import { 
    SET_JOBS,
    SET_JOB_EVENTS,
    UPDATE_JOB_EVENTS,
} from '../actions/actionTypes';
import _ from 'lodash'

const initialState = {
  jobs: [],
  jobs_confirmed: [],
  jobs_completed: [],
  jobs_aborted: [],
  jobs_closed: [],
  job_events: [],
  new_job_events: 0,
}

const reducer = (state = initialState, action) => {
  let new_job_events = 0
  switch (action.type) {
    case SET_JOBS:
      //console.warn(action.jobs)
      let jobs_confirmed = _.filter(action.jobs, {job_status: 'confirmed'})
      let jobs_completed = _.filter(action.jobs, {job_status: 'completed'})
      let jobs_aborted = _.filter(action.jobs, {job_status: 'aborted'})
      let jobs_closed = jobs_completed.concat(jobs_aborted)

      new_job_events = 0
      for (var i = 0; i < jobs_confirmed.length; i++) {
        let job = jobs_confirmed[i]
      
        if (!job.job_events_read) {
          new_job_events++
        }
      }
      for (var i = 0; i < jobs_completed.length; i++) {
        let job = jobs_completed[i]
      
        if (!job.job_events_read) {
          new_job_events++
        }
      }

      return {
        ...state,
        jobs: action.jobs,
        jobs_confirmed,
        jobs_completed,
        jobs_aborted,
        jobs_closed,
        new_job_events,
      }
    case SET_JOB_EVENTS:
      //console.warn(action.job_events)
      return {
        ...state,
        job_events: action.job_events,
      }
    case UPDATE_JOB_EVENTS:
      let job_events = []
      new_job_events = state.new_job_events
          
      for (var i = 0; i < state.job_events.length; i++) {
        let job_event = state.job_events[i]
        if (job_event.job_id != action.job_id) {
          job_events.push(job_event)
        }
      }
      for (var i = 0; i < action.job_events.length; i++) {
        let job_event = action.job_events[i]
        job_events.push(job_event)
      }
      let jobs = [...state.jobs]
      for (var i = 0; i < jobs.length; i++) {
        let job = jobs[i]
        if (job.job_id == action.job_id) {
          job.job_events = action.job_events

          let job_events_read = true
          let job_events_new = {}
          for (var j = 0; j < job.job_events.length; j++) {
            let job_event = job.job_events[j]
            if (!job_event.job_event_read) {
              if (job_event.event_type != 'hidden') {
                job_events_read = false
              }
              if (job_events_new[job_event.event_type]) {
                job_events_new[job_event.event_type]++
              }
              else {
                job_events_new[job_event.event_type] = 1
              }
            }
          }

          if (job.job_status == 'confirmed' || job.job_status == 'completed') {
            new_job_events = new_job_events + (job_events_read ? 0 : 1) - (job.job_events_read ? 0 : 1)
          }

          job.job_events_read = job_events_read
          job.job_events_new = job_events_new
        }
      }
      return {
        ...state,
        jobs,
        job_events,
        new_job_events
      }
    default:
      return state;
  }
}

export default reducer;