import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'
import { signBid, revokeBid } from '../../../store/actions/auctions'


import _ from 'lodash'


class ProviderViewBidScreen extends ByggbuddyScreen {

  // Component settings
  show_back = true
  content_max_width = 'none'

  state = {
    bid_is_being_revoked: false,
    bid_is_being_signed: false,
    job_image_popup: -1,
  }

  popupJobImage = (job_image_popup) => {

    this.setState(prev_state => {

      return {
        ...prev_state,
        job_image_popup,
      }
    })
  }

  signBid = () => {

    let bid_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.bid_id) {
      bid_id = this.props.match.params.bid_id
    }

    if (bid_id) {
      this.setState(prev_state => {

        return {
          ...prev_state,
          bid_is_being_signed: true,
        }
      })
      this.props.signBid(bid_id)
        .then(() => {
          alert('Offerten har skickats! Psst. För att öka chansen att vinna uppdraget är vårt tips att du direkt ringer kunden och säkerställer att offerten kommit fram. Ibland hamnar den i skräpposten')
          this.setState(prev_state => {
            return {
              ...prev_state,
              bid_is_being_signed: false,
            }
          })
        })
    }
    else {
      alert('Något gick fel!')
    }
  }

  revokeBid = () => {

    let bid_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.bid_id) {
      bid_id = this.props.match.params.bid_id
    }

    if (bid_id) {
      this.setState(prev_state => {
        return {
          ...prev_state,
          bid_is_being_revoked: true,
        }
      })
      this.props.revokeBid(bid_id)
        .then(() => {
          alert('Offerten har återtagits')
          this.setState(prev_state => {
            return {
              ...prev_state,
              bid_is_being_revoked: false,
            }
          })
        })
    }
    else {
      alert('Något gick fel!')
    }
  }

  renderBrandedContent(provider_account) {

    if (this.props.auth.scope !== 'provider') {
      return null
    }

    let bid_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.bid_id) {
      bid_id = this.props.match.params.bid_id
    }

    let auction_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.auction_id) {
      auction_id = this.props.match.params.auction_id
    }

    let bid = null
    // Find bid to view
    for (var i = 0; i < this.props.bids.length; i++) {
      let b = this.props.bids[i]
      if (b.bid_id == bid_id) {
        bid = b
      }
    }

    if (!bid || auction_id != bid.auction_id) {
      return null
    }

    // Find auction bid is for
    let auction = null
    for (var i = 0; i < this.props.auctions.length; i++) {
      let a = this.props.auctions[i]
      if (a.auction_id == bid.auction_id) {
        auction = a
      }
    }

    if (!auction) {
      return null
    }

    return (

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>

        {this.state.job_image_popup > -1 ? (
          <div onClick={() => this.popupJobImage(-1)} style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', backgroundColor: 'black', border: '10px solid white', boxSizing: 'border-box' }}>
            <img src={auction.job_images[this.state.job_image_popup].job_image_url} style={{ width: '100%', maxHeight: 'calc(100vh - 20px)', objectFit: 'contain' }} />
          </div>
        ) : null}

        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '400px', flexGrow: 0, flexShrink: 1, padding: '15px', backgroundColor: '#f8f8f8' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {auction.job_name}
          </div>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {auction.geolocation_text}
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
            <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
              <span className="material-icons">person</span>
            </div>
            <div>
              <div>{auction.first_name} {auction.last_name}</div>
              <div>{auction.email}</div>
              <div>{auction.phone}</div>
            </div>
          </div>

          {auction.job_hours ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">watch_later</span>
              </div>
              <div>
                <div>{utility.niceNumberFormat(auction.job_hours)} timmar</div>
                <div>Uppskattad tidsåtgång</div>
              </div>
            </div>
          ) : null}

          {auction.job_options.length ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">home</span>
              </div>
              <div>
                {auction.job_options.map(job_option => (
                  <div key={job_option.job_option_id}>{job_option.option_name}</div>
                ))}
              </div>
            </div>
          ) : null}

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
            <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
              <span className="material-icons">date_range</span>
            </div>
            <div>
              <div>Start tidigast {auction.preferred_startdate}</div>
              <div>Färdigt senast {auction.preferred_enddate}</div>
            </div>
          </div>

          {auction.job_text ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">assignment</span>
              </div>
              <div>
                <div>{auction.job_text}</div>
              </div>
            </div>
          ) : null}

          {auction.job_tasks.map(job_task => (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }} key={job_task.job_task_id}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <div style={{ width: '24px', height: '24px', padding: '2px', boxSizing: 'border-box', backgroundColor: 'black', borderRadius: '3px' }}>
                  <img src={job_task.image_url} style={{ width: '100%', objectFit: 'contain' }} />
                </div>
              </div>
              <div>
                <div>{job_task.task_name}</div>
                <div>{job_task.job_task_text}</div>
                {job_task.job_task_drivers.map(job_task_driver => (
                  <div key={job_task_driver.job_task_driver_value_id}>{job_task_driver.driver_name}: {job_task_driver.driver_value} {job_task_driver.driver_metric}</div>
                ))}
                <ul style={{ paddingInlineStart: '30px' }}>
                  {job_task.job_task_items.map(job_task_item => (
                    <li key={job_task_item.job_task_item_id} style={{ marginBottom: '5px' }}>{job_task_item.task_item_name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* Job images */}
          {auction.job_images.length ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '15px' }}>
                {auction.job_images.map((job_image, index) => (
                  <div style={{ width: '100px', marginRight: '15px' }} onClick={() => this.popupJobImage(index)} key={job_image.job_image_id}>
                    <img src={job_image.job_image_url} style={{ width: '100%' }} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '300px', flexGrow: 1, padding: '0 15px' }}>

          <div style={{ padding: 0, margin: '0 auto', maxWidth: '800px' }}>

            <div style={{ backgroundColor: '#B9F3E9', padding: 10 }}>
              <div style={{ marginTop: 5 }}>
                {bid.bid_type == 'fixed' ? (
                  <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                    Fast anbud (inkl moms efter ROT-avdrag)
                  </div>
                ) : (
                  <div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                      Rörligt anbud om <div style={{ fontWeight: 'bold' }}>{bid.bid_price_extra_hour_deducted}</div> kr / timme
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                      (inkl moms före ROT-avdrag)
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a', marginTop: 15 }}>
                      Uppskattad totalkostnad
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a' }}>
                      (inkl moms efter ROT-avdrag)
                    </div>
                  </div>
                )}
                <div style={{ fontSize: 50, fontWeight: 'bold', textAlign: 'center', color: '#00a58a' }}>
                  {utility.niceNumberFormat(Math.round(bid.bid_price_deducted))} <span style={{ fontSize: 16 }}>kr</span>
                </div>
                {/*<div style={{fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a'}}>
                  {utility.niceNumberFormat(bid.bid_price)} kr (exkl moms före ROT-avdrag)
                </div>*/}
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                <div style={{ width: '100%', marginBottom: 15 }}>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Specifikation av anbudets{bid.bid_type == 'fixed' ? ' ' : ' uppskattade '}totalkostnad</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Arbetskostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round(bid.bid_price_work * 0.8))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Materialkostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(bid.bid_price_material * 0.8)} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Servicebil (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round(bid.bid_price_car * 0.8))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: 'black' }}>Hantverkarens totalkostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: 'black' }}>{utility.niceNumberFormat(Math.round(bid.bid_price * 0.8))} kr</div>
                  </div>
                </div>

              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Moms</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round((bid.bid_price + bid.bid_commission) * 0.20))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: 'black' }}>ROT-avdrag</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: 'red' }}>{utility.niceNumberFormat(Math.round(bid.bid_price_deducted) - Math.round(bid.bid_price))} kr</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 14, color: 'black' }}>Uppdragsgivarens totalkostnad</div>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 14, color: 'black' }}>(inkl moms, efter ROT-avdrag)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 14, color: 'black' }}>{utility.niceNumberFormat(Math.round(bid.bid_price_deducted))} kr</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment terms */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#026ca0', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-bid-type.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Betalningsvillkor</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här väljer du när kunden ska betala</div>
              </div>
            </div>

            {bid.bid_payment_terms == 'monthly' ? (
              <div style={{ width: '100%', boxSizing: 'border-box', padding: 20 }}>
                <div>
                  <div style={{ textAlign: 'left' }}>Kunden betalar för utfört arbete månadsvis i efterskott.</div>
                </div>
              </div>
            ) : (
              <div style={{ width: '100%', boxSizing: 'border-box', padding: 20 }}>
                <div>
                  <div style={{ textAlign: 'left' }}>Kunden betalar hela beloppet när arbetet är utfört.</div>
                </div>
              </div>
            )}

            {/* Dates */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#00a1b1', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-dates.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Datum för genomförande</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här väljer du inom vilka datum uppdraget kommer att genomföras</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white', padding: 20 }}>

              <div style={{ width: '100%', marginBottom: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>Påbörjas tidigast</div><div>{bid.bid_startdate}</div>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>Färdigt senast</div><div>{bid.bid_enddate}</div>
              </div>
            </div>

            {/* Prerequisites */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#0d7a3e', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-cog.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Ange förutsättningar</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här anger du uppgifter om dig och dina underleverantörer. Uppgifterna kommer att presenteras av kunden och bli en del av avtalet</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white', padding: 20 }}>
              <div style={{ width: '100%' }}>
                {bid.bid_properties.map(bid_property => (
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={bid_property.property_id}>
                    {bid_property.property_type == 'bool' && bid_property.bool == 1 ? (
                      <div style={{ marginBottom: 20, paddingHorizontal: 5, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                        <div style={{}}>
                          <div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontWeight: 'bold' }}>{bid_property.property_provider_heading}</div>
                          </div>
                          <div>
                            <div style={{ fontSize: 14, textAlign: 'left', color: '#808080' }}>{bid_property.property_provider_bid_info}</div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>


            {/* Bid text */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#00a1b1', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-bouble.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Övrigt</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här skrivs annan viktig information, exempelvis hantverkarens avrådanden. Denna text blir en del av avtalet med kunden</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  {bid.bid_text}
                </div>
              </div>

            </div>

          </div>

          <div style={{
            flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', //position: 'absolute', 
            borderColor: '#808080',
            borderTopWidth: 2,
            bottom: 0,
            backgroundColor: 'white',
            padding: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: -10,
            },
            shadowOpacity: 0.5,
            shadowRadius: 15,
          }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {bid.bid_type == 'fixed' ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 10, width: 60 }}>
                  <img src={require('../../../assets/icons/icon-lock.png')} style={{ height: 24, width: 24 }} />
                  <div style={{ fontSize: 9, textAlign: 'center' }}>Fast pris</div>
                </div>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {bid.bid_type == 'fixed' ? (
                  <div style={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40' }}>
                    {utility.niceNumberFormat(Math.round(bid.bid_price_deducted + bid.bid_commission))} <span style={{ fontSize: 16 }}>kr</span>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, width: 60 }}>
                        <img src={require('../../../assets/icons/icon-unlock.png')} style={{ height: 24, width: 24 }} />
                        <div style={{ fontSize: 9, textAlign: 'center' }}>Rörligt pris</div>
                      </div>
                      <div style={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40' }}>
                        {utility.niceNumberFormat(Math.round(bid.bid_price_extra_hour_deducted))} <span style={{ fontSize: 16 }}>kr / timme</span>
                      </div>
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: 'black' }}>
                      Uppskattad totalkostnad <span style={{ fontWeight: 'bold' }}>{utility.niceNumberFormat(Math.round(bid.bid_price_deducted + bid.bid_commission))}</span> kr
                    </div>
                  </div>
                )}
                <div style={{ fontSize: 11, textAlign: 'center', color: '#808080' }}>Inkl moms efter ROT-avdrag</div>
              </div>
              {bid.bid_type == 'fixed' ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 10, width: 60 }}>
                </div>
              ) : null}
            </div>

            {bid.bid_status == 'unsigned' ? (
              <>
                <div style={{ padding: 0, margin: '50px 0 10px', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  {!this.state.bid_is_being_signed ? (
                    <DefaultButton
                      style={{ width: '80%', backgroundColor: '#167f40' }}
                      onClick={() => this.signBid()}
                    >
                      Skicka offert
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      style={{ width: '80%', backgroundColor: '#167f40' }}
                      disabled={true}
                    >
                      Offerten skickas...
                    </DefaultButton>
                  )}
                </div>

                <div style={{ padding: 0, margin: '10px 0', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  {!this.state.bid_is_being_revoked ? (
                    <DefaultButton
                      style={{ width: '80%', color: 'red', border: '2px solid red', backgroundColor: 'white' }}
                      onClick={() => this.revokeBid()}
                    >
                      Ta bort offert
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      style={{ width: '80%', backgroundColor: '#167f40' }}
                      disabled={true}
                    >
                      Offerten tas bort...
                    </DefaultButton>
                  )}
                </div>
              </>
            ) : null}

            {bid.bid_status == 'open' ? (
              <>
                <div style={{ padding: 0, margin: '10px 0 0px', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  <h2>
                    Skickad!
                  </h2>
                </div>
                <div style={{ padding: 0, margin: '10px 0', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  {!this.state.bid_is_being_revoked ? (
                    <DefaultButton
                      style={{ width: '80%', color: 'red', border: '2px solid red', backgroundColor: 'white' }}
                      onClick={() => this.revokeBid()}
                    >
                      Återta offert
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      style={{ width: '80%', backgroundColor: '#167f40' }}
                      disabled={true}
                    >
                      Offerten återtas...
                    </DefaultButton>
                  )}
                </div>
              </>
            ) : null}

            {bid.bid_contract_url ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px' }}>
                <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                  <span className="material-icons">file_download</span>
                </div>
                <div>
                  <a href={bid.bid_contract_url} target="_blank">Se offerten som PDF</a>
                </div>
              </div>
            ) : null}


          </div>
        </div>
      </div>

    )
  }

  renderByggbuddyContent() {

    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auctions: state.auctions.auctions,
  bids: state.auctions.bids,
  auth: state.auth,
  ui: state.ui,
  developer_mode: state.auth.developer_mode,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    revokeBid: (bid_id) => dispatch(revokeBid(bid_id)),
    signBid: (bid_id) => dispatch(signBid(bid_id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderViewBidScreen)
