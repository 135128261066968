import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  addImageToTask,
  removeImageFromTask, 
  doneImagesToTask,
  initiateCheckout,
  confirmPrerequisites,
  setCartMaxPricePerHour, 
  setCartAddress, 
  selectCartJobOption, 
  setCartSpeed,
  setCartPreferredStartDate,
  setCartPreferredEndDate,
  setCartJobName,
  setCartJobText,
  setCartProviderAccountIds,
  getGeolocations,
  checkoutCart, 
} from '../../../store/actions/shop'
import validate from '../../../utility/validation'

import DefaultProviderImage from '../../../assets/images/background-5.jpg';

import FileBase64 from 'react-file-base64';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
registerLocale('sv', sv)


class StartAuctionScreen extends ByggbuddyScreen {

  // Component settings
  show_cart = false
  content_max_width = '600px'

  state = {
    auction: null,
    control_address: {
      search: '',
      options: [],
      current_position: {},
    },
    controls: {
      job_name: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
      job_text: {
        value: '',
        valid: false,
        validationRules: {
        },
        touched: false
      },
      preferred_startdate: {
        value: new Date(moment().add(1, 'days').format("YYYY-MM-DD")),
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false,
      },
      preferred_enddate: {
        value: new Date(moment().add(91, 'days').format("YYYY-MM-DD")),
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false,
      },
      first_name: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
      last_name: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
      email: {
        value: '',
        valid: false,
        validationRules: {
          isEmail: true
        },
        touched: false
      },
      phone: {
        value: '',
        valid: false,
        validationRules: {
        },
        touched: false
      },
    },
    address: null,
    provider_account_ids: [],
    all_prerequisites_checked: false,
    auction_is_starting: false,
  }

  goBack = () => {
    this.props.history.goBack()
  }

  componentDidMount() {

    Analytics.logEvent('initiate_checkout')

    /*Geocoder.init('AIzaSyBVqnrloLO0ebOoxlcq6OmFi1PjdNMnans'); // use a valid API key

    GetLocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
    })
    .then(position => {
        //console.warn(position)
        Geocoder.from(position.latitude, position.longitude)
        .then(json => {
          var location = json.results
          //console.warn(location)

          let address = location[0].formatted_address
          let address_short = location[0].formatted_address

          if (address.substring(address.length - 8) == ', Sweden') {
            address_short = address.substring(0, address.length - 8);
          }
          if (address.substring(address.length - 9) == ', Sverige') {
            address_short = address.substring(0, address.length - 9);
          }

          this.setState(prev_state => {
            return {
              ...this.prev_state,
              control_address: {
                ...this.state.control_address, 
                current_position: {
                  geolocation_id: 0,
                  latitude: position.latitude,
                  longitude: position.longitude,
                  geolocation_text: address,
                  geolocation_text_short: address_short,
                  google_place_id: location[0].place_id,
                }
              }
            }
          });
        })
        .catch(error => console.warn(error))
    })
    .catch(error => {
        const { code, message } = error;
        console.warn(code, message);
    })*/

    if (this.props.cart.tasks.length == 0) {
      return
    }


    // Construct auction from cart
    let auction = {
      job_tasks: [],
      auction_reference_price: this.props.cart.total_price,
      auction_reference_price_deducted: this.props.cart.total_price_deducted,
    }

    for (var i = 0; i < this.props.cart.tasks.length; i++) {
      let cart_task = this.props.cart.tasks[i]
      let task = null

      // Task
      for (var j = 0; j < this.props.shop.tasks.length; j++) {
        let t = this.props.shop.tasks[j]
        if (t.task_id == cart_task.task_id) {
          task = t
        }
      }

      // Drivers
      let job_task_drivers = []
      for (var j = 0; j < cart_task.driver_values.length; j++) {
        let cart_driver_value = cart_task.driver_values[j]
        let driver = null

        for (var k = 0; k < this.props.shop.drivers.length; k++) {
          let d = this.props.shop.drivers[k]
          if (d.driver_id == cart_driver_value.driver_id) {
            driver = d
          }
        }

        let job_task_driver = {
          ...driver,
          driver_value: cart_driver_value.driver_value,
        }
        job_task_drivers.push(job_task_driver)
      }

      // Task items
      let job_task_items = []
      for (var j = 0; j < cart_task.task_items.length; j++) {
        let cart_task_item = cart_task.task_items[j]
        let task_item = null

        for (var k = 0; k < this.props.shop.task_items.length; k++) {
          let ti = this.props.shop.task_items[k]
          if (ti.task_item_id == cart_task_item.task_item_id) {
            task_item = ti
          }
        }

        let job_task_item = {
          ...task_item,
        }
        job_task_items.push(job_task_item)
      }

      // Task answers
      let job_task_answers = []
      for (var j = 0; j < cart_task.task_answers.length; j++) {
        let cart_task_answers = cart_task.task_answers[j]
        let task_item_question = null

        for (var k = 0; k < this.props.shop.task_item_questions.length; k++) {
          let tq = this.props.shop.task_item_questions[k]
          if (tq.task_item_question_id == cart_task_answers.task_item_question_id) {
            task_item_question = tq
          }
        }

        let job_task_answer = {
          ...task_item_question,
          job_task_answer_text: cart_task_answers.job_task_answer_text,
        }
        job_task_answers.push(job_task_answer)
      }

      // Task images
      let job_task_images = []
      for (var j = 0; j < cart_task.task_item_image_ids.length; j++) {
        let task_image = null

        for (var k = 0; k < this.props.shop.task_item_images.length; k++) {
          let image = this.props.shop.task_item_images[k]
          if (image.task_item_image_id == cart_task.task_item_image_ids[j]) {
            task_image = {
              ...image,
              image_files: [],
            }
          }
        }
        job_task_images.push(task_image)
      }

      // Compiling the task item object
      let job_task = {
        ...task,
        job_task_text: cart_task.task_text,
        job_task_total_price: cart_task.total_price,
        job_task_total_price_deducted: cart_task.total_price_deducted,
        job_task_drivers,
        job_task_items,
        job_task_answers,
        job_task_images,
      }
      //console.warn(job_task.task_name)
      auction.job_tasks.push(job_task)
    }

    // Prerequisites
    let prerequisites = []
    for (var i = 0; i < auction.job_tasks.length; i++) {
      let job_task = auction.job_tasks[i]
    
      for (var j = 0; j < job_task.job_task_items.length; j++) {
        let job_task_item = job_task.job_task_items[j]
        this.addPrerequisitesRecursive(job_task_item.task_item_id, prerequisites)
      }
    }

    // Options
    let option_selects = []
    this.addOptionsRecursive(0, true, option_selects)

    // Job name
    let job_name = '' //auction.job_tasks[0].task_name + (auction.job_tasks.length > 1 ? ' m.m.' : '')

    // Provider
    let provider_account_ids = []

    if (this.props.shop.shop_provider_account_id > 0) {
      provider_account_ids.push(this.props.shop.shop_provider_account_id)
    }

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        auction,
        prerequisites,
        option_selects,
        job_name,
        provider_account_ids,
        all_prerequisites_checked: (prerequisites.length == 0),
      }
    })
  }

  addOptionsRecursive(parent_option_id, visible, option_selects) {

    for (var i = 0; i < this.props.shop.options.length; i++) {
      let option = this.props.shop.options[i]

      if (option.parent_option_id == parent_option_id) {
        if (option.leaf == 0) {

          let option_select = {
            ...option,
            visible,
            selected_child_option_id: 0,
            child_options: [],
          }

          for (var j = 0; j < this.props.shop.options.length; j++) {
            let child_option = this.props.shop.options[j]

            if (child_option.parent_option_id == option.option_id) {
              option_select.child_options.push(child_option)
            }
          }

          if (option_select.child_options.length > 0) {
            option_select.selected_child_option_id = option_select.child_options[0].option_id
          }

          option_selects.push(option_select)

          // Recursive
          for (var j = 0; j < option_select.child_options.length; j++) {
            this.addOptionsRecursive(option_select.child_options[j].option_id, visible && (option_select.selected_child_option_id == option_select.child_options[j].option_id), option_selects)
          }
        }
      }
    }
  }
  
  startAuction() {

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        auction_is_starting: true,
      }
    })

    for (var i = 0; i < this.state.auction.job_tasks.length; i++) {
      let job_task = this.state.auction.job_tasks[i]

      for (var j = 0; j < job_task.job_task_images.length; j++) {
        let job_task_image = job_task.job_task_images[j]

        for (var k = 0; k < job_task_image.image_files.length; k++) {
          let image_file = job_task_image.image_files[k]
          this.props.onAddImageToTask(job_task_image.task_item_image_id, image_file, i)
        }
        this.props.onDoneImagesToTask(job_task_image.task_item_image_id, i)
      }
    }

    for (var i = 0; i < this.state.option_selects.length; i++) {
      let option_select = this.state.option_selects[i]

      if (option_select.visible) {
        this.props.onSelectCartJobOption(option_select.selected_child_option_id)
      }
    }

    this.props.onSetCartJobName(this.state.job_name)
    this.props.onSetCartPreferredStartdate(moment(this.state.controls.preferred_startdate.value).format("YYYY-MM-DD"))
    this.props.onSetCartPreferredEnddate(moment(this.state.controls.preferred_enddate.value).format("YYYY-MM-DD"))
    //this.props.onSetCartJobText('')
    this.props.onSetCartAddress(this.state.address)

    // Provider - double check only available providers are selected
    let provider_account_ids = []
    for (var i = 0; i < this.state.provider_account_ids.length; i++) {
      let provider_account_id = this.state.provider_account_ids[i]

      for (var j = 0; j < this.props.cart.potential_provider_accounts.length; j++) {
        let potential_provider = this.props.cart.potential_provider_accounts[j]

        if (potential_provider.provider_account_id == provider_account_id) {
          provider_account_ids.push(provider_account_id)
        }
      }
    }
    this.props.onSetCartProviderAccountIds(provider_account_ids)

    let provider = 'byggbuddy'

    if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      provider = this.props.match.params.provider
    }

    if (this.state.scope == 'customer') {

      this.props.onCheckoutCart()
      .then(() => {
        this.setState(prev_state => {
          return {
            ...this.prev_state,
            auction_is_starting: false,
          }
        })
        this.props.history.push('/' + provider + '/shop/auction_started')
      })
      .catch(error => {
        console.warn(error)
        .then(() => {
          console.log('Budgivningen kunde inte startas')
          alert('Något blev tyvätt fel, offertförfrågan kunde inte skickas')
          this.setState(prev_state => {
            return {
              ...this.prev_state,
              auction_is_starting: false,
            }
          })
        })
      })

    }
    else {

      let customer = {
        customer_id: 0,
        first_name: this.state.controls.first_name.value,
        last_name: this.state.controls.last_name.value,
        email: this.state.controls.email.value,
        phone: this.state.controls.phone.value,
      }

      this.props.onCheckoutCart(customer)
      .then(() => {
        this.props.history.push('/' + provider + '/shop/auction_started')
      })
      .catch(error => {
        console.warn(error)
        console.log('Budgivningen kunde inte startas')
        /*Alert.alert(
          'Starta budgivning',
          'Budgivningen kunde inte startas',
          [
            {text: 'Stäng'},
          ]
        )*/

      })
    }
  }

  updateInputState = (key, value) => {
    
    let valid = validate(value, this.state.controls[key].validationRules)
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: valid,
            touched: true
          }
        },
        any_touched: true,
      }
    })
  }

  onChangeSearchText(text) {

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        control_address: {
          ...this.state.control_address, 
          search: text,
        }
      }
    })

    try {
      this.searchAddress(text)  
    }
    catch (error) {
      console.warn(error)
    }
  }

  selectGeolocation(geolocation) {

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        address: geolocation,
        control_address: {
          ...prev_state.control_address,
          search: '',
          options: [],
        },
      }
    })
    this.props.onSetCartAddress(geolocation)
    .then(() => {

      // Filter selected provider to ensure only geographically matched remain
      this.setState(prev_state => {
        let provider_account_ids = []
        
        // If in branded shop, always add brand provider if matched
        if (this.props.ui.is_branded) {
          this.props.shop.cart.potential_provider_accounts.forEach(provider_account => {
            if (this.props.ui.brand_provider_account.provider_account_id == provider_account.provider_account_id) {
              provider_account_ids.push(provider_account.provider_account_id)
            }
          })
        }
        else {
          this.props.shop.cart.potential_provider_accounts.forEach(provider_account => {
            if (prev_state.provider_account_ids.includes(provider_account.provider_account_id)) {
              provider_account_ids.push(provider_account.provider_account_id)
            }
          })
        }
        return {
          ...this.prev_state,
          provider_account_ids,
        }
      })
    })
  }

  resetGeolocation() {

    let current_text = this.state.address.geolocation_text_short

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        address: null,
      }
    })
    this.onChangeSearchText(current_text)
  }

  searchAddress(text) {

    this.addressSearchLastInput = new Date();

    if (text.length > 5) {

      setTimeout(async () => {

        var now = new Date(); 
        var time_since_last_input = now.getTime() - this.addressSearchLastInput.getTime();

        if (time_since_last_input >= 1000) {

          this.props.getGeolocations(text, ["street_address"])
          .then(geolocations => {
            this.setState(prev_state => {
              return {
                ...this.prev_state,
                control_address: {
                  ...this.state.control_address, 
                  options: geolocations,
                }
              }
            })
          })
          .catch(error => {
            // Do nothing
          })
        }
      }, 2000);
    }
  }


  addPrerequisitesRecursive(task_item_id, prerequisites) {

    let task_item_prerequisites = this.props.shop.task_item_prerequisites
    let task_items = this.props.shop.task_items

    // Add prerequisites for parent
    for (var i = 0; i < task_items.length; i++) {
      let task_item = task_items[i]

      if (task_item.task_item_id == task_item_id && task_item.parent_task_item_id > 0) {
        this.addPrerequisitesRecursive(task_item.parent_task_item_id, prerequisites)
      }
    }

    // Add prerequisites for this task_item
    for (var j = 0; j < task_item_prerequisites.length; j++) {
      let prerequisite = task_item_prerequisites[j]
      if (prerequisite.task_item_id == task_item_id) {
        let already_in_list = false
        for (var k = 0; k < prerequisites.length; k++) {
          if (prerequisites[k].task_item_prerequisite_id == prerequisite.task_item_prerequisite_id) {
            already_in_list = true
          }
        }
        if (!already_in_list) {
          prerequisites.push({...prerequisite, confirmed: false, expanded: false})
        }
      }
    }
  }

  confirmPrerequisite = (task_item_prerequisite_id, value) => {
    this.setState(prev_state => {

      let prerequisites = [...prev_state.prerequisites]

      let all_prerequisites_checked = true
      for (var i = 0; i < prerequisites.length; i++) {
        let prerequisite = prerequisites[i]

        if (prerequisite.task_item_prerequisite_id == task_item_prerequisite_id) {
          prerequisite.confirmed = value
        }
        if (!prerequisite.confirmed) {
          all_prerequisites_checked = false
        }
      }

      return {
        ...prev_state,
        prerequisites,
        all_prerequisites_checked,
      }
    }) 
  }

  expandPrerequisite = (task_item_prerequisite_id, value) => {
    this.setState(prev_state => {

      let prerequisites = [...prev_state.prerequisites]

      for (var i = 0; i < prerequisites.length; i++) {
        let prerequisite = prerequisites[i]

        if (prerequisite.task_item_prerequisite_id == task_item_prerequisite_id) {
          prerequisite.expanded = value
        }
      }

      return {
        ...prev_state,
        prerequisites,
      }
    }) 
  }

  toggleProvider = (provider_account_id) => {
    this.setState(prev_state => {

      let selected = true

      if (prev_state.provider_account_ids.indexOf(provider_account_id) >= 0) {
        selected = false
      }

      let provider_account_ids = []
      let found = false
      for (var i = 0; i < prev_state.provider_account_ids.length; i++) {
        if (prev_state.provider_account_ids[i] == provider_account_id) {
          if (selected) {
            found = true
            provider_account_ids.push(prev_state.provider_account_ids[i])
          }
          else {
            // Do nothing, hence removed
          }
        }
        else {
          provider_account_ids.push(prev_state.provider_account_ids[i])
        }
      }
      if (selected && !found) {
        provider_account_ids.push(provider_account_id)
      }
      
      return {
        ...prev_state,
        provider_account_ids,
      }
    }) 
  }

  selectOption(option_id, child_option_id) {
    this.setState(prev_state => {
    
      let option_selects = [...prev_state.option_selects]
      
      for (var i = 0; i < option_selects.length; i++) {
        let option_select = option_selects[i]

        if (option_select.option_id == option_id) {
          let previous_child_option_id = option_select.selected_child_option_id
          option_select.selected_child_option_id = child_option_id
          
          this.setOptionSelectVisibilityRecursive(previous_child_option_id, false, option_selects)
          this.setOptionSelectVisibilityRecursive(child_option_id, true, option_selects)
        }
      }
    
      return {
        ...prev_state,
        option_selects,
      }
    })
  }

  setOptionSelectVisibilityRecursive(option_id, visible, option_selects) {

    for (var i = 0; i < option_selects.length; i++) {
      let option_select = option_selects[i]

      if (option_select.option_id == option_id) {
        option_select.visible = visible

        for (var j = 0; j < option_select.child_options.length; j++) {
          this.setTaskItemSelectVisibilityRecursive(option_select.child_options[j].option_id, visible, option_selects)
        }
      }
    }
  }

  updateJobName(value) {
    this.setState(prev_state => {
      return {
        ...prev_state,
        job_name: value,
      }
    })
  }

  updateDate(date_control, date) {
    this.setState(prev_state => {
      let new_state = {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [date_control]: {
            ...prev_state.controls[date_control],
            value: new Date(moment(date).format("YYYY-MM-DD")),
            touched: true,
          },
        },
      }
      if (date_control == 'preferred_startdate' && moment(date).isAfter(prev_state.controls.preferred_enddate.value)) {
        new_state.controls.preferred_enddate = {
          ...new_state.controls.preferred_enddate,
          value: new Date(moment(date).add(30, 'days').format("YYYY-MM-DD")),
          touched: true,
        }
      }
      return new_state
    })
  }

  /*selectJobTaskImages(task_index, task_item_image_id, image_instruction) {

    Navigation.push(this.props.componentId, {
      component: {
        name: 'byggbuddy.CameraRollScreen',
        passProps: {
          onSelectImages: (image_urls) => this.addJobTaskImages(task_index, task_item_image_id, image_urls),
          instruction: image_instruction,
        }
      }
    })
  }*/

  /*addJobTaskImages = (task_index, task_item_image_id, image_urls) => {
    this.setState(prev_state => {

      let job_tasks = [...prev_state.auction.job_tasks]
      let job_task_images = [...job_tasks[task_index].job_task_images]

      for (var i = 0; i < job_task_images.length; i++) {
        let job_task_image = job_task_images[i]

        if (job_task_image.task_item_image_id == task_item_image_id) {
          let urls = [...job_task_image.image_urls]

          for (var j = 0; j < image_urls.length; j++) {
            urls.push(image_urls[j])
          }
          job_task_image.image_urls = urls
        }
      }
      
      return {
        ...prev_state,
        auction: {
          ...prev_state.auction,
          job_tasks,
        }
      }
    })
  }*/

  addJobTaskImage(task_index, task_item_image_id, file) {
    this.setState(prev_state => {

      let job_tasks = [...prev_state.auction.job_tasks]
      let job_task_images = [...job_tasks[task_index].job_task_images]

      for (var i = 0; i < job_task_images.length; i++) {
        let job_task_image = job_task_images[i]

        if (job_task_image.task_item_image_id == task_item_image_id) {
          let files = [...job_task_image.image_files]

          files.push(file)
          
          job_task_image.image_files = files
        }
      }
      
      return {
        ...prev_state,
        auction: {
          ...prev_state.auction,
          job_tasks,
        }
      }
    })
  }

  removeJobTaskImage = (task_index, task_item_image_id, file_index) => {
    this.setState(prev_state => {

      let job_tasks = [...prev_state.auction.job_tasks]
      let job_task_images = [...job_tasks[task_index].job_task_images]

      for (var i = 0; i < job_task_images.length; i++) {
        let job_task_image = job_task_images[i]

        if (job_task_image.task_item_image_id == task_item_image_id) {
          let files = [...job_task_image.image_files]
          let new_files = []
          for (var j = 0; j < files.length; j++) {
            if (j != file_index) {
              new_files.push(files[j])
            }
          }
          job_task_image.image_files = new_files
        }
      }
      
      return {
        ...prev_state,
        auction: {
          ...prev_state.auction,
          job_tasks,
        }
      }
    })
  }

  openImageSelect = (event) => {
    
    let frame = event.currentTarget
    let parent = frame.parentElement.parentElement
    let children = parent.children

    for (var i = 0; i < children.length; i++) {
      let child = children[i];
      if (child.className == 'image_file_input') {
        let grand_children = child.children
        for (var j = 0; j < grand_children.length; j++) {
          let grand_child = grand_children[j];
            grand_child.click()
        }
      }
    }
  }

  renderOptionSelect(option_select) {
    return (

      <div style={{width: '100%', boxSizing: 'border-box'}} key={option_select.option_id}>
        {/*<div style={{paddingHorizontal: 10, marginBottom: 5}}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>{task_item_select.question}</div>
        </div>*/}
        <div>
          
          <div style={{padding: '10px 0 0', borderRadius: 10, borderWidth: 1, borderColor: '#404040', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'border-box', display: 'flex'}}>
            <div style={{width: '100%', padding: '10px', border: '1px solid #808080', borderRadius: '4px'}}>
              <select style={{width: '100%', border: 'none', fontSize: '16px'}} onChange={(event) => {this.selectOption(option_select.option_id, event.target.value)}} value={option_select.selected_child_option_id}>
                {option_select.child_options.map(child_option => (
                  <option value={child_option.option_id} key={child_option.option_id}>
                    {child_option.answer}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderJobNameInput() {
    return (
      <div style={{width: '100%', paddingHorizontal: 20, paddingVertical: 0}}>
        {/*<div style={{paddingHorizontal: 10, marginBottom: 5}}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>Uppdragets namn</div>
        </div>*/}
        <div>
          <div style={{paddingHorizontal: 10, borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (this.state.job_name ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <DefaultInput
             placeholder="Namnge uppdraget"
             value={String(this.state.job_name)}
             onChange={event => this.updateJobName(event.target.value)}
             style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
            />
          </div>
        </div>
      </div>
    )
  }

  renderAddressSelect() {
    return (
      <div style={{width: '100%'}}>
        {/*<div style={{paddingHorizontal: 10, marginBottom: 5}}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>Address där uppdraget ska utföras</div>
        </div>*/}
        <div>
          <div style={{padding: '0 10px', borderRadius: '4px', borderWidth: '1px', borderStyle: 'solid', borderColor: (this.state.address ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
              {this.state.address ? (
                <div style={{display: 'flex', padding: '10px 0'}}>
                  <div style={{display: 'flex', width: '80%'}}>
                    <div style={{color: 'black', fontSize: 14}}>
                      <div style={{fontSize: 16, color: '#404040'}}>{this.state.address.geolocation_text_short}</div>
                    </div>
                  </div>
                  <div style={{display: 'flex', width: '20%', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <a href="javascript://" onClick={() => this.resetGeolocation()} style={{textDecoration: 'none'}}>
                      <img src={require('../../../assets/icons/icon-edit-2.png')} style={{height: 16, width: 16}} />
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{padding: '10px 0', backgroundColor: 'white'}}>
                    <input type="text" value={this.state.control_address.search} placeholder="Ange adress där uppdraget ska utföras" onChange={(event) => this.onChangeSearchText(event.target.value)} style={{fontSize: 16, marginVertical: 10, border: 'none', boxSizing: 'border-box', width: '100%'}} />
                  </div>
                  {this.state.control_address.search == '' ? this.props.geolocations.map(geolocation => (
                    <a href="javascript://" onClick={() => this.selectGeolocation(geolocation)} key={geolocation.geolocation_id} style={{textDecoration: 'none'}}>
                     <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#ffffff', padding: '15px 20px', flexDirection: 'row', borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: '#808080'}}>
                        <div style={{display: 'flex', width: '80%'}}>
                          <div style={{color: 'black', fontSize: 14}}>
                            {geolocation.geolocation_text_short}
                          </div>
                        </div>
                        <div style={{display: 'flex', width: '20%', flexDirection: 'row', justifyContent: 'flex-end'}}>
                          <img src={require('../../../assets/icons/icon-address.png')} style={{height: 16, width: 16}} />
                        </div>
                      </div>
                    </a>
                  )) : null }
                  {this.state.control_address.options.map((option, index) => (
                    <a href="javascript://" onClick={() => this.selectGeolocation(option)} key={index} style={{textDecoration: 'none'}}>
                      <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#ffffff', padding: '15px 20px', flexDirection: 'row', borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: '#808080'}}>
                        <div style={{display: 'flex', width: '80%'}}>
                          <div style={{color: 'black', fontSize: 14}}>
                            {option.geolocation_text_short}
                          </div>
                        </div>
                        <div style={{display: 'flex', width: '20%', flexDirection: 'row', justifyContent: 'flex-end'}}>
                          <img src={require('../../../assets/icons/icon-address.png')} style={{height: 16, width: 16}} />
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              )}            
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDatePicker(date_control, heading, min_date) {
    return (
      <div style={{width: '100%'}}>
        {/*<div style={{paddingHorizontal: 10, marginBottom: 5}}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>{heading}</div>
        </div>*/}
        <div>
          <div style={{padding: '10px', borderStyle: 'solid', borderRadius: 4, borderWidth: 1, borderColor: '#808080', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
            <div style={{width: '50%'}}>
              <div style={{fontSize: 16, fontWeight: 'normal', color: 'black'}}>{heading}</div>
            </div>
            <div style={{width: '50%', alignItems: 'flex-end', display: 'flex', flexDirection: 'column'}}>
              <DatePicker popperClassName="byggbuddy-date-picker-popper" className="byggbuddy-date-picker" dateFormat="yyyy-MM-dd" locale="sv" selected={this.state.controls[date_control].value} onChange={date => this.updateDate(date_control, date)} style={{border: 'none', textAlign: 'right', fontSize: '16px'}} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContent() {

    if (!this.state.auction) {
      return null
    }

    let cart = this.props.cart
    let auction = this.state.auction
    let prerequisites = this.state.prerequisites
    let address = this.state.address

    let visible_option_selects = []

    for (var i = 0; i < this.state.option_selects.length; i++) {
      let option_select = this.state.option_selects[i]

      if (option_select.visible) {
        visible_option_selects.push(option_select)
      }
    }

    let any_without_price = false
    for (var i = 0; i < auction.job_tasks.length; i++) {
      if (auction.job_tasks[i].job_task_total_price_deducted == 0) {
        any_without_price = true
      }
    }

    // Calculate auction end date
    let auction_end_moment = moment().add(this.props.speeds[this.props.cart.speed], 'days')
    if (auction_end_moment.day() == 0) {
      // Sunday
      auction_end_moment.add(1, 'days')
    }
    else if (auction_end_moment.day() == 6) {
      // Sunday
      auction_end_moment.add(2, 'days')
    }
    
    let auction_end_date = utility.niceDateFormat(auction_end_moment.format("YYYY-MM-DD"))
    let min_startdate = moment().format("YYYY-MM-DD")

    let show_pricing = (!this.props.ui.is_branded || this.props.ui.brand_provider_account.provider_show_price_estimate)
  

    return (
      <>
        <div>
          {this.props.ui.is_saving ? (
            <div style={{backgroundColor: '#085e7c', padding: 15, borderTopWidth: 4, borderColor: '#000000'}}>
              <div style={{color: '#ffffff', fontSize: 14, fontStyle: 'italic', textAlign: 'left'}}>Sparar data...</div>
            </div>
          ) : null }
          <div style={{paddingBottom: 40}}>

            {/*this.props.shop.shop_provider_account_id == 0 && !this.state.address && cart.potential_provider_accounts && cart.potential_provider_accounts.length == 0 ? (
              <div
                style={{
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  padding: 20,
                  marginVertical: 0}}>
                <span style={{color: 'red'}}>Det finns tyvärr inga anslutna hantverkare som kan utföra de tjänster som du lagt i din varukorg.</span>
              </div>
            ) : null */}

            {/* 1. Tasks */}
            <div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px'}}>

              <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 0, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                  <div style={{color: 'white', fontSize: 40}}>1</div>
                </div>
                <div style={{flexGrow: 1}}>
                  <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Uppdrag</div>
                  <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Detta är den varukorg du håller på att checka ut</div>
                </div>
              </div>

              <div style={{backgroundColor: 'white', padding: '5px 20px'}}>
                {auction.job_tasks.map((job_task, index) => (
                  <div key={index} style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '10px', display: 'flex'}}>
                    <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                      <div style={{backgroundColor: '#026ca0'}}>
                        <img src={job_task.image_url} style={{width: '100%'}} />
                      </div>
                    </div>

                    <div style={{width: '65%', paddingLeft: 15, flexShrink: 100}}>
                      <div>{job_task.task_name}</div>
                    </div>

                    {show_pricing ? (
                      <div style={{width: '35%', paddingLeft: 15}}>
                        {job_task.job_task_total_price_deducted > 0 ? (
                          <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'normal'}}>{utility.niceNumberFormat(Math.ceil((job_task.job_task_total_price_deducted)/1000)*1000)} kr</div>
                        ) : (
                          <div style={{textAlign: 'right', fontSize: 9, fontWeight: 'bold'}}>Kan inte prisuppskattas</div>
                        )}
                      </div>
                    ) : null}
                    
                  </div>
                ))}
                {show_pricing > 0 && auction.job_tasks.length > 0 && auction.auction_reference_price_deducted > 0 ? (
                  <div style={{
                    /*backgroundColor: '#f0f0f0',*/
                    padding: '20px 0',
                    borderTop: '1px solid #a0a0a0',
                    marginTop: '15px',
                    }}>
                    <div style={{flexDirection: 'row', marginBottom: '15px', display: 'flex'}}>
                      <div style={{width: '65%'}}>
                        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 'normal'}}>Prisuppskattning</div>
                        <div style={{textAlign: 'left', fontSize: 10, fontWeight: 'normal', color: '#808080'}}>(inklusive moms före ROT-avdrag)</div>
                      </div>
                      <div style={{width: '35%'}}>
                        <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'bold'}}>{utility.niceNumberFormat(Math.ceil(auction.auction_reference_price / 1000) * 1000)} kr</div>
                      </div>
                    </div>
                    <div style={{flexDirection: 'row', marginBottom: '15px', display: 'flex'}}>
                      <div style={{width: '65%'}}>
                        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 'normal'}}>ROT-avdrag</div>
                        <div style={{textAlign: 'left', fontSize: 10, fontWeight: 'normal', color: '#808080'}}>(förutsätter att du är berättigad)</div>
                      </div>
                      <div style={{width: '35%'}}>
                        <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'normal', color: 'red'}}>{utility.niceNumberFormat(Math.ceil(auction.auction_reference_price_deducted/1000)*1000-Math.ceil(auction.auction_reference_price/1000)*1000)} kr</div>
                      </div>
                    </div>
                    <div style={{flexDirection: 'row', paddingTop: '15px', borderTopWidth: '1px', borderTopColor: '#808080'}}>
                      <div style={{width: '100%'}}>
                        <div style={{textAlign: 'center', fontSize: 30, fontWeight: 'bold'}}>{utility.niceNumberFormat(Math.ceil(auction.auction_reference_price_deducted/1000)*1000)} kr</div>
                        <div style={{textAlign: 'center', fontSize: 10, fontWeight: 'normal', color: '#808080'}}>Prisuppskattning inkl moms efter ROT-avdrag</div>
                        {any_without_price ? (
                           <div style={{fontSize: 11, textAlign: 'center', color: 'red', marginTop: '10px'}}>Det finns produkter i varukorgen som inte kunde prisuppskattas</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>
            </div>

            {/* 2. Images */}
            <div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px'}}>

              <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 10, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                  <div style={{color: 'white', fontSize: 40}}>2</div>
                </div>
                <div style={{flexGrow: 1}}>
                  <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Bilder</div>
                  <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Bilder underlättar mycket för hantverkarna.{'\n'}Ladda gärna upp redan nu!</div>
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  padding: 20,
                  marginVertical: 0}}>
                
                {auction.job_tasks.map((job_task, index) => (
                  <div key={index}>
                    {/*<div>
                      <div>{job_task.task_name}</div>
                    </div>*/}
                    <div style={{width: '100%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', display: 'flex'}}>
                      {job_task.job_task_images.map(job_task_image => (
                        <div style={{width: '100%', flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', marginBottom: '30px'}} key={job_task_image.task_item_image_id}>
                          <div style={{marginBottom: 20, width: '100%'}} key={job_task_image.task_item_image_id}>
                            <div onClick={(event) => this.openImageSelect(event)} style={{padding: 10, borderColor: '#000000', backgroundColor: '#f0f0f0', borderStyle: 'solid', borderWidth: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                              <div style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center'}}>{job_task.task_name}</div>
                              <img src={require('../../../assets/icons/icon-add-image.png')} style={{height: 64, width: 64}} />
                              <div style={{fontSize: 14, textAlign: 'center'}}>{job_task_image.image_instruction}</div>  
                              {job_task_image.image_files.length > 0 ? (
                                <div style={{justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 10, left: 10}}>
                                  <img src={require('../../../assets/icons/icon-check.png')} style={{height: 16, width: 16}} />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {job_task_image.image_files.map((image_file, file_index) => (
                            <div key={file_index} style={{marginBottom: 20, width: '100%', border: '1px solid #808080', position: 'relative'}}>
                              <div>
                                <img src={image_file.base64} style={{width: '100%', display: 'block'}} />
                                <div onClick={() => this.removeJobTaskImage(index, job_task_image.task_item_image_id, file_index)}>
                                  <div style={{height: 30, width: 30, borderRadius: 15, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 10, bottom: 10}}>
                                    <img src={require('../../../assets/icons/icon-delete.png')} style={{height: 16, width: 16, display: 'block'}} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div style={{padding: '0 0 20px'}} className="image_file_input" id={'image_file_input_' + job_task_image.task_item_image_id}>
                            <FileBase64
                              multiple={false }
                              onDone={(file) => this.addJobTaskImage(index, job_task_image.task_item_image_id, file)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* 3. Prerequisites */}
            {/*<div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px'}}>

              <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 10, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                  <div style={{color: 'white', fontSize: 40}}>3</div>
                </div>
                <div style={{flexGrow: 1}}>
                  <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Innan arbetet kan komma igång</div>
                  <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Här listar vi saker som du behöver ordna innan arbetet startar. Bekräfta genom att kryssa för respektive villkor.</div>
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  padding: 20,
                  marginVertical: 0}}>
                
                {prerequisites.length == 0 ? (
                  <div style={{fontStyle: 'italic'}}>Det finns inga förutsättningar som behöver bekräftas för detta uppdrag</div>
                ) : null}
                {prerequisites.map(prerequisite => (
                  <div style={{marginBottom:20, paddingHorizontal: 5, flex: 1, flexDirection: 'row', alignItems: 'center', display: 'flex'}} key={prerequisite.task_item_prerequisite_id}>
                    <div style={{width: '30px', flexGrow: 0}}>
                      <input type="checkbox" checked={prerequisite.confirmed} onChange={(event) => {this.confirmPrerequisite(prerequisite.task_item_prerequisite_id, event.target.value)}} />
                    </div>
                    <div style={{width: '200px', flexGrow: 1}}>
                      <div>
                        <div style={{fontSize: 14, textAlign: 'left', fontWeight:'normal'}}>{prerequisite.prerequisite_headline}</div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div> */}

            {/* 3. Options */}
            <div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px', paddingBottom: '20px'}}>

              <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 10, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                  <div style={{color: 'white', fontSize: 40}}>3</div>
                </div>
                <div style={{flexGrow: 1}}>
                  <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Allmänt om uppdraget</div>
                  <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Beskriv var och när uppdraget ska utföras</div>
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  padding: '0 20px',
                  marginVertical: 0}}>

                <div style={{paddingTop: 20, paddingBottom: 10}}>
                  {this.renderJobNameInput()}
                </div>

                <div style={{paddingVertical: 10}}>
                  {this.renderAddressSelect()}
                </div>

                <div style={{paddingTop: 10}}>
                  {this.renderDatePicker('preferred_startdate', 'Arbetet påbörjas tidigast', min_startdate)}
                </div>

                <div style={{paddingTop: 10}}>
                  {this.renderDatePicker('preferred_enddate', 'Arbetet avslutas senast', this.state.controls.preferred_startdate.value)}
                </div>

                {visible_option_selects.length > 0 ? (
                  <div style={{paddingVertical: 10}}>
                    {visible_option_selects.map(option_select => this.renderOptionSelect(option_select))}
                  </div>
                ) : null}

              </div>
            </div>

            {/* 4. Provider */}
            {this.props.shop.shop_provider_account_id == 0 ? (
              <div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px'}}>

                <div>
                  <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 50, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                    <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                      <div style={{color: 'white', fontSize: 40}}>4</div>
                    </div>
                    <div style={{flexGrow: 1}}>
                      <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Hantverkare</div>
                      <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Här väljer du vilka hantverkare du vill skicka förfrågan till. Du kan välja mellan de hantverkare som finns i din närhet och som har angivit att de utför de uppdrag som du lagt i din varukorg.</div>
                    </div>
                  </div>

                  {!this.state.address ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        backgroundColor: '#ffffff',
                        padding: 20,
                        marginVertical: 0}}>
                      <span style={{color: '#404040'}}>Välj adress där uppdraget ska utföras först.</span>
                    </div>
                  ) : null }
             
                  {this.state.address && cart.potential_provider_accounts && cart.potential_provider_accounts.length > 0 ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        backgroundColor: '#ffffff',
                        padding: 20,
                        marginVertical: 0}}>
                      
                      {this.props.cart.potential_provider_accounts.map((provider_account) => (
                        <div
                          key={provider_account.provider_account_id}
                          onClick={() => this.toggleProvider(provider_account.provider_account_id)}
                          style={{alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  /*backgroundColor: 'rgb(13, 122, 62)',*/
                                  backgroundColor: 'white',
                                  borderRadius: '4px',
                                  marginBottom: '30px',
                                  width: '100%',
                                  height: '250px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  WebkitBoxShadow: '5px 5px 15px -5px #808080', 
                                  boxShadow: '5px 5px 15px -5px #808080',
                                  cursor: 'pointer',
                                  boxSizing: 'border-box',
                                  border: (this.state.provider_account_ids.indexOf(provider_account.provider_account_id) >= 0 ? '3px solid rgb(13, 122, 62)' : 'none'),
                                }}>
                          
                          <div style={{width: '40%', height: '250px', backgroundImage: (provider_account.provider_images.length > 0 ? 'url(' + provider_account.provider_images[0].image_url + ')' : 'url(' + DefaultProviderImage + ')'), backgroundSize: 'cover', backgroundPosition: 'center center'}}>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'space-between', width: '60%', height: '250px', boxSizing: 'border-box'}}>
                            <div style={{display: 'flex', flexShrink: 1, flexDirection: 'column', alignItems: 'left', width: '100%', height: '215px', padding: '20px', boxSizing: 'border-box'}}>
                              <div style={{width: '100%', flexShrink: 0}}>
                                {provider_account.provider_logo ? (
                                  <div
                                   style={{
                                     color: 'black',
                                     fontSize: 20,
                                     fontFamily: 'MuseoSans-300',
                                     textAlign: 'left',
                                     marginBottom: '10px',
                                  }}>
                                    <img src={provider_account.provider_logo.image_url} style={{width: '160px'}} />
                                  </div>
                                ) : (
                                  <div
                                   style={{
                                     color: 'black',
                                     fontSize: 20,
                                     fontFamily: 'MuseoSans-300',
                                     textAlign: 'left',
                                     marginBottom: '10px',
                                    }}>
                                    {provider_account.provider_name}
                                  </div>
                                )}
                              </div>
                              <div style={{width: '100%', flexShrink: 0}}>
                                <div
                                   style={{
                                     color: '#808080',
                                     fontSize: 12,
                                     fontFamily: 'MuseoSans-300',
                                     textAlign: 'left',
                                     marginBottom: '10px',
                                     overflow: 'hidden',
                                  }}>
                                  {provider_account.provider_locations.map(provider_location => utility.cityAddress(provider_location.geolocation_text)).join(', ')}
                                </div>
                              </div>
                              <div style={{width: '100%', flexShrink: 1, overflow: 'hidden'}}>
                                <div
                                   style={{
                                     color: '#404040',
                                     fontSize: 13,
                                     fontFamily: 'MuseoSans-300',
                                     textAlign: 'left',
                                     whiteSpace: 'pre-line',
                                  }}>
                                  {provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 ? provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 : utility.truncateTextRows(provider_account.provider_description, 6, 40)}
                                </div>
                              </div>
                            </div>
                            {this.state.provider_account_ids.indexOf(provider_account.provider_account_id) >= 0 ? (
                              <div style={{width: '100%', height: '35px', flexShrink: 0, backgroundColor: 'rgb(13, 122, 62)', color: 'white', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', padding: '10px', boxSizing: 'border-box'}}>
                                Vald
                              </div>
                            ) : (
                              <div style={{width: '100%', height: '35px', flexShrink: 0, backgroundColor: '#c0c0c0', color: 'white', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', padding: '10px', boxSizing: 'border-box'}}>
                                Inte vald
                              </div>
                            )}
                            
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null }

                  {this.state.address && cart.potential_provider_accounts && cart.potential_provider_accounts.length == 0 ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        backgroundColor: '#ffffff',
                        padding: 20,
                        marginVertical: 0}}>
                      <span style={{color: 'red'}}>Det finns tyvärr inga anslutna hantverkare i ditt område som kan utföra de tjänster som du lagt i din varukorg.</span><br /><br />
                      <span style={{color: 'red'}}>Du kan ändå skicka in din förfrågan, men det kan ta längre tid än vanligt att få svar eftersom Byggbuddy behöver leta möjliga hantverkare i ditt område. </span>
                    </div>
                  ) : null }
                </div>
              </div>
            ) : null }


            {/* 5. Customer info - if not logged in */}
            {this.props.scope != 'customer' ? (
              <div style={{backgroundColor: 'white', WebkitBoxShadow: '5px 5px 15px -5px #808080', boxShadow: '5px 5px 15px -5px #808080', marginBottom: '30px'}}>

                <div style={{marginTop: '50px'}}>

                  <div style={{flex: 1, flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 10, backgroundColor: '#026ca0', alignItems: 'center', display: 'flex'}} >
                    <div style={{width: '50px', flexGrow: 0, flexShrink: 0}}>
                      <div style={{color: 'white', fontSize: 40}}>{this.props.shop.shop_provider_account_id == 0 ? '5' : '4'}</div>
                    </div>
                    <div style={{flexGrow: 1}}>
                      <div style={{fontSize: 18, fontWeight: '600', textAlign: 'left', color: 'white'}}>Din kontaktuppgifter</div>
                      <div style={{fontSize: 10, fontWeight: '600', textAlign: 'left', color: 'white'}}>Vi behöver dina kontaktuppgifter för att kunna svara på din förfrågan. Du förbinder dig inte vid något när du skickar en förfrågan.</div>
                    </div>
                  </div>

                  <div
                    style={{
                      justifyContent: 'center',
                      backgroundColor: '#ffffff',
                      padding: 20,
                      marginVertical: 0}}>
                    
                    <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (this.state.controls.first_name.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <DefaultInput
                       placeholder="Ditt förnamn"
                       value={String(this.state.controls.first_name.value)}
                       onChange={event => this.updateInputState('first_name', event.target.value)}
                       style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                      />
                    </div>

                    <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (this.state.controls.last_name.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <DefaultInput
                       placeholder="Ditt efternamn"
                       value={String(this.state.controls.last_name.value)}
                       onChange={event => this.updateInputState('last_name', event.target.value)}
                       style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                      />
                    </div>

                    <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (this.state.controls.email.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <DefaultInput
                       placeholder="Din e-postadress"
                       value={String(this.state.controls.email.value)}
                       onChange={event => this.updateInputState('email', event.target.value)}
                       style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                      />
                    </div>

                    <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: '#808080', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <DefaultInput
                       placeholder="Ditt telefonnummer (frivillig uppgift)"
                       value={String(this.state.controls.phone.value)}
                       onChange={event => this.updateInputState('phone', event.target.value)}
                       style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                      />
                    </div>

                  </div>
                </div>
              </div>
            ) : null}
            
          </div>
        </div>

        {this.props.shop.shop_provider_account_id > 0 && this.state.provider_account_ids.length == 0 ? (
          <div
            style={{
              justifyContent: 'center',
              backgroundColor: '#ffffff',
              padding: 20,
              marginVertical: 0,
              textAlign: 'center'}}>
            <span style={{color: 'red'}}>Adressen där uppdraget ska utföras ligger utanför denna hantverkares angivna arbetsområde.</span>
          </div>
        ) : null }

        {/* Floating action */}
        <div style={{flex: 1, flexDirection: 'column', alignItems: 'center', width: '100%',
          borderColor: '#808080',
          bottom: 0, 
          /*backgroundColor: 'white',*/ 
          padding: '10px 0',
          paddingBottom:30,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: -10,
          },
          shadowOpacity: 0.5,
          shadowRadius: 15,}}>

          {show_pricing ? (
            <div style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{flexDirection: 'column', alignItems: 'center'}}>
                {auction.auction_reference_price_deducted == 0 ? (
                  <div style={{fontSize: 11, textAlign: 'center', color: 'black', fontWeight: 'bold'}}>Varukorgen kan inte prisuppskattas. Checka ut så får du ett pris när offert lämnas.</div>
                ) : (
                  <div>
                    <div style={{fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center',}}>
                      {utility.niceNumberFormat(Math.ceil(auction.auction_reference_price_deducted/1000)*1000)} <div style={{fontSize: 16, marginLeft: '5px', marginBottom: '5px'}}>kr</div>
                    </div>
                    <div style={{fontSize: 11, textAlign: 'center', color: '#808080'}}>Prisuppskattning inkl moms efter ROT-avdrag</div>
                    {any_without_price ? (
                       <div style={{fontSize: 11, textAlign: 'center', color: 'red'}}>Det finns produkter i varukorgen som inte kunde prisuppskattas</div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {!this.state.auction_is_starting ? (
            <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <DefaultButton
                style={{width: '100%', maxWidth: '300px', backgroundColor: '#167f40', margin: '15px 0'}}
                onClick={() => this.startAuction()}
                disabled={
                  !this.state.job_name ||
                  !this.state.address ||
                  (this.props.scope != 'customer' && (
                    !this.state.controls.first_name.value ||
                    !this.state.controls.last_name.value ||
                    !this.state.controls.email.value
                  )) ||
                  (this.state.provider_account_ids.length == 0 && this.props.cart.potential_provider_accounts.length > 0)
                }
              >
                Skicka förfrågan
              </DefaultButton>
              <div style={{width: '100%', padding: '0 20px', boxSizing: 'border-box'}}>
                <div style={{fontStyle: 'italic', fontSize: 11, color: '#808080', textAlign: 'center'}}>Priset som anges är en uppskattning och inte bindande, men ger en fingervisning om vad uppdraget kostar. Det är vanligt att den offert du får skiljer sig upp till 20% i pris från denna uppskattning, i undantagsfall mer än så. Du förbinder dig inte vid något när du skickar en förfrågan. Det är först när du fått en offert och väljer att acceptera den som du ingår avtal. </div>
              </div>
            </div>
          ) : (
            <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <DefaultButton
                style={{width: '100%', maxWidth: '300px', backgroundColor: '#167f40', margin: '15px 0'}}
                disabled={true}
              >
                Din förfrågan skickas. Vänta...
              </DefaultButton>
              <div style={{width: '100%', padding: '0 20px', boxSizing: 'border-box'}}>
                <div style={{fontStyle: 'italic', fontSize: 11, color: '#808080', textAlign: 'center'}}>Du förbinder dig inte vid något när du skickar en förfrågan. Det är först när du fått en offert och väljer att acceptera den som du ingår avtal.</div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  speeds: state.shop.speeds,
  cart: state.shop.cart,
  shop: state.shop,
  options: state.shop.options,
  scope: state.auth.scope,
  geolocations: state.shop.geolocations,
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    onAddImageToTask: (task_item_image_id, image_uri, cart_task_index) => dispatch(addImageToTask(task_item_image_id, image_uri, cart_task_index)),
    onRemoveImageFromTask: (index, cart_task_index) => dispatch(removeImageFromTask(index, cart_task_index)),
    onDoneImagesToTask: (current_task_image_id, cart_task_index) => dispatch(doneImagesToTask(current_task_image_id, cart_task_index)),
    initiateCheckout: (initiate, cart_task_index) => dispatch(initiateCheckout(initiate, cart_task_index)),
    confirmPrerequisites: (confirm, cart_task_index) => dispatch(confirmPrerequisites(confirm, cart_task_index)),
    onSetCartMaxPricePerHour: (value) => dispatch(setCartMaxPricePerHour(value)),
    onSetCartAddress: (address) => dispatch(setCartAddress(address)),
    onSelectCartJobOption: (option_id) => dispatch(selectCartJobOption(option_id)),
    onSetCartSpeed: (speed) => dispatch(setCartSpeed(speed)),
    onSetCartJobName: (job_name) => dispatch(setCartJobName(job_name)),
    onSetCartJobText: (job_text) => dispatch(setCartJobText(job_text)),
    onSetCartPreferredStartdate: (date) => dispatch(setCartPreferredStartDate(date)),
    onSetCartPreferredEnddate: (date) => dispatch(setCartPreferredEndDate(date)),
    onSetCartProviderAccountIds: (provider_account_ids) => dispatch(setCartProviderAccountIds(provider_account_ids)),
    onCheckoutCart: (customer) => dispatch(checkoutCart(customer)),
    pushDebugMessage: (message) => dispatch(pushDebugMessage(message)),
    getGeolocations: (search_text) => dispatch(getGeolocations(search_text)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartAuctionScreen)
