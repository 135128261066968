import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'

import _ from 'lodash'
import { Analytics } from '../../../services/analytics.service'

import ProviderMessages from './../Messages/ProviderMessages'


class ProviderJobScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  content_padding = '0'
  content_max_width = 'none'

  state = {
    job_image_index: 0,
    job_image_popup: false,
  }

  renderBrandedContent(provider_account) {

    let job = null

    for (var i = 0; i < this.props.jobs.length; i++) {
      let j = this.props.jobs[i]
      if (j.job_id == this.props.job_id) {
        job = j
      }
    }

    if (!job) {
      return null
    }

    console.log(job)

    return (

      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
        
        {this.state.job_image_popup ? (
          <div onClick={() => this.popupJobImage(false)} style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', backgroundColor: 'black', border: '10px solid white', boxSizing: 'border-box'}}>
            <img src={job.job_images[this.state.job_image_index].job_image_url} style={{width: '100%', maxHeight: 'calc(100vh - 20px)', objectFit: 'contain'}} />
          </div>
        ) : null}
        
        <div style={{display: 'flex', flexDirection: 'column', flexBasis: '400px', flexGrow: 0, flexShrink: 1, padding: '15px', backgroundColor: '#f8f8f8'}}>
          <div style={{fontSize: '24px', fontWeight: 'bold'}}>
            {job.job_name}
          </div>
          <div style={{fontSize: '16px', fontWeight: 'bold'}}>
            {job.geolocation_text}
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
            <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
              <span className="material-icons">person</span>
            </div>
            <div>
              <div>{job.first_name} {job.last_name}</div>
              <div>{job.email}</div>
              <div>{job.phone}</div>
            </div>
          </div>

          {job.job_hours ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">watch_later</span>
              </div>
              <div>
                <div>{utility.niceNumberFormat(job.job_hours)} timmar</div>
                <div>Uppskattad tidsåtgång</div>
              </div>
            </div>
          ) : null}

          {job.job_options.length ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">home</span>
              </div>
              <div>
                {job.job_options.map(job_option => (
                  <div key={job_option.job_option_id}>{job_option.option_name}</div>
                ))}
              </div>
            </div>
          ) : null}

          <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
            <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
              <span className="material-icons">date_range</span>
            </div>
            <div>
              <div>Start tidigast {job.preferred_startdate}</div>
              <div>Färdigt senast {job.preferred_enddate}</div>
            </div>
          </div>

          {job.job_text ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">assignment</span>
              </div>
              <div>
                <div>{job.job_text}</div>
              </div>
            </div>
          ) : null}

          {job.job_tasks.map(job_task => (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}} key={job_task.job_task_id}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <div style={{width: '24px', height: '24px', padding: '2px', boxSizing: 'border-box', backgroundColor: 'black', borderRadius: '3px'}}>
                  <img src={job_task.image_url} style={{width: '100%', objectFit: 'contain'}} />
                </div>
              </div>
              <div>
                <div>{job_task.task_name}</div>
                <div>{job_task.job_task_text}</div>
                {job_task.job_task_drivers.map(job_task_driver => (
                  <div key={job_task_driver.job_task_driver_value_id}>{job_task_driver.driver_name}: {job_task_driver.driver_value} {job_task_driver.driver_metric}</div>
                ))}
                <ul style={{paddingInlineStart: '30px'}}>
                  {job_task.job_task_items.map(job_task_item => (
                    <li key={job_task_item.job_task_item_id} style={{marginBottom: '5px'}}>{job_task_item.task_item_name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          
        </div>
        <div style={{display: 'flex', flexDirection: 'column', flexBasis: '300px', flexGrow: 1, flexShrink: 5, padding: '15px'}}>
          
          {/* Job images */}
          {job.job_images.length ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{}} onClick={() => this.popupJobImage(true)}>
                <img src={job.job_images[this.state.job_image_index].job_image_url} style={{width: '100%', maxHeight: '400px', objectFit: 'cover'}} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '15px'}}>
                {job.job_images.map((job_image, index) => (
                  <div style={{width: '100px', marginRight: '15px'}} onClick={() => this.setJobImage(index)} key={job_image.job_image_id}>
                    <img src={job_image.job_image_url} style={{width: '100%'}} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* Messages */}
          {/*<div>
            <ProviderMessages job_id={job.job_id} key={job.job_id} />
          </div>*/}

        </div>
        
      </div>

    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  jobs: state.jobs.jobs,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderJobScreen)
