import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'

import _ from 'lodash'
import { Analytics } from '../../../services/analytics.service'

import ProviderAuction from './ProviderAuction'


class ProviderAuctionsScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  content_padding = '0px'
  content_max_width = 'none'

  selectAuction = (selected_auction_id) => {
    if (selected_auction_id) {
      this.goTo('/provider/auctions/' + selected_auction_id)
    }
    else {
      this.goTo('/provider/auctions')
    }
  }

  renderBrandedContent(provider_account) {

    let selected_auction_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.auction_id) {
      selected_auction_id = this.props.match.params.auction_id
    }

    let auctions = []
    let specific_selected = false

    if (selected_auction_id == 'ongoing') {
      auctions = _.filter(this.props.auctions, (a) => { return a.auction_status == 'ongoing' && a.provider_status == 'invited' })
    }
    else if (selected_auction_id == 'aborted') {
      auctions = _.filter(this.props.auctions, (a) => { return a.auction_status != 'ongoing' || a.provider_status != 'invited' })
    }
    else {
      specific_selected = true
      for (var i = 0; i < this.props.auctions.length; i++) {
        let a = this.props.auctions[i]
        if (a.auction_id == selected_auction_id) {
          let auction_status = a.auction_status

          if (auction_status == 'ongoing') {
            auctions = _.filter(this.props.auctions, {auction_status: 'ongoing'})
          }
          else {
            auctions = _.filter(this.props.auctions, (a) => { return a.auction_status != 'ongoing' })
          }
        }
      }
    }

    return (

      <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      
        <div className={'byggbuddy-auction-list' + (specific_selected ? ' item-selected' : '')} style={{flexShrink: '0', flexGrow: '0', borderRight: '1px solid rgb(192, 192, 192)'}}>

          {auctions.map(auction => (
            <div key={auction.auction_id} className={'byggbuddy-auction-card' + (auction.auction_id == selected_auction_id ? ' selected' : '')} style={{padding: '15px', cursor: 'pointer', borderBottom: '1px solid rgb(192, 192, 192)'}} onClick={() => this.selectAuction(auction.auction_id)}>
              {this.renderAuctionCard(auction)}
            </div>
          ))}            
        </div>

        {specific_selected ? (
          <div className={'byggbuddy-auction-container item-selected'} style={{flexGrow: '1'}}>
            <div className={'byggbuddy-auction-container-back'} style={{padding: '15px', cursor: 'pointer'}}>
              <div style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} onClick={() => this.selectAuction(0)}><span className="material-icons">arrow_back_ios</span> Tillbaka</div>
            </div>
            <ProviderAuction {...this.props} auction_id={selected_auction_id} />
          </div>
        ) : null}

        {!specific_selected && auctions.length ? (
          <div className={'byggbuddy-auction-container'} style={{flexGrow: '1'}}>
            <div className={'byggbuddy-auction-container-back'} style={{padding: '15px', cursor: 'pointer'}}>
              <div style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} onClick={() => this.selectAuction(0)}><span className="material-icons">arrow_back_ios</span> Tillbaka</div>
            </div>
            <ProviderAuction {...this.props} auction_id={auctions[0].auction_id} />
          </div>
        ) : null}

        {!specific_selected && !auctions.length ? (
          <div className={'byggbuddy-auction-placeholder'} style={{padding: '20px'}}>
            <i>Det finns inga förfrågningar</i>
          </div>
        ) : null}

      </div>

    )
  }

  renderAuctionCard(auction) {
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '35%', maxWidth: '150px', flexGrow: 0, flexShrink: 5}}>
          <img src={auction.job_images.length ? auction.job_images[0].job_image_url : require('../../../assets/images/background-5.jpg')} style={{width: '100%'}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '65%', marginLeft: '15px'}}>
          <div style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
            {auction.job_name}
          </div>
          <div style={{marginBottom: '10px'}}>
            {auction.geolocation_text}
          </div>
          <div style={{fontSize: '14px'}}>
           {auction.first_name} {auction.last_name}
          </div>
          {auction.job_hours ? (
            <div style={{fontSize: '14px'}}>
              Uppskattad omfattning {utility.niceNumberFormat(auction.job_hours)}
            </div>
          ) : null}
          <div style={{fontSize: '14px'}}>
            Start tidigast {auction.preferred_startdate}
          </div>
          <div style={{fontSize: '14px'}}>
            Färdigt senast {auction.preferred_enddate}
          </div>
        </div>
      </div>
    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  auctions: state.auctions.auctions,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderAuctionsScreen)
