import React from 'react';
import { matchPath } from 'react-router';
import { Route } from 'react-router-dom';
import { history } from '../../App'


class PersistentRoute extends Route { //React.Component {

  state = {
    children: [],
    props: {},
  }

  componentWillReceiveProps() {

    let location = window.location.hash
    location = location.substring(11) // Remove '#/byggbuddy'

    let match = matchPath(location,  {
      path: this.props.path,
      exact: false,
      strict: false
    });

    if (match) {

      let url = match.url
      let component = this.props.component

      let child_exists = false
      for (var i = 0; i < this.state.children.length; i++) {
        let child = this.state.children[i]
        if (child.url == url) {
          child_exists = true
        }
      }

      if (!child_exists) {
        this.setState(prev_state => {
          let children = [...prev_state.children]
          for (var i = 0; i < children.length; i++) {
            let child = children[i]
            child.active = false
          }
          children.push({
            active: true,
            path: this.props.path,
            url,
            component,
            match,
            persist: (this.props.persist && this.props.persist != false)
          })
          return {
            ...prev_state,
            children,
          }
        })
      }
      else {
      
        this.setState(prev_state => {
        
          let children = [...prev_state.children]
        
          for (var i = 0; i < children.length; i++) {
            let child = children[i]

            let rematch = matchPath(location,  {
              path: child.path,
              exact: false,
              strict: false
            })
            child.active = (rematch != null && rematch.url == child.url)
          }
          return {
            ...prev_state,
            children,
          }
        })
      }
    }
    else {
      
      this.setState(prev_state => {
      
        let children = [...prev_state.children]
      
        for (var i = 0; i < children.length; i++) {
          let child = children[i]
          child.active = false
        }
        return {
          ...prev_state,
          children,
        }
      })
    }
  }

  render() {

    
    return (
      <div>
        {this.state.children.map((child) => {
          let $Component = child.component
          return (child.active || child.persist) ? (
            <$Component {...this.props} match={child.match} history={history} active={child.active} />
          ) : null}
        )}
      </div>
    )
  }
}

export default PersistentRoute