import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import utility from '../../../utility/utility';

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import { pushDebugMessage } from '../../../store/actions/debug'
import {
  selectSearchTask,
  selectSearchTaskItem,
  setSearchDriverValue,
  setSearchTaskText,
  addSearchToCart,
  doneImagesToTask,
  setSearchTaskAnswer,
  resetSearch,
  submitTaskMissingText,
} from '../../../store/actions/shop'
import {
  armHelpPopup,
  disarmHelpPopup
} from '../../../store/actions/ui'

import Cart from './../../../components/Shop/Cart'

class AddTaskToCartScreen extends ByggbuddyScreen {

  state = {
    task: null,
    root_task_item_ids: [],
    task_item_selects: [],
    driver_values: [],
    task_item_question_inputs: [],
    task_text: '',
    total_price_deducted: 0,
  }

  goBack = () => {
    this.props.history.goBack()
  }

  async componentDidMount() {

    let task_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.task_id) {
      task_id = this.props.match.params.task_id
      await this.props.selectSearchTask(task_id)
    }

    if (!task_id && this.props.shop.search) {
      task_id = this.props.shop.search.task_id
    }

    let task = null
    let root_task_item_ids = this.props.shop.search.task_item_ids
    let task_item_selects = []
    let driver_values = []
    let task_item_question_inputs = []

    // Task
    for (var i = 0; i < this.props.shop.tasks.length; i++) {
      let t = this.props.shop.tasks[i]

      if (t.task_id == task_id) {
        task = t
      }
    }

    // Task items
    for (var i = 0; i < root_task_item_ids.length; i++) {
      this.addTaskItemsRecursive(root_task_item_ids[i], true, task_item_selects)
    }

    // Task item questions
    for (var i = 0; i < root_task_item_ids.length; i++) {
      this.addTaskItemQuestionsRecursive(root_task_item_ids[i], root_task_item_ids, task_item_selects, task_item_question_inputs)
    }

    // Drivers
    for (var i = 0; i < root_task_item_ids.length; i++) {
      for (var j = 0; j < this.props.shop.task_items.length; j++) {
        let task_item = this.props.shop.task_items[j]

        if (task_item.task_item_id == root_task_item_ids[i]) {
          if (task_item.leaf && task_item.driver_id > 0) {
            for (var k = 0; k < this.props.shop.drivers.length; k++) {
              let driver = this.props.shop.drivers[k]

              if (driver.driver_id == task_item.driver_id) {

                let visible = true
                let driver_exists = false
                for (var l = 0; l < driver_values.length; l++) {
                  let driver_value = driver_values[l]
                  if (driver_value.driver_id == driver.driver_id) {
                    driver_exists = true
                    if (visible) {
                      driver_value.visible = true
                    }
                  }
                }
                if (!driver_exists) {
                  driver_values.push({ ...driver, driver_value: '', visible })
                }
              }
            }
          }
        }
      }
    }

    for (var i = 0; i < task_item_selects.length; i++) {
      let task_item_select = task_item_selects[i]

      for (var j = 0; j < task_item_select.child_task_items.length; j++) {
        let child_task_item = task_item_select.child_task_items[j]

        if (child_task_item.leaf) {
          for (var k = 0; k < this.props.shop.drivers.length; k++) {
            let driver = this.props.shop.drivers[k]

            if (driver.driver_id == child_task_item.driver_id) {

              let visible = (child_task_item.task_item_id == task_item_select.selected_child_task_item_id)
              let driver_exists = false
              for (var l = 0; l < driver_values.length; l++) {
                let driver_value = driver_values[l]
                if (driver_value.driver_id == driver.driver_id) {
                  driver_exists = true
                  if (visible) {
                    driver_value.visible = true
                  }
                }
              }
              if (!driver_exists) {
                driver_values.push({ ...driver, driver_value: '', visible })
              }
            }
          }
        }
      }
    }


    this.setState(prev_state => {
      return {
        ...prev_state,
        task,
        root_task_item_ids,
        task_item_selects,
        task_item_question_inputs,
        driver_values,
      }
    }, () => {
      this.calculatePrice()
    })
  }

  addTaskItemsRecursive(task_item_id, visible, task_item_selects) {

    for (var i = 0; i < this.props.shop.task_items.length; i++) {
      let task_item = this.props.shop.task_items[i]

      if (task_item.task_item_id == task_item_id) {
        if (task_item.leaf == 0) {

          let task_item_select = {
            ...task_item,
            visible,
            modal_open: false,
            selected_child_task_item_id: 0,
            child_task_items: [],
          }

          for (var j = 0; j < this.props.shop.task_items.length; j++) {
            let child_task_item = this.props.shop.task_items[j]

            if (child_task_item.parent_task_item_id == task_item.task_item_id) {
              task_item_select.child_task_items.push(child_task_item)
            }
          }

          if (task_item_select.child_task_items.length > 0) {
            task_item_select.selected_child_task_item_id = task_item_select.child_task_items[0].task_item_id
          }

          task_item_selects.push(task_item_select)

          // Recursive
          for (var j = 0; j < task_item_select.child_task_items.length; j++) {
            this.addTaskItemsRecursive(task_item_select.child_task_items[j].task_item_id, visible && (task_item_select.selected_child_task_item_id == task_item_select.child_task_items[j].task_item_id), task_item_selects)
          }
        }
      }
    }
  }

  addTaskItemQuestionsRecursive(task_item_id, root_task_item_ids, task_item_selects, task_item_question_inputs) {

    for (var i = 0; i < this.props.shop.task_items.length; i++) {
      let task_item = this.props.shop.task_items[i]

      if (task_item.task_item_id == task_item_id) {

        for (var j = 0; j < this.props.shop.task_item_questions.length; j++) {
          let task_item_question = this.props.shop.task_item_questions[j]

          if (task_item_question.task_item_id == task_item_id) {

            // Visible?
            let visible = false
            for (var k = 0; k < root_task_item_ids.length; k++) {
              if (root_task_item_ids[k] == task_item_question.task_item_id) {
                visible = true
              }
            }
            for (var k = 0; k < task_item_selects.length; k++) {
              let task_item_select = task_item_selects[k]

              if (task_item_select.visible && task_item_select.selected_child_task_item_id == task_item_question.task_item_id) {
                visible = true
              }
            }

            let task_item_question_input = {
              ...task_item_question,
              visible,
              answer_text: '',
            }
            task_item_question_inputs.push(task_item_question_input)
          }
        }

        if (task_item.leaf == 0) {

          let child_task_items = []

          for (var j = 0; j < this.props.shop.task_items.length; j++) {
            let child_task_item = this.props.shop.task_items[j]

            if (child_task_item.parent_task_item_id == task_item.task_item_id) {
              child_task_items.push(child_task_item)
            }
          }

          // Recursive
          for (var j = 0; j < child_task_items.length; j++) {
            this.addTaskItemQuestionsRecursive(child_task_items[j].task_item_id, root_task_item_ids, task_item_selects, task_item_question_inputs)
          }
        }
      }
    }
  }

  updateDriverValue(driver_id, value) {
    this.setState(prev_state => {

      if (typeof value === 'string') {
        value = value.replace(',', '.')
        value = value.replace(' ', '')
      }

      let driver_values = [...prev_state.driver_values]

      for (var i = 0; i < driver_values.length; i++) {
        let driver_value = driver_values[i]

        if (driver_value.driver_id == driver_id) {
          driver_value.driver_value = value
        }
      }

      return {
        ...prev_state,
        driver_values,
      }
    }, () => {
      this.calculatePrice()
    })
  }

  updateTaskItemQuestionInput(task_item_question_id, value) {
    this.setState(prev_state => {

      let task_item_question_inputs = [...prev_state.task_item_question_inputs]

      for (var i = 0; i < task_item_question_inputs.length; i++) {
        let task_item_question_input = task_item_question_inputs[i]

        if (task_item_question_input.task_item_question_id == task_item_question_id) {
          task_item_question_input.answer_text = value
        }
      }

      return {
        ...prev_state,
        task_item_question_inputs,
      }
    })
  }

  updateTaskText(value) {
    this.setState(prev_state => {
      return {
        ...prev_state,
        task_text: value,
      }
    })
  }

  toggleTaskItemSelectModal(task_item_id) {
    this.setState(prev_state => {

      let task_item_selects = [...prev_state.task_item_selects]

      for (var i = 0; i < task_item_selects.length; i++) {
        let task_item_select = task_item_selects[i]

        if (task_item_select.task_item_id == task_item_id) {
          task_item_select.modal_open = !task_item_select.modal_open
        }
      }

      return {
        ...prev_state,
        task_item_selects,
      }
    })
  }

  selectTaskItem(task_item_id, child_task_item_id) {
    this.setState(prev_state => {

      let task_item_selects = [...prev_state.task_item_selects]
      let driver_values = [...prev_state.driver_values]
      let task_item_question_inputs = [...prev_state.task_item_question_inputs]

      for (var i = 0; i < task_item_selects.length; i++) {
        let task_item_select = task_item_selects[i]

        if (task_item_select.task_item_id == task_item_id) {
          let previous_child_task_item_id = task_item_select.selected_child_task_item_id
          task_item_select.selected_child_task_item_id = child_task_item_id
          task_item_select.modal_open = false

          this.setTaskItemSelectVisibilityRecursive(previous_child_task_item_id, false, task_item_selects)
          this.setTaskItemSelectVisibilityRecursive(child_task_item_id, true, task_item_selects)
          this.setDriverValuesVisibility(driver_values, task_item_selects)
          this.setTaskItemQuestionInputsVisibility(task_item_question_inputs, task_item_selects)
        }
      }

      return {
        ...prev_state,
        task_item_selects,
        driver_values,
        task_item_question_inputs,
      }
    }, () => {
      this.calculatePrice()
    })
  }

  openTaskItem(task_item_id) {
    this.setState(prev_state => {

      let task_item_selects = [...prev_state.task_item_selects]
      for (var i = 0; i < task_item_selects.length; i++) {
        let task_item_select = task_item_selects[i]

        if (task_item_select.task_item_id == task_item_id) {
          task_item_select.modal_open = true
        }
        else {
          task_item_select.modal_open = false
        }
      }

      return {
        ...prev_state,
        task_item_selects,
      }
    })
  }

  closeTaskItems() {
    this.setState(prev_state => {
      let task_item_selects = [...prev_state.task_item_selects]
      let openModal = task_item_selects.find(task_item_select => task_item_select.modal_open);
      if (openModal != undefined) {
        setTimeout(() => {
          openModal.modal_open = false;
        }, 100);
      }
      return {
        ...prev_state,
        task_item_selects,
      }
    })
  }

  setTaskItemSelectVisibilityRecursive(task_item_id, visible, task_item_selects) {

    for (var i = 0; i < task_item_selects.length; i++) {
      let task_item_select = task_item_selects[i]

      if (task_item_select.task_item_id == task_item_id) {
        task_item_select.visible = visible

        for (var j = 0; j < task_item_select.child_task_items.length; j++) {
          if (task_item_select.child_task_items[j].task_item_id == task_item_select.selected_child_task_item_id || !visible) {
            this.setTaskItemSelectVisibilityRecursive(task_item_select.child_task_items[j].task_item_id, visible, task_item_selects)
          }
        }
      }
    }
  }

  setDriverValuesVisibility(driver_values, task_item_selects) {

    for (var i = 0; i < driver_values.length; i++) {
      let driver_value = driver_values[i]

      let visible = false

      for (var j = 0; j < this.state.root_task_item_ids.length; j++) {
        for (var k = 0; k < this.props.shop.task_items.length; k++) {
          let task_item = this.props.shop.task_items[k]

          if (task_item.leaf && task_item.task_item_id == this.state.root_task_item_ids[j]) {
            if (task_item.driver_id == driver_value.driver_id) {
              visible = true
            }
          }
        }
      }

      for (var j = 0; j < task_item_selects.length; j++) {
        let task_item_select = task_item_selects[j]

        if (task_item_select.visible) {
          for (var k = 0; k < task_item_select.child_task_items.length; k++) {
            let child_task_item = task_item_select.child_task_items[k]

            if (child_task_item.task_item_id == task_item_select.selected_child_task_item_id && child_task_item.driver_id == driver_value.driver_id) {
              visible = true
            }
          }
        }
      }
      driver_value.visible = visible
    }
  }

  setTaskItemQuestionInputsVisibility(task_item_question_inputs, task_item_selects) {

    for (var i = 0; i < task_item_question_inputs.length; i++) {
      let task_item_question_input = task_item_question_inputs[i]

      let visible = false
      for (var k = 0; k < this.state.root_task_item_ids.length; k++) {
        if (this.state.root_task_item_ids[k] == task_item_question_input.task_item_id) {
          visible = true
        }
      }
      for (var k = 0; k < task_item_selects.length; k++) {
        let task_item_select = task_item_selects[k]

        if (task_item_select.visible && task_item_select.selected_child_task_item_id == task_item_question_input.task_item_id) {
          visible = true
        }
      }
      task_item_question_input.visible = visible
    }
  }

  calculatePrice() {
    this.setState(prev_state => {

      let total_hours = 0
      let total_work = 0
      let total_work_deducted = 0
      let total_material = 0
      let total_price = 0
      let total_price_deducted = 0

      // Task items visible in controls
      for (var i = 0; i < prev_state.task_item_selects.length; i++) {
        let task_item_select = prev_state.task_item_selects[i]
        let selected_task_item_id = task_item_select.selected_child_task_item_id

        if (task_item_select.visible) {

          for (var k = 0; k < task_item_select.child_task_items.length; k++) {
            let child_task_item = task_item_select.child_task_items[k]

            if (child_task_item.task_item_id == selected_task_item_id) {

              if (child_task_item.leaf) {
                let driver_id = child_task_item.driver_id
                let driver_value = 0

                if (driver_id > 0) {
                  for (var j = 0; j < prev_state.driver_values.length; j++) {
                    let driver = prev_state.driver_values[j]

                    if (driver.driver_id == driver_id) {
                      driver_value = driver.driver_value == '' ? 0 : parseInt(driver.driver_value)
                    }
                  }
                }

                let hours = child_task_item.hours_base + driver_value * child_task_item.hours_per_driver
                let material = child_task_item.material_base + driver_value * child_task_item.material_per_driver
                let work = hours * this.props.shop.cart.price_per_hour
                let work_deducted = Math.round(work * 0.7)

                total_hours += hours
                total_work += work
                total_work_deducted += work_deducted
                total_material += material
                total_price += work + material
                total_price_deducted += work_deducted + material
              }
            }
          }
        }
      }

      // Leaves among root task items
      for (var i = 0; i < prev_state.root_task_item_ids.length; i++) {
        let task_item_id = prev_state.root_task_item_ids[i]

        for (var k = 0; k < this.props.shop.task_items.length; k++) {
          let task_item = this.props.shop.task_items[k]

          if (task_item.task_item_id == task_item_id && task_item.leaf) {

            let driver_id = task_item.driver_id
            let driver_value = 0

            if (driver_id > 0) {
              for (var j = 0; j < prev_state.driver_values.length; j++) {
                let driver = prev_state.driver_values[j]

                if (driver.driver_id == driver_id) {
                  driver_value = driver.driver_value == '' ? 0 : parseInt(driver.driver_value)
                }
              }
            }

            let hours = task_item.hours_base + driver_value * task_item.hours_per_driver
            let material = task_item.material_base + driver_value * task_item.material_per_driver
            let work = hours * this.props.shop.cart.price_per_hour
            let work_deducted = Math.round(work * 0.7)

            total_hours += hours
            total_work += work
            total_work_deducted += work_deducted
            total_material += material
            total_price += work + material
            total_price_deducted += work_deducted + material
          }
        }
      }

      return {
        ...prev_state,
        total_price_deducted,
      }
    })
  }



  addToCart() {

    let task_item_ids = this.state.root_task_item_ids

    for (var i = 0; i < task_item_ids.length; i++) {
      this.selectTaskItemRecursive(task_item_ids[i])
    }

    for (var i = 0; i < this.state.driver_values.length; i++) {
      let driver_value = this.state.driver_values[i]
      if (driver_value.visible) {
        this.props.setSearchDriverValue(driver_value.driver_id, driver_value.driver_value)
      }
    }

    for (var i = 0; i < this.state.task_item_question_inputs.length; i++) {
      let task_item_question_input = this.state.task_item_question_inputs[i]
      if (task_item_question_input.visible) {
        this.props.setSearchTaskAnswer(task_item_question_input.task_item_question_id, task_item_question_input.answer_text)
      }
    }

    this.props.setSearchTaskText(this.state.task_text)
    this.props.addSearchToCart()

    this.props.history.push(this.getRoot() + '/shop/task_added')
  }

  selectTaskItemRecursive(task_item_id) {

    for (var i = 0; i < this.state.task_item_selects.length; i++) {
      let task_item_select = this.state.task_item_selects[i]

      if (task_item_select.task_item_id == task_item_id) {
        let new_task_item_id = task_item_select.selected_child_task_item_id

        this.props.selectSearchTaskItem(new_task_item_id)
        this.selectTaskItemRecursive(new_task_item_id)
      }
    }
  }

  renderTaskItemSelect(task_item_select) {
    return (
      <div style={{ width: '100%', paddingHorizontal: 20, paddingVertical: 10, boxSizing: 'border-box' }} key={task_item_select.task_item_id}>
        {/*<div style={{paddingHorizontal: 10, marginBottom: 5}}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>{task_item_select.question}</div>
        </div>*/}
        <div>

          <div style={{ padding: 10, borderRadius: 10, borderWidth: 1, borderColor: '#404040', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'border-box', display: 'flex' }}>
            <div style={{ width: '100%', position: 'relative' }}>
              {/*<select style={{width: '100%', border: 'none', fontSize: '16px'}} onChange={(event) => {this.selectTaskItem(task_item_select.task_item_id, event.target.value)}}>
                {task_item_select.child_task_items.map(child_task_item => (
                  <option value={child_task_item.task_item_id} selected={child_task_item.task_item_id == task_item_select.selected_child_task_item_id}>
                    {child_task_item.answer}
                  </option>
                ))}
              </select>*/}
              <div style={{ cursor: 'pointer', width: '100%', padding: '10px', border: '1px solid black', borderRadius: '4px', fontSize: '16px', boxSizing: 'border-box' }}>
                <div style={{ width: '100%' }} onClick={(event) => this.openTaskItem(task_item_select.task_item_id)}>
                  {task_item_select.child_task_items.map(child_task_item => (
                    child_task_item.task_item_id == task_item_select.selected_child_task_item_id ? child_task_item.answer : ''
                  ))}
                  <img src={require('../../../assets/icons/triangle_drop_down_icon.ico')} style={{ width: 16, borderRadius: 5, float: 'right', height: 'auto' }}></img>
                </div>
                {task_item_select.modal_open ? (
                  <div style={{ position: 'absolute', top: 0, left: 0, boxSizing: 'border-box', backgroundColor: 'white', width: '100%', padding: '10px', border: '1px solid black', borderRadius: '4px', zIndex: 999, boxShadow: 'rgb(128 128 128) 5px 5px 15px -5px', WebkitBoxShadow: 'rgb(128 128 128) 5px 5px 15px -5px' }}>
                    {task_item_select.child_task_items.map((child_task_item, index) => (
                      <div onClick={() => this.selectTaskItem(task_item_select.task_item_id, child_task_item.task_item_id)} style={{ cursor: 'pointer', padding: '10px 0', borderTop: (index == 0 ? 'none' : '1px solid #d8d8d8') }}>
                        {child_task_item.answer}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDriverValue(driver_value) {
    return (
      <div style={{ width: '100%', padding: '20px 10px', boxSizing: 'border-box' }} key={driver_value.driver_id}>
        <div style={{ width: '100%', paddingHorizontal: 10, borderRadius: 10, borderWidth: 1, borderColor: '#404040', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <div style={{ paddingHorizontal: 0, marginTop: 10 }}>
            <div style={{ fontSize: 14, fontWeight: 'normal', color: '#404040' }}>{driver_value.driver_question}</div>
          </div>
          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 10, paddingTop: 10 }}>
            <DefaultInput
              placeholder="0"
              value={String(driver_value.driver_value)}
              onChange={event => this.updateDriverValue(driver_value.driver_id, event.target.value)}
              //valid={this.state.controls.provider_name.valid}
              //touched={this.state.controls.provider_name.touched}
              style={{ backgroundColor: '#ffffff', textAlign: 'center', color: '#000', fontSize: '16px', fontWeight: 'normal', width: '100%', height: 'auto', padding: '10px', boxSizing: 'border-box' }}
            />
          </div>
          <div style={{ width: '100%', paddingBottom: '10px', paddingTop: '5px' }}>
            <div style={{ fontSize: 10, color: 'black', textAlign: 'center', color: '#404040', marginBottom: 2 }}>
              {driver_value.driver_type == 'length' ? 'meter' : null}
              {driver_value.driver_type == 'area' ? 'kvadratmeter' : null}
              {driver_value.driver_type == 'number' ? 'st' : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTaskItemQuestionInput(task_item_question_input) {
    return (
      <div style={{ width: '100%', padding: '20px 10px', boxSizing: 'border-box' }} key={task_item_question_input.task_item_question_id}>
        <div>
          <div style={{ paddingHorizontal: 0, borderRadius: 10, borderWidth: 1, borderColor: '#404040', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{ paddingHorizontal: 10, marginTop: 10 }}>
              <div style={{ fontSize: 14, fontWeight: 'normal', color: '#404040' }}>{task_item_question_input.question_instruction}</div>
            </div>

            <DefaultInput
              placeholder="Skriv här..."
              value={String(task_item_question_input.answer_text)}
              onChange={event => this.updateTaskItemQuestionInput(task_item_question_input.task_item_question_id, event.target.value)}
              //valid={this.state.controls.provider_name.valid}
              //touched={this.state.controls.provider_name.touched}
              rowSpan={10}
              style={{ backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', paddingHorizontal: 10 }}
            />
          </div>
        </div>
      </div>
    )
  }

  renderTaskTextInput() {
    return (
      <div style={{ width: '100%', padding: '20px 10px', boxSizing: 'border-box' }}>
        <div>
          <div style={{ paddingHorizontal: 0, borderRadius: 10, borderWidth: 1, borderColor: '#404040', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{ paddingHorizontal: 10, marginBottom: 0, marginTop: 10 }}>
              <div style={{ fontSize: 14, fontWeight: 'normal', color: '#404040' }}>{this.state.task_item_selects.length == 0 && this.state.driver_values.length == 0 ? 'Beskriv vad du vill ha hjälp med' : 'Övriga kommentarer'}</div>
            </div>

            <DefaultInput
              placeholder="Skriv här..."
              value={String(this.state.task_text)}
              onChange={event => this.updateTaskText(event.target.value)}
              //valid={this.state.controls.provider_name.valid}
              //touched={this.state.controls.provider_name.touched}
              rowSpan={10}
              style={{ backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', marginTop: '10px' }}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {

    let task = this.state.task
    if (!task) {
      return null
    }

    let visible_task_item_selects = []

    for (var i = 0; i < this.state.task_item_selects.length; i++) {
      let task_item_select = this.state.task_item_selects[i]

      if (task_item_select.visible) {
        visible_task_item_selects.push(task_item_select)
      }
    }

    let visible_driver_values = []
    let all_drivers_nonzero = true
    let all_drivers_zero = true
    
    for (var i = 0; i < this.state.driver_values.length; i++) {
      let driver_value = this.state.driver_values[i]

      if (driver_value.visible) {
        visible_driver_values.push(driver_value)
        if (driver_value.driver_value == 0) {
          all_drivers_nonzero = false
        }
        else {
          all_drivers_zero = false
        }
      }
    }

    if (visible_driver_values.length == 0) {
      all_drivers_zero = false
    }

    let visible_task_item_question_inputs = []

    for (var i = 0; i < this.state.task_item_question_inputs.length; i++) {
      let task_item_question_input = this.state.task_item_question_inputs[i]

      if (task_item_question_input.visible) {
        visible_task_item_question_inputs.push(task_item_question_input)
      }
    }

    let show_pricing = (!this.props.ui.is_branded || this.props.ui.brand_provider_account.provider_show_price_estimate)


    return (
      <div className="byggbuddy-screen" style={{ display: 'flex', flexDirection: 'horizontal' }} onClick={(event) => this.closeTaskItems()} >
        <div className="byggbuddy-content-height byggbuddy-content-scroll byggbuddy-content-scroll-padding byggbuddy-content-grow">

          <div style={{ padding: '15px' }}>
            <a style={{ textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center' }} href="javascript://" onClick={this.goBack}><span className="material-icons">arrow_back_ios</span> Tillbaka</a>
          </div>

          <div style={{ backgroundColor: 'white', maxWidth: '600px', margin: '0 auto' }}>

            <div style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start', padding: 20, marginBottom: 10, backgroundColor: '#00a1b1', alignItems: 'center', width: '100%', boxSizing: 'border-box' }}>
              <div style={{ width: '100%', alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
                <img src={task.image_url} style={{ aspectRatio: 1.33, borderRadius: 7, marginBottom: 10, width: '30%', maxWidth: '150px' }} />
              </div>
              <div style={{ width: '100%', alignItems: 'center' }}>
                <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', color: 'white' }}>{task.task_name}</div>
              </div>
            </div>

            {visible_task_item_selects.length > 0 ? (
              <div style={{ paddingVertical: 0 }}>
                {visible_task_item_selects.map(task_item_select => this.renderTaskItemSelect(task_item_select))}
              </div>
            ) : null}

            {visible_driver_values.length > 0 ? (
              <div style={{ paddingVertical: 0 }}>
                {visible_driver_values.map(driver_value => this.renderDriverValue(driver_value))}
              </div>
            ) : null}

            {visible_task_item_question_inputs.length > 0 ? (
              <div style={{ paddingVertical: 0 }}>
                {visible_task_item_question_inputs.map(task_item_question_input => this.renderTaskItemQuestionInput(task_item_question_input))}
              </div>
            ) : null}

            <div style={{ paddingVertical: 0 }}>
              {this.renderTaskTextInput()}
            </div>

          </div>

          <div style={{
            flex: 1, flexDirection: 'column', alignItems: 'center', width: '100%', boxSizing: 'border-box',
            borderColor: '#808080',
            bottom: 0,
            backgroundColor: 'white',
            padding: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: -10,
            },
            shadowOpacity: 0.5,
            shadowRadius: 15,
          }}>

            {show_pricing && !all_drivers_zero ? (
              <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {this.state.total_price_deducted == 0 ? (
                  <div style={{ flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                    <div style={{ fontSize: 11, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>Denna produkt kan inte prisuppskattas här.{'\n'}Lägg produkten i varukorgen och checka ut så får du ett pris när offert lämnas.</div>
                  </div>
                ) : (
                  <div style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center' }}>
                      {utility.niceNumberFormat(Math.ceil(this.state.total_price_deducted / 1000) * 1000)} <div style={{ fontSize: 16, marginLeft: '5px', marginBottom: '5px' }}>kr</div>
                    </div>
                    <div style={{ fontSize: 11, textAlign: 'center', color: '#808080' }}>Prisuppskattning inkl moms efter ROT-avdrag</div>
                  </div>
                )}
              </div>
            ) : null}

            <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <DefaultButton
                style={{ width: '100%', maxWidth: '300px', backgroundColor: '#167f40', margin: '15px 0' }}
                onClick={() => this.addToCart()}
                disabled={
                  all_drivers_zero
                }
              >
                Nästa steg
              </DefaultButton>
            </div>
          </div>
        </div>
        <div className="byggbuddy-side-cart" style={{ width: '360px', flexShrink: 0, flexGrow: 0 }}>
          <Cart {...this.props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    selectSearchTask: (task_id) => dispatch(selectSearchTask(task_id)),
    selectSearchTaskItem: (task_item_id) => dispatch(selectSearchTaskItem(task_item_id)),
    setSearchDriverValue: (driver_id, driver_value) => dispatch(setSearchDriverValue(driver_id, driver_value)),
    setSearchTaskText: (task_text) => dispatch(setSearchTaskText(task_text)),
    addSearchToCart: () => dispatch(addSearchToCart()),
    setSearchTaskAnswer: (task_item_question_id, answer_text) => dispatch(setSearchTaskAnswer(task_item_question_id, answer_text)),
    onResetSearch: () => dispatch(resetSearch()),
    submitTaskMissingText: (task_missing_text) => dispatch(submitTaskMissingText(task_missing_text)),
    pushDebugMessage: (message) => dispatch(pushDebugMessage(message)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTaskToCartScreen)
