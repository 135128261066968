import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { authLogout } from './../../store/actions/auth'
import { setMenuOpen } from './../../store/actions/ui'


class Menu extends React.Component {

  state = {
  }

  getRoot = () => {

    if (this.props.ui.is_branded) {
      if (this.props.ui.is_custom_domain) {
        return ''
      }
      else {
        if (this.props.ui.brand_provider_account.provider_identifier) {
          return '/' + this.props.ui.brand_provider_account.provider_identifier
        }
        else {
          return '/' + this.props.ui.brand_provider_account.provider_account_id
        }
      }
    }
    else {
      return '/byggbuddy'
    }
  }

  goTo = (path) => {
    this.props.setMenuOpen(false)
    this.props.history.push(this.getRoot() + path)
  }

  render() {

    let is_logged_in = this.props.auth.scope == 'customer' || this.props.auth.scope == 'provider'
    let has_menu = is_logged_in
    
    return (
      <div className="bb-menu" style={{padding: '15px', boxSizing: 'border-box'}}>
        {this.props.auth.scope == 'provider' ? (
          <>
            {/*<div onClick={() => this.goTo('/provider')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">dashboard</span>
              </div>
              <div>
                Översikt
              </div>
            </div>*/}
            <div onClick={() => this.goTo('/shop/find_task/0')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">create</span>
              </div>
              <div>
                Ny förfrågning
              </div>
            </div>
            {/*<div onClick={() => this.goTo('/provider/auctions/drafts')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">content_paste</span>
              </div>
              <div>
                Utkast
              </div>
            </div>*/}
            <div onClick={() => this.goTo('/provider/auctions')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">new_releases</span>
              </div>
              <div>
                Pågående förfrågningar
              </div>
            </div>
            <div onClick={() => this.goTo('/provider/auctions/aborted')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">not_interested</span>
              </div>
              <div>
                Avbrutna förfrågningar
              </div>
            </div>
            <div onClick={() => this.goTo('/provider/jobs')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">play_circle</span>
              </div>
              <div>
                Vunna uppdrag
              </div>
            </div>
            {/*<div onClick={() => this.goTo('/provider/jobs/confirmed')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">play_circle</span>
              </div>
              <div>
                Pågående uppdrag
              </div>
            </div>
            <div onClick={() => this.goTo('/provider/jobs/completed')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">flag</span>
              </div>
              <div>
                Avslutade uppdrag
              </div>
            </div>*/}
          </>
        ) : null}

        {this.props.auth.scope == 'customer' ? (
          <>
            <div onClick={() => this.goTo('/customer')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">dashboard</span>
              </div>
              <div>
                Översikt
              </div>
            </div>
            <div onClick={() => this.goTo('/shop/find_task/0')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">create</span>
              </div>
              <div>
                Ny förfrågning
              </div>
            </div>
            <div onClick={() => this.goTo('/customer/auctions/drafts')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">content_paste</span>
              </div>
              <div>
                Utkast
              </div>
            </div>
            <div onClick={() => this.goTo('/customer/auctions')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">new_releases</span>
              </div>
              <div>
                Pågående förfrågningar
              </div>
            </div>
            <div onClick={() => this.goTo('/customer/auctions')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">not_interested</span>
              </div>
              <div>
                Avbrutna förfrågningar
              </div>
            </div>
            <div onClick={() => this.goTo('/customer/jobs/confirmed')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">play_circle</span>
              </div>
              <div>
                Pågående uppdrag
              </div>
            </div>
            <div onClick={() => this.goTo('/customer/jobs/completed')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">flag</span>
              </div>
              <div>
                Avslutade projekt
              </div>
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     screen: state.ui.screen,
     auth: state.auth,
     ui: state.ui,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
     setMenuOpen: (menu_open) => dispatch(setMenuOpen(menu_open)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)