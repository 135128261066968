export const colors = {
    primary: '#009BD7',
    secondary: '#016CA0',
    disabled: '#aaa'
}


export const fonts = {
    sm: 12,
    md: 18,
    lg: 28,
    primary: 'Museo Sans'
}

const styles = {
    colors,
    fonts,
}

export default styles