import { PUSH_DEBUG_MESSAGE } from '../actions/actionTypes';
import _ from 'lodash'

const initialState = {
  debugMessages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_DEBUG_MESSAGE:
      //alert(action.message)
      let debugMessages = state.debugMessages.slice()
      debugMessages.push({
        timestamp: action.timestamp,
        message: action.message,
      })
      debugMessages = _.sortBy(debugMessages, 'timestamp');
      debugMessages.reverse();
      return {
        ...state,
        debugMessages: debugMessages
      };
    default:
      return state;
  }
};

export default reducer;