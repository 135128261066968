import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'
import { declineAuction } from '../../../store/actions/auctions'

import _ from 'lodash'
import { Analytics } from '../../../services/analytics.service'

import ProviderMessages from './../Messages/ProviderMessages'


class ProviderAuctionScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  content_padding = '0'
  content_max_width = 'none'

  state = {
    job_image_index: 0,
    job_image_popup: false,
    auction_is_being_declined: false,
  }

  setJobImage = (job_image_index) => {

    this.setState(prev_state => {

      return {
        ...prev_state,
        job_image_index,
      }
    })
  }

  popupJobImage = (job_image_popup) => {

    this.setState(prev_state => {

      return {
        ...prev_state,
        job_image_popup,
      }
    })
  }

  declineAuction = () => {

    if (window.confirm('Vill du tacka nej till förfrågan?')) {

      this.setState(prev_state => {
        return {
          ...prev_state,
          auction_is_being_declined: true,
        }
      })
      this.props.declineAuction(this.props.auction_id, this.props.auth.provider_account.provider_account_id)
      .then(() => {
        this.setState(prev_state => {
          return {
            ...prev_state,
            auction_is_being_declined: false,
          }
        })
      })
    }
  }

  makeBid = () => {

    this.goTo('/provider/auctions/' + this.props.auction_id + '/bids')
  }

  viewBid = (bid_id) => {

    this.goTo('/provider/auctions/' + this.props.auction_id + '/bids/' + bid_id)
  }

  renderBrandedContent(provider_account) {

    let auction = null

    for (var i = 0; i < this.props.auctions.length; i++) {
      let a = this.props.auctions[i]
      if (a.auction_id == this.props.auction_id) {
        auction = a
      }
    }

    if (!auction) {
      return null
    }

    // Bids
    let did_bid = false
    let bid_not_sent = false

    for (var i = 0; i < auction.bids.length; i++) {
      let bid = auction.bids[i]

      if (bid.bid_status == 'unsigned' || bid.bid_status == 'open') {
        did_bid = true
        if (bid.bid_status == 'unsigned') {
          bid_not_sent = true
        }
      }
    }

    let auction_declined = auction.provider_status == 'declined'
    let auction_aborted = auction.auction_status != 'ongoing'

    return (

      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
        
        {this.state.job_image_popup ? (
          <div onClick={() => this.popupJobImage(false)} style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', backgroundColor: 'black', border: '10px solid white', boxSizing: 'border-box'}}>
            <img src={auction.job_images[this.state.job_image_index].job_image_url} style={{width: '100%', maxHeight: 'calc(100vh - 20px)', objectFit: 'contain'}} />
          </div>
        ) : null}
        
        <div style={{display: 'flex', flexDirection: 'column', flexBasis: '400px', flexGrow: 0, flexShrink: 1, padding: '15px', backgroundColor: '#f8f8f8'}}>
          <div style={{fontSize: '24px', fontWeight: 'bold'}}>
            {auction.job_name}
          </div>
          <div style={{fontSize: '16px', fontWeight: 'bold'}}>
            {auction.geolocation_text}
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
            <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
              <span className="material-icons">person</span>
            </div>
            <div>
              <div>{auction.first_name} {auction.last_name}</div>
              <div>{auction.email}</div>
              <div>{auction.phone}</div>
            </div>
          </div>

          {auction.job_hours ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">watch_later</span>
              </div>
              <div>
                <div>{utility.niceNumberFormat(auction.job_hours)} timmar</div>
                <div>Uppskattad tidsåtgång</div>
              </div>
            </div>
          ) : null}

          {auction.job_options.length ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">home</span>
              </div>
              <div>
                {auction.job_options.map(job_option => (
                  <div key={job_option.job_option_id}>{job_option.option_name}</div>
                ))}
              </div>
            </div>
          ) : null}

          <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
            <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
              <span className="material-icons">date_range</span>
            </div>
            <div>
              <div>Start tidigast {auction.preferred_startdate}</div>
              <div>Färdigt senast {auction.preferred_enddate}</div>
            </div>
          </div>

          {auction.job_text ? (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <span className="material-icons">assignment</span>
              </div>
              <div>
                <div>{auction.job_text}</div>
              </div>
            </div>
          ) : null}

          {auction.job_tasks.map(job_task => (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}} key={job_task.job_task_id}>
              <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                <div style={{width: '24px', height: '24px', padding: '2px', boxSizing: 'border-box', backgroundColor: 'black', borderRadius: '3px'}}>
                  <img src={job_task.image_url} style={{width: '100%', objectFit: 'contain'}} />
                </div>
              </div>
              <div>
                <div>{job_task.task_name}</div>
                <div>{job_task.job_task_text}</div>
                {job_task.job_task_drivers.map(job_task_driver => (
                  <div key={job_task_driver.job_task_driver_value_id}>{job_task_driver.driver_name}: {job_task_driver.driver_value} {job_task_driver.driver_metric}</div>
                ))}
                <ul style={{paddingInlineStart: '30px'}}>
                  {job_task.job_task_items.map(job_task_item => (
                    <li key={job_task_item.job_task_item_id} style={{marginBottom: '5px'}}>{job_task_item.task_item_name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {auction_declined ? (

            <div 
              style={{alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#ffffff',
                      padding: 30,
                    }}>
              <div>
                <span style={{fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginBottom: 7, textAlign: 'center'}}>Du har tackat nej till denna förfrågan</span>
              </div>
            </div>

          ) : null}

          {auction_aborted ? (

            <div 
              style={{alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#ffffff',
                      padding: 30,
                    }}>
              <div>
                <span style={{fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginBottom: 7, textAlign: 'center'}}>Denna förfrågan avbröts av kunden</span>
              </div>
            </div>

          ) : null}

          {!auction_aborted && !auction_declined ? (

            <div>

              <div style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}>
                Offerter
              </div>
              {!did_bid ? (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <DefaultButton
                    style={{width: '100%', backgroundColor: '#167f40', margin: '15px 0'}}
                    onClick={() => this.makeBid()}
                    disabled={did_bid}
                  >
                    Skapa offert
                  </DefaultButton>
                </div>
              ) : null}
              {auction.bids.length == 0 ? (
                <div 
                  style={{alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#ffffff',
                          padding: 30,
                        }}>
                  <div>
                    <span style={{fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginBottom: 7, textAlign: 'center'}}>Det finns ännu inga offerter</span>
                  </div>
                </div>
              ) : null}
              
              {auction.bids.map((bid, index) => (
                <div onClick={() => this.viewBid(bid.bid_id)} key={bid.bid_id} style={{padding: 20, backgroundColor: '#ffffff'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <div style={{width: '20%'}}>
                      <div>
                        {bid.provider_account.provider_images.length > 0 ? (
                          <img src={bid.provider_account.provider_images[0].image_url} style={{width: 50, height: 50, borderRadius: 25, objectFit: 'cover'}} />
                        ) : (
                          <img src={require('../../../assets/icons/avatar-provider.png')} style={{width: 50, height: 50, borderRadius: 25, objectFit: 'cover'}} />
                        )}
                      </div>
                    </div>
                    <div style={{width: '50%'}}>
                      <div>
                        <span style={{fontSize: 16, fontWeight: 'bold', marginBottom: 2}}>{bid.provider_name}</span>
                      </div>
                      {bid.bid_created ? (
                        <div>
                          <span style={{fontSize: 12, color: '#808080', fontWeight: 'normal'}}>Skapad {utility.niceDateFormat(bid.bid_created)}</span>
                        </div>
                      ) : null}
                    </div>
                    <div style={{width: '30%', paddingLeft: 15, display: 'flex', flexDirection: 'column'}}>
                      <div style={{fontWeight: 'bold', fontSize: 16, textAlign: 'right', color: (bid.bid_status == 'declined' || bid.bid_status == 'revoked' ? '#808080' : '#000000')}}>{utility.niceNumberFormat(Math.round(bid.bid_price_deducted + bid.bid_commission))} kr</div>
                      {bid.bid_status == 'unsigned' ? (
                        <span style={{textAlign: 'right', fontSize: 11, color: '#808080'}}>Ej skickad</span>
                      ) : null}
                      {bid.bid_status == 'open' ? (
                        <span style={{textAlign: 'right', fontSize: 11, color: '#808080'}}>Skickad</span>
                      ) : null}
                      {bid.bid_status == 'accepted' ? (
                        <span style={{textAlign: 'right', fontSize: 11, color: '#808080'}}>Antagen</span>
                      ) : null}
                      {bid.bid_status == 'declined' ? (
                        <span style={{textAlign: 'right', fontSize: 11, color: '#808080'}}>Avböjd</span>
                      ) : null}
                      {bid.bid_status == 'revoked' ? (
                        <span style={{textAlign: 'right', fontSize: 11, color: '#808080'}}>Återkallad</span>
                      ) : null}
                    </div>
                  </div>
                  {bid.bid_status == 'unsigned' ? (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                      <DefaultButton
                        style={{width: '100%', backgroundColor: '#167f40', margin: '15px 0'}}
                        onClick={() => this.viewBid(bid.bid_id)}
                      >
                        Granska och skicka offert
                      </DefaultButton>
                    </div>
                  ) : null}
                  {bid.bid_status == 'open' ? (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                      <DefaultButton
                        style={{width: '100%', backgroundColor: '#167f40', margin: '15px 0'}}
                        onClick={() => this.viewBid(bid.bid_id)}
                      >
                        Se och återta offert
                      </DefaultButton>
                    </div>
                  ) : null}
                  
                </div>
              ))}

              {did_bid ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, backgroundColor: 'white'}}>
                  {bid_not_sent ? (
                    <span style={{textAlign: 'center', color: '#808080'}}>
                      Du har skapat en offert som inte har skickats. Klicka på offerten och välj signera och skicka. Om du vill ändra din offert måste du återkalla den och lämna en på nytt.
                    </span>
                  ) : (
                    <span style={{textAlign: 'center', color: '#808080'}}>
                      Du har lämnat offert. Invänta svar från uppdragsgivaren. Om du vill ändra din offert måste du återkalla den och lämna en på nytt. Om du vill tacka nej till förfrågan måste du först återkalla din offert.
                    </span>
                  )}
                </div>
              ) : null}

              {!did_bid ? (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <DefaultButton
                    style={{width: '100%', color: 'red', backgroundColor: 'white', border: '2px solid red', margin: '15px 0'}}
                    onClick={() => this.declineAuction()}
                    disabled={this.state.auction_is_being_declined}
                  >
                    Tacka nej till förfrågan
                  </DefaultButton>
                </div>
              ) : null}
            </div>
          ) : null}    

        </div>
        <div style={{display: 'flex', flexDirection: 'column', flexBasis: '300px', flexGrow: 1, flexShrink: 5, padding: '15px'}}>
          
          {/* Job images */}
          {auction.job_images.length ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{}} onClick={() => this.popupJobImage(true)}>
                <img src={auction.job_images[this.state.job_image_index].job_image_url} style={{width: '100%', maxHeight: '400px', objectFit: 'cover'}} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '15px'}}>
                {auction.job_images.map((job_image, index) => (
                  <div style={{width: '100px', marginRight: '15px'}} onClick={() => this.setJobImage(index)} key={job_image.job_image_id}>
                    <img src={job_image.job_image_url} style={{width: '100%'}} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* Messages */}
          {/*<div>
            <ProviderMessages job_id={auction.job_id} key={auction.job_id} />
          </div>*/}

        </div>
        
      </div>

    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  auctions: state.auctions.auctions,
})

const mapDispatchToProps = dispatch => {
  return {
    declineAuction: (auction_id, provider_account_id) => dispatch(declineAuction(auction_id, provider_account_id)),
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderAuctionScreen)
