import React, { Component } from 'react'

import Cart from './../components/Shop/Cart'
import { pushDebugMessage } from '../store/actions/debug'


class ByggbuddyScreen extends React.Component {

  // Component settings
  show_cart = false
  show_back = true
  content_max_width = '800px'
  content_padding = '20px'

  showBack = () => {
    return this.show_back
  }

  showCart = () => {
    return this.show_cart
  }
  
  getRoot = () => {

    let root = ''

    if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      root = '/' + this.props.match.params.provider
    }
    return root
  }

  goTo = (path) => {
    this.props.history.push(this.getRoot() + path)
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {

    this.beforeRender()

    if (this.props.ui.is_branded) {

      let provider_account = this.props.ui.brand_provider_account
      return this.renderBranded(provider_account)
    }
    else {

      return this.renderByggbuddy()
    }
  }

  beforeRender() {
    // To override
  }

  renderContent() {
    return (
      <div>ByggbuddyScreen</div>
    )
  }

  renderByggbuddy() {
    return (
  
      <div className="byggbuddy-screen" style={{display: 'flex', flexDirection: 'horizontal'}}>
        <div className="byggbuddy-content-height byggbuddy-content-scroll byggbuddy-content-scroll-padding byggbuddy-content-grow">

          {this.showBack() ? (
            <div style={{padding: '15px'}}>
              <a style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} href="javascript://" onClick={this.goBack}><span className="material-icons">arrow_back_ios</span> Tillbaka</a>
            </div>
          ) : null}

          <div style={{
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            maxWidth: this.content_max_width,
            margin: '0 auto',
            padding: this.content_padding,
            boxSizing: 'border-box'
          }}>

            {this.renderByggbuddyContent()}

          </div>
        </div>
        {this.showCart() ? (
          <div className="byggbuddy-side-cart" style={{width: '360px', flexShrink: 0, flexGrow: 0}}>
            <Cart {...this.props} />
          </div>
        ) : null}
      </div>
    )
  }

  renderByggbuddyContent() {
    return this.renderContent()
  }

  renderBranded(provider_account) {
    return (
  
      <div className="byggbuddy-screen" style={{display: 'flex', flexDirection: 'horizontal'}}>
        <div className="byggbuddy-content-height byggbuddy-content-scroll byggbuddy-content-scroll-padding byggbuddy-content-grow">

          {this.showBack() ? (
            <div style={{padding: '15px'}}>
              <a style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} href="javascript://" onClick={this.goBack}><span className="material-icons">arrow_back_ios</span> Tillbaka</a>
            </div>
          ) : null}

          <div style={{
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            maxWidth: this.content_max_width,
            margin: '0 auto',
            padding: this.content_padding,
            boxSizing: 'border-box'
          }}>

            {this.renderBrandedContent(provider_account)}

          </div>
        </div>
        {this.showCart() ? (
          <div className="byggbuddy-side-cart" style={{width: '360px', flexShrink: 0, flexGrow: 0}}>
            <Cart {...this.props} />
          </div>
        ) : null}
      </div>
    )
  }

  renderBrandedContent(provider_account) {
    return this.renderContent()
  }

  static mapStateToProps(state) {
    return {
      auth: state.auth,
      ui: state.ui,
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      // Nothing
    }
  }
}

export default ByggbuddyScreen
