import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import validate from '../../../utility/validation';
import moment from 'moment'
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'
import { submitBid } from '../../../store/actions/auctions'

import _ from 'lodash'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
registerLocale('sv', sv)

const maxDeduction = 80000; // Two people in the household with a max of 50 000 SEK pp - excluding VAT!


class ProviderMakeBidScreen extends ByggbuddyScreen {

  // Component settings
  show_back = true
  content_max_width = 'none'

  state = {
    bid_price_deducted: 0,
    bid_price_work_deducted: 0,
    bid_price_extra_hour_deducted: 0,
    bid_commission: 0,
    controls: {
      bid_type: {
        value: 'fixed',
        valid: false,
        validationRules: {
        },
        touched: false
      },
      bid_price_extra_hour: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_price: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_hours: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_price_work: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_price_material: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_price_car: {
        value: 0,
        valid: false,
        validationRules: {
        },
        touched: false,
        modal_open: false,
        temp_value: '',
      },
      bid_text: {
        value: '',
        valid: false,
        validationRules: {
        },
        touched: false
      },
      bid_payment_terms: {
        value: 'after',
        valid: true,
        validationRules: {
        },
        touched: false
      },
      bid_startdate: {
        value: new Date(moment().format("YYYY-MM-DD")),
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false,
        modal_open: false,
      },
      bid_enddate: {
        value: new Date(moment().format("YYYY-MM-DD")),
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false,
        modal_open: false,
      },
    },
    bid_properties: [],
    bid_is_being_created: false,
    job_image_popup: -1,
  }

  popupJobImage = (job_image_popup) => {

    this.setState(prev_state => {

      return {
        ...prev_state,
        job_image_popup,
      }
    })
  }

  updateInputState = (key, value) => {
    let valid = true

    if (key == 'bid_hours') {

      valid = true

      this.setState(prevState => {

        let bid_hours = value
        let bid_price_work = Math.round(prevState.controls.bid_price_extra_hour.value * bid_hours)
        let bid_price_work_deducted = (bid_price_work * 0.3 > maxDeduction ? Math.round(bid_price_work - maxDeduction) : Math.round(bid_price_work * 0.7))
        let bid_price = Math.round(bid_price_work + prevState.controls.bid_price_material.value)
        let bid_price_deducted = Math.round(bid_price - bid_price_work + bid_price_work_deducted)

        return {
          ...prevState,
          bid_price_deducted,
          bid_price_work_deducted,
          controls: {
            ...prevState.controls,
            bid_hours: {
              ...prevState.controls.bid_hours,
              value: bid_hours,
              valid: valid,
              touched: true
            },
            bid_price: {
              ...prevState.controls.bid_price,
              value: bid_price,
              valid: valid,
              touched: true
            },
            bid_price_work: {
              ...prevState.controls.bid_price_work,
              value: bid_price_work,
              temp_value: bid_price_work,
              valid: valid,
              touched: true
            },
          }
        }
      })
    }

    else if (key == 'bid_price_extra_hour') {

      valid = true

      this.setState(prevState => {

        let bid_price_work = Math.round(value * prevState.controls.bid_hours.value)
        let bid_price_work_deducted = (bid_price_work * 0.3 > maxDeduction ? Math.round(bid_price_work - maxDeduction) : Math.round(bid_price_work * 0.7))
        let bid_price = Math.round(bid_price_work + prevState.controls.bid_price_material.value)
        let bid_price_deducted = Math.round(bid_price - bid_price_work + bid_price_work_deducted)
        let bid_price_extra_hour = value // Math.round(bid_price_work / prevState.controls.bid_hours.value) 
        let bid_price_extra_hour_deducted = Math.round(bid_price_extra_hour * 0.70)
        
        return {
          ...prevState,
          bid_price_deducted,
          bid_price_work_deducted,
          bid_price_extra_hour_deducted,
          controls: {
            ...prevState.controls,
            bid_price_extra_hour: {
              ...prevState.controls.bid_price_extra_hour,
              value: bid_price_extra_hour,
              valid: valid,
              touched: true,
            },
            bid_price: {
              ...prevState.controls.bid_price,
              value: bid_price,
              valid: valid,
              touched: true,
            },
            bid_price_work: {
              ...prevState.controls.bid_price_work,
              value: bid_price_work,
              valid: valid,
              touched: true,
            },
          }
        }
      })
    }

    else if (key == 'bid_price_material') {

      valid = true

      this.setState(prevState => {

        let bid_price_material = value

        let bid_price = Math.round(bid_price_material + prevState.controls.bid_price_work.value + prevState.controls.bid_price_car.value)
        let bid_price_deducted = Math.round(bid_price - prevState.controls.bid_price_work.value + prevState.bid_price_work_deducted)
        
        return {
          ...prevState,
          bid_price_deducted,
          controls: {
            ...prevState.controls,
            bid_price: {
              ...prevState.controls.bid_price,
              value: bid_price,
              valid: valid,
              touched: true
            },
            bid_price_material: {
              ...prevState.controls.bid_price_material,
              value: bid_price_material,
              valid: valid,
              touched: true
            },
          }
        }
      })
    }

    else if (key == 'bid_price_car') {
      valid = true

      this.setState(prevState => {
        let bid_price_car = value
        if (bid_price_car === "") {
          bid_price_car = 0
        }
        //debugger;
        let bid_price = Math.round(bid_price_car + prevState.controls.bid_price_work.value + prevState.controls.bid_price_material.value)
        let bid_price_deducted = Math.round(bid_price - prevState.controls.bid_price_work.value + prevState.bid_price_work_deducted)
        
        return {
          ...prevState,
          bid_price_deducted,
          controls: {
            ...prevState.controls,
            bid_price: {
              ...prevState.controls.bid_price,
              value: bid_price,
              valid: valid,
              touched: true
            },
            bid_price_car: {
              ...prevState.controls.bid_price_car,
              value: bid_price_car,
              valid: valid,
              touched: true
            },
          }
        }
      })
    }

    else {

      let valid = false;
      valid = true //validate(value, this.state.controls[key].validationRules)

      this.setState(prevState => {
        return {
          ...prevState,
          controls: {
            ...prevState.controls,
            [key]: {
              ...prevState.controls[key],
              value: value,
              valid: valid,
              touched: true
            }
          }
        }
      })
    }
  }

  setBidType = (bid_type) => {

    this.setState(prevState => {

      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          bid_type: {
            ...prevState.controls.bid_type,
            value: bid_type,
            valid: true,
            touched: true
          }
        }
      }
    })
  }

  setBidPaymentTerms = (bid_payment_terms) => {

    this.setState(prevState => {

      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          bid_payment_terms: {
            ...prevState.controls.bid_payment_terms,
            value: bid_payment_terms,
            valid: true,
            touched: true
          }
        }
      }
    })
  }

  setBidProperty = (property_id, value) => {

    this.setState(prevState => {

      let bid_properties = [...prevState.bid_properties]

      for (var i = 0; i < bid_properties.length; i++) {
        let bid_property = bid_properties[i]
        if (bid_property.property_id == property_id) {
          if (bid_property.property_type == 'bool') {
            if (bid_property.bool == 1)
              bid_property.bool = 0
            else
              bid_property.bool = 1

          }
        }
      }

      return {
        ...prevState,
        bid_properties,
      }
    })
  }

  componentDidMount() {

    let auction = {}
    let auction_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.auction_id) {
      auction_id = this.props.match.params.auction_id
    }

    // Find auction to bid for
    for (var i = 0; i < this.props.auctions.length; i++) {
      let a = this.props.auctions[i]
      if (a.auction_id == auction_id) {
        auction = a
      }
    }

    let bid_hours = auction.auction_total_hours > 0 ? Math.round(auction.auction_total_hours) : 10
    let bid_price_extra_hour = (this.props.provider_account.provider_price_per_hour ? this.props.provider_account.provider_price_per_hour * 0.8 : Math.round((auction.auction_reference_price - auction.auction_total_material) * 0.8 / auction.auction_total_hours / 25) * 25)
    let bid_price_extra_hour_deducted = Math.round(bid_price_extra_hour * 0.7)
    let bid_price_work = Math.round(bid_price_extra_hour * bid_hours)
    let bid_price_work_deducted = (bid_price_work * 0.3 > maxDeduction ? Math.round(bid_price_work - maxDeduction) : Math.round(bid_price_work * 0.7))
    let bid_price_material = Math.round(auction.auction_total_material * 0.8)
    let bid_price = Math.round(bid_price_work + bid_price_material)
    let bid_price_deducted = Math.round(bid_price - bid_price_work + bid_price_work_deducted)
    let bid_commission = 0 
    
    // Retrieve the competences needed for this task
    let competence_ids = []
    for (var i = 0; i < auction.job_tasks.length; i++) {
      let job_task = auction.job_tasks[i]

      for (var j = 0; j < this.props.tasks.length; j++) {
        let task = this.props.tasks[j]

        if (task.task_id == job_task.task_id) {
          let task_competences = task.task_competences

          for (var k = 0; k < task_competences.length; k++) {
            let task_competence = task_competences[k]

            if (!competence_ids.includes(task_competence.competence_id)) {
              competence_ids.push(task_competence.competence_id)
            }
          }
        }
      }
    }

    // Find properties to answer
    let bid_properties = []
    for (var i = 0; i < this.props.properties.length; i++) {
      let property = this.props.properties[i]

      if (property.property_scope == 'bid' && (property.property_competence_id == 0 || competence_ids.includes(property.property_competence_id))) {
        let bid_property = {
          ...property,
          bid_property_id: 0,
          bid_id: 0,
          bool: 0,
          int: 0,
          string: '',
        }
        bid_properties.push(bid_property)
      }
    }

    this.setState(prevState => {
      return {
        ...prevState,
        bid_price_deducted,
        bid_price_work_deducted,
        bid_price_extra_hour_deducted,
        bid_commission,
        controls: {
          ...prevState.controls,
          bid_price_extra_hour: {
            ...prevState.controls.bid_price_extra_hour,
            value: bid_price_extra_hour,
          },
          bid_hours: {
            ...prevState.controls.bid_hours,
            value: bid_hours,
          },
          bid_price: {
            ...prevState.controls.bid_price,
            value: bid_price,
          },
          bid_price_work: {
            ...prevState.controls.bid_price_work,
            value: bid_price_work,
          },
          bid_price_material: {
            ...prevState.controls.bid_price_material,
            value: bid_price_material,
          },
        },
        bid_properties
      }
    })

    // Initiate dates with customer's preferred
    for (var i = 0; i < this.props.auctions.length; i++) {
      let auction = this.props.auctions[i]
      if (auction.auction_id == auction_id) {
        this.updateInputState('bid_startdate', new Date(moment(auction.preferred_startdate).format("YYYY-MM-DD")))
        this.updateInputState('bid_enddate', new Date(moment(auction.preferred_enddate).format("YYYY-MM-DD")))
      }
    }
  }

  getCommission = (auction_reference_price) => {

    let services = this.props.services
    let commission = 0

    for (var i = 0; i < services.length; i++) {
      let service = services[i]

      if (service.service_type == 'base') {
        if (auction_reference_price >= service.reference_price_from && auction_reference_price < service.reference_price_to) {
          commission = Math.round((service.service_price_fixed + service.service_price_percentage * auction_reference_price) / 25) * 25
        }
      }
    }
    return commission
  }

  submitBid = () => {

    let auction_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.auction_id) {
      auction_id = this.props.match.params.auction_id
    }

    let bid = {
      bid_id: 0,
      bid_type: this.state.controls.bid_type.value,
      bid_price: Math.round(this.state.controls.bid_price.value * 1.25),
      bid_price_deducted: Math.round(this.state.bid_price_deducted * 1.25),
      bid_price_work: Math.round(this.state.controls.bid_price_work.value * 1.25),
      bid_price_work_deducted: Math.round(this.state.bid_price_work_deducted * 1.25),
      bid_price_material: Math.round(this.state.controls.bid_price_material.value * 1.25),
      bid_price_extra_hour: Math.round(this.state.controls.bid_price_extra_hour.value * 1.25),
      bid_price_extra_hour_deducted: Math.round(this.state.bid_price_extra_hour_deducted * 1.25),
      bid_price_car: Math.round(this.state.controls.bid_price_car.value * 1.25),
      bid_commission: this.state.bid_commission,
      bid_hours: this.state.controls.bid_hours.value,
      bid_startdate: moment(this.state.controls.bid_startdate.value).format("YYYY-MM-DD"),
      bid_enddate: moment(this.state.controls.bid_enddate.value).format("YYYY-MM-DD"),
      bid_text: this.state.controls.bid_text.value,
      bid_payment_terms: this.state.controls.bid_payment_terms.value,
      bid_status: 'unsigned',
      auction_id: auction_id,
      provider_account_id: this.props.user.provider_account_id,
      provider_user_id: this.props.user.provider_user_id,
      bid_properties: this.state.bid_properties,
    }

    //console.warn('BID', bid)

    this.setState(prev_state => {
      return {
        ...this.prev_state,
        bid_is_being_created: true,
      }
    })
    this.props.submitBid(bid)
      .then((saved_bid) => {
        console.warn('BID', saved_bid)
        this.setState(prev_state => {
          return {
            ...this.prev_state,
            bid_is_being_created: false,
          }
        })
        this.goTo('/provider/auctions/' + auction_id)
      })

    /*Alert.alert(
      'Skapa anbud',
      'Vill du skapa anbudet? Ett avtal kommer att tas fram som du får signera på nästa skärm. Därefter skickas anbudet till uppdragsgivaren',
      [
        {text: 'Skapa anbud', onClick: () => {
          this.submit_modal.show()
          this.props.submitBid(bid)
          .then((saved_bid) => {
            console.warn('BID', saved_bid)
            this.submit_modal.hide()
            .then(() => {
              //Alert.alert('Det gick bra!', 'Anbudet lämnades', [{text: 'Stäng', onClick: () => Navigation.pop(this.props.componentId)}])
              Navigation.push(this.props.componentId, {
                component: {
                  name: 'byggbuddy.ProviderSignBidScreen',
                  passProps: {
                    bid_id: saved_bid.bid_id,
                  }
                }
              })
            })
          })
          .catch(() => {
            this.submit_modal.hide()
            .then(() => {
              Alert.alert('Fel', 'Åtgärden misslyckades', [{text: 'Stäng'}])
            })
          })
        }},
        {text: 'Avbryt'},
      ]
    )*/
  }

  updateDate(date_control, date) {
    this.setState(prev_state => {
      return {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [date_control]: {
            ...prev_state.controls[date_control],
            value: new Date(moment(date).format("YYYY-MM-DD")),
            touched: true,
          },
        },
      }
    })
  }


  renderDatePicker(date_control, heading, min_date) {
    return (
      <div style={{ width: '100%' }}>
        <div>
          <div style={{ padding: '10px', borderStyle: 'solid', borderRadius: 4, borderWidth: 1, borderColor: '#808080', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <div style={{ fontSize: 16, fontWeight: 'normal', color: 'black' }}>{heading}</div>
            </div>
            <div style={{ width: '50%', alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
              <DatePicker popperClassName="byggbuddy-date-picker-popper" className="byggbuddy-date-picker" dateFormat="yyyy-MM-dd" locale="sv" selected={this.state.controls[date_control].value} onChange={date => this.updateDate(date_control, date)} style={{ border: 'none', textAlign: 'right', fontSize: '16px' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNumberInput(control, heading, unit) {
    return (
      <div>
        <div style={{ width: '100%', paddingHorizontal: 3 }}>
          <div style={{ fontSize: 26, fontWeight: 'bold', color: '#00a58a', textAlign: 'right', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

            <div style={{ paddingHorizontal: 10, borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (this.state.controls[control].value ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <DefaultInput
                placeholder={heading}
                value={String(this.state.controls[control].value)}
                onChange={event => this.updateInputState(control, (event.target.value === "" ? "" : parseFloat(event.target.value)))}
                style={{ fontSize: 26, fontWeight: 'bold', color: '#00a58a', textAlign: 'right', backgroundColor: '#ffffff', width: '100%', border: 'none' }}
              />
            </div>

            <div style={{ width: '50px', fontSize: 14 }}>{unit}</div>
          </div>
        </div>
      </div>

    )
  }

  renderBrandedContent(provider_account) {

    if (this.props.scope !== 'provider') {
      return null
    }

    let auction = {}
    let auction_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.auction_id) {
      auction_id = this.props.match.params.auction_id
    }

    // Find auction to bid for
    for (var i = 0; i < this.props.auctions.length; i++) {
      let a = this.props.auctions[i]
      if (a.auction_id == auction_id) {
        auction = a
      }
    }

    //console.warn(this.state)

    let earliest_startdate = moment().add(1, 'days').format("YYYY-MM-DD")

    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>

        {this.state.job_image_popup > -1 ? (
          <div onClick={() => this.popupJobImage(-1)} style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', backgroundColor: 'black', border: '10px solid white', boxSizing: 'border-box' }}>
            <img src={auction.job_images[this.state.job_image_popup].job_image_url} style={{ width: '100%', maxHeight: 'calc(100vh - 20px)', objectFit: 'contain' }} />
          </div>
        ) : null}

        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '400px', flexGrow: 0, flexShrink: 1, padding: '15px', backgroundColor: '#f8f8f8' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {auction.job_name}
          </div>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {auction.geolocation_text}
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
            <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
              <span className="material-icons">person</span>
            </div>
            <div>
              <div>{auction.first_name} {auction.last_name}</div>
              <div>{auction.email}</div>
              <div>{auction.phone}</div>
            </div>
          </div>

          {auction.job_hours ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">watch_later</span>
              </div>
              <div>
                <div>{utility.niceNumberFormat(auction.job_hours)} timmar</div>
                <div>Uppskattad tidsåtgång</div>
              </div>
            </div>
          ) : null}

          {auction.job_options.length ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">home</span>
              </div>
              <div>
                {auction.job_options.map(job_option => (
                  <div key={job_option.job_option_id}>{job_option.option_name}</div>
                ))}
              </div>
            </div>
          ) : null}

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
            <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
              <span className="material-icons">date_range</span>
            </div>
            <div>
              <div>Start tidigast {auction.preferred_startdate}</div>
              <div>Färdigt senast {auction.preferred_enddate}</div>
            </div>
          </div>

          {auction.job_text ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <span className="material-icons">assignment</span>
              </div>
              <div>
                <div>{auction.job_text}</div>
              </div>
            </div>
          ) : null}

          {auction.job_tasks.map(job_task => (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }} key={job_task.job_task_id}>
              <div className="byggbuddy-icon" style={{ alignItems: 'center', marginRight: '15px', marginTop: '4px' }}>
                <div style={{ width: '24px', height: '24px', padding: '2px', boxSizing: 'border-box', backgroundColor: 'black', borderRadius: '3px' }}>
                  <img src={job_task.image_url} style={{ width: '100%', objectFit: 'contain' }} />
                </div>
              </div>
              <div>
                <div>{job_task.task_name}</div>
                <div>{job_task.job_task_text}</div>
                {job_task.job_task_drivers.map(job_task_driver => (
                  <div key={job_task_driver.job_task_driver_value_id}>{job_task_driver.driver_name}: {job_task_driver.driver_value} {job_task_driver.driver_metric}</div>
                ))}
                <ul style={{ paddingInlineStart: '30px' }}>
                  {job_task.job_task_items.map(job_task_item => (
                    <li key={job_task_item.job_task_item_id} style={{ marginBottom: '5px' }}>{job_task_item.task_item_name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* Job images */}
          {auction.job_images.length ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '15px' }}>
                {auction.job_images.map((job_image, index) => (
                  <div style={{ width: '100px', marginRight: '15px' }} onClick={() => this.popupJobImage(index)} key={job_image.job_image_id}>
                    <img src={job_image.job_image_url} style={{ width: '100%' }} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '300px', flexGrow: 1, padding: '0 15px' }}>

          <div style={{ padding: 0, margin: '0 auto', maxWidth: '800px' }}>
            <div
              style={{
                alignItems: 'flex-start',
                justifyContent: 'center',
                backgroundColor: '#a0a0a0',
                padding: 20,
                marginVertical: 0
              }}
            >
              <div style={{ width: '100%' }}>
                <div style={{ textAlign: 'left', color: 'white' }}>
                  Nedan specificerar du ditt anbud. Notera att anbudet ska täcka samtliga kostnader för genomförande av uppdraget, exempelvis transport- och parkeringskostnader.
                  Anbudet är giltigt i 30 dagar och blir bindande om kunden väljer det.
                </div>
              </div>
            </div>

            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#026ca0', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-bid-type.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Välj anbudstyp</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här väljer du om du vill lämna en fast eller rörligt offert</div>
              </div>
            </div>

            <div onClick={() => this.setBidType('fixed')} style={{ width: '100%', boxSizing: 'border-box', padding: 20, backgroundColor: (this.state.controls.bid_type.value == 'fixed' ? '#cdeefe' : '#ffffff') }}>
              <div>
                <div style={{ fontSize: 24, textAlign: 'left', color: (this.state.controls.bid_type.value == 'fixed' ? 'black' : 'black'), fontWeight: '600' }}>Fast pris</div>
              </div>
              <div>
                <div style={{ fontSize: 12, textAlign: 'left', color: (this.state.controls.bid_type.value == 'fixed' ? 'black' : '#808080') }}>Uppdraget utförs med fast pris. Vid merkostnader pga ändrade förutsättningar får slutligt pris högst överstiga avtalet pris med 15%</div>
              </div>
            </div>

            <div onClick={() => this.setBidType('variable')} style={{ width: '100%', boxSizing: 'border-box', padding: 20, backgroundColor: (this.state.controls.bid_type.value == 'variable' ? '#cdeefe' : '#ffffff') }}>
              <div>
                <div style={{ fontSize: 24, textAlign: 'left', color: (this.state.controls.bid_type.value == 'variable' ? 'black' : 'black'), fontWeight: '600' }}>Rörligt pris</div>
              </div>
              <div>
                <div style={{ fontSize: 12, textAlign: 'left', color: (this.state.controls.bid_type.value == 'variable' ? 'black' : '#808080') }}>Uppdraget utförs utifrån ett överenskommet timpris. Angivet totalpris är endast en uppskattning.</div>
              </div>
            </div>

            {/* Price */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#00a58a', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-sliders.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Specificera ditt anbudspris</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här specificerar du offertpriser</div>
              </div>
            </div>

            <div
              style={{
                alignItems: 'flex-start',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                padding: 20,
              }}
            >

              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <div style={{ marginTop: 5, width: '65%' }}>
                    <div style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Timpris (kr exkl. moms)</div>
                    <div style={{ textAlign: 'left', fontSize: 12, fontWeight: 'normal', color: '#808080' }}>Ange det timpris du vill använda i anbudet</div>
                  </div>
                  <div style={{ marginTop: 5, width: '35%' }}>
                    {this.renderNumberInput('bid_price_extra_hour', 'Ange det timpris du vill använda i anbudet (exkl. moms)', 'kr')}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: (this.state.controls.bid_type.value == 'fixed' ? 'white' : '#e0e0e0'), padding: 20 }}>
              {this.state.controls.bid_type.value == 'variable' ? (
                <div style={{ width: '100%', paddingVertical: 0, /*borderBottomWidth: 1, borderBottomColor: '#00a58a'*/ }}>
                  {/*<div style={{fontWeight: 'bold', textAlign: 'left', fontSize: 16, fontStyle: 'italic'}}>Uppskattad arbetstid och materialkostnad</div>*/}
                  <div style={{ fontWeight: 'normal', textAlign: 'left', fontSize: 14, fontStyle: 'italic', color: '#808080' }}>Här anges uppskattad arbetstid och materialkostnad. Detta blir inte bindande i ditt anbud när du lämnar ett rörligt pris.</div>
                </div>
              ) : null}
              <div style={{ width: '100%', marginTop: 20 }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <div style={{ marginTop: 5, width: '65%' }}>
                    <div style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Arbetstid (timmar)</div>
                    <div style={{ textAlign: 'left', fontSize: 12, fontWeight: 'normal', color: '#808080' }}>Ange det antal timmar som du vill inkludera i ditt anbud</div>
                  </div>
                  <div style={{ marginTop: 5, width: '35%' }}>
                    {this.renderNumberInput('bid_hours', 'Ange det antal timmar som du vill inkludera i ditt anbud', 'tim')}
                  </div>
                </div>

                <div style={{ width: '100%', marginTop: 20 }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <div style={{ marginTop: 5, width: '65%' }}>
                      <div style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Material (exkl. moms)</div>
                      <div style={{ textAlign: 'left', fontSize: 12, fontWeight: 'normal', color: '#808080' }}>Ange total materialkostnad som du vill inkludera i ditt anbud</div>
                    </div>
                    <div style={{ marginTop: 5, width: '35%' }}>
                      {this.renderNumberInput('bid_price_material', 'Ange total materialkostnad som du vill inkludera i ditt anbud (exkl. moms)', 'kr')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                alignItems: 'flex-start',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                padding: 20,
              }}
            >
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <div style={{ marginTop: 5, width: '65%' }}>
                    <div style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Servicebil (kr exkl. moms)</div>
                    <div style={{ textAlign: 'left', fontSize: 12, fontWeight: 'normal', color: '#808080' }}>Ange totalkostnad för servicebil Inklusive parkering, eventuella vägtullar etc.</div>
                  </div>
                  <div style={{ marginTop: 5, width: '35%' }}>
                    {this.renderNumberInput('bid_price_car', 'Ange det timpris du vill använda i anbudet (exkl. moms)', 'kr')}
                  </div>
                </div>
              </div>
            </div>



            <div style={{ width: '100%', backgroundColor: 'white', paddingTop: 30, paddingBottom: 10 }}>
              <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 30, textAlign: 'center', textTransform: 'uppercase', color: '#00a58a' }}>Ditt anbudspris</div>
              <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 16, textAlign: 'center', color: '#808080' }}>Detta är vad kunden kommer att se</div>
            </div>

            <div style={{ backgroundColor: '#B9F3E9', padding: 10 }}>
              <div style={{ marginTop: 5 }}>
                {this.state.controls.bid_type.value == 'fixed' ? (
                  <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                    Fast anbud (inkl moms efter ROT-avdrag)
                  </div>
                ) : (
                  <div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                      Rörligt anbud om <div style={{ fontWeight: 'bold' }}>{this.state.bid_price_extra_hour_deducted * 1.25}</div> kr / timme
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: 'black' }}>
                      (inkl moms efter ROT-avdrag)
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a', marginTop: 15 }}>
                      Uppskattad totalkostnad
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a' }}>
                      (inkl moms efter ROT-avdrag)
                    </div>
                  </div>
                )}
                <div style={{ fontSize: 50, fontWeight: 'bold', textAlign: 'center', color: '#00a58a' }}>
                  {utility.niceNumberFormat(Math.round(this.state.bid_price_deducted * 1.25 + this.state.bid_commission))} <span style={{ fontSize: 16 }}>kr</span>
                </div>
                {/*<div style={{fontSize: 16, fontWeight: 'normal', textAlign: 'center', color: '#00a58a'}}>
                  {utility.niceNumberFormat(this.state.controls.bid_price.value)} kr (exkl moms före ROT-avdrag)
                </div>*/}
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                <div style={{ width: '100%', marginBottom: 15 }}>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Specifikation av anbudets{this.state.controls.bid_type.value == 'fixed' ? ' ' : ' uppskattade '}totalkostnad</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Arbetskostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round(this.state.controls.bid_price_work.value))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Materialkostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(this.state.controls.bid_price_material.value)} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Servicebil (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round(this.state.controls.bid_price_car.value))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: 'black' }}>Hantverkarens totalkostnad (exkl moms)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: 'black' }}>{utility.niceNumberFormat(Math.round(this.state.controls.bid_price.value))} kr</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                {/*<div style={{display: 'flex', flexDirection: 'row', marginBottom: 10}}>
                  <div style={{width: '70%'}}>
                    <div style={{textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080'}}>Byggbuddys avgift* (exkl moms)</div>
                  </div>
                  <div style={{width: '30%'}}>
                    <div style={{textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080'}}>{utility.niceNumberFormat(Math.round(this.state.bid_commission * 0.8))} kr</div>
                  </div>
                </div>*/}
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>Moms</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: '#808080' }}>{utility.niceNumberFormat(Math.round(this.state.controls.bid_price.value * 0.25))} kr</div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 14, color: 'black' }}>ROT-avdrag</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 14, color: 'red' }}>{utility.niceNumberFormat(Math.round(this.state.bid_price_deducted * 1.25) - Math.round(this.state.controls.bid_price.value * 1.25))} kr</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ backgroundColor: '#e0e0e0', marginTop: 10, padding: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                  <div style={{ width: '70%' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 14, color: 'black' }}>Uppdragsgivarens totalkostnad</div>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 14, color: 'black' }}>(inkl moms, efter ROT-avdrag)</div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 14, color: 'black' }}>{utility.niceNumberFormat(Math.round(this.state.bid_price_deducted * 1.25 + this.state.bid_commission))} kr</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment terms */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#026ca0', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-bid-type.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Välj betalningsvillkor</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här väljer du när kunden ska betala</div>
              </div>
            </div>

            <div onClick={() => this.setBidPaymentTerms('after')} style={{ width: '100%', boxSizing: 'border-box', padding: 20, backgroundColor: (this.state.controls.bid_payment_terms.value == 'after' ? '#cdeefe' : '#ffffff') }}>
              <div>
                <div style={{ fontSize: 24, textAlign: 'left', color: (this.state.controls.bid_payment_terms.value == 'after' ? 'black' : 'black'), fontWeight: '600' }}>När arbetet är utfört</div>
              </div>
              <div>
                <div style={{ fontSize: 12, textAlign: 'left', color: (this.state.controls.bid_payment_terms.value == 'after' ? 'black' : '#808080') }}>Kunden betalar hela beloppet när arbetet är utfört.</div>
              </div>
            </div>

            <div onClick={() => this.setBidPaymentTerms('monthly')} style={{ width: '100%', boxSizing: 'border-box', padding: 20, backgroundColor: (this.state.controls.bid_payment_terms.value == 'monthly' ? '#cdeefe' : '#ffffff') }}>
              <div>
                <div style={{ fontSize: 24, textAlign: 'left', color: (this.state.controls.bid_payment_terms.value == 'monthly' ? 'black' : 'black'), fontWeight: '600' }}>Månadsvis</div>
              </div>
              <div>
                <div style={{ fontSize: 12, textAlign: 'left', color: (this.state.controls.bid_payment_terms.value == 'monthly' ? 'black' : '#808080') }}>Kunden betalar för utfört arbete månadsvis i efterskott.</div>
              </div>
            </div>

            {/* Dates */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#00a1b1', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-dates.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Datum för genomförande</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här väljer du inom vilka datum uppdraget kommer att genomföras</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white', padding: 20 }}>

              <div style={{ width: '100%', marginBottom: '15px' }}>
                {this.renderDatePicker('bid_startdate', 'När kan uppdraget tidigast påbörjas?', earliest_startdate)}
              </div>
              <div style={{ width: '100%' }}>
                {this.renderDatePicker('bid_enddate', 'När kan uppdraget senast vara färdigt?', this.state.controls.bid_startdate.value)}
              </div>
            </div>

            {/* Prerequisites */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#0d7a3e', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-cog.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Ange förutsättningar</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här anger du uppgifter om dig och dina underleverantörer. Uppgifterna kommer att presenteras av kunden och bli en del av avtalet</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white', padding: 20 }}>
              <div style={{ width: '100%' }}>
                {this.state.bid_properties.map(bid_property => (
                  <div style={{ marginBottom: 20, paddingHorizontal: 5, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={bid_property.property_id}>
                    {bid_property.property_type == 'bool' ? (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                        <div style={{ width: '40px' }}>
                          <input type="checkbox"
                            style={{ marginRight: 15 }}
                            checked={(bid_property.bool == 1)}
                            onChange={(event) => { this.setBidProperty(bid_property.property_id, event.target.value) }}
                          />
                        </div>
                        <div style={{ width: '450px' }}>
                          <div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontWeight: 'bold' }}>{bid_property.property_provider_heading}</div>
                          </div>
                          <div>
                            <div style={{ fontSize: 14, textAlign: 'left', color: '#808080' }}>{bid_property.property_provider_bid_info}</div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>


            {/* Bid text */}
            <div
              style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'space-between', padding: 10, marginTop: 20, backgroundColor: '#00a1b1', alignItems: 'center' }}>
              <div style={{ width: '12%' }}>
                <img src={require('../../../assets/icons/icon-bouble.png')} style={{ height: 32, width: 32 }} />
              </div>
              <div style={{ width: '88%' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Övrigt</div>
                <div style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'left', color: 'white' }}>Här skrivs annan viktig information, exempelvis hantverkarens avrådanden. Denna text blir en del av avtalet med kunden</div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <DefaultInput
                    placeholder="Här skrivs annan viktig information..."
                    rowSpan={10}
                    value={this.state.controls.bid_text.value}
                    onChange={(event) => this.updateInputState("bid_text", event.target.value)}
                    autoCapitalize="sentences"
                    style={{ borderRadius: 7, backgroundColor: 'white', width: '100%' }}
                  />
                </div>
              </div>

              {/*<div style={{padding:0, marginVertical: 20, width: '100%', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center',}}>
                <DefaultButton
                  style={{width: (Dimensions.get('screen').width * 0.8), backgroundColor: '#167f40', marginTop: 20}}
                  onClick={() => this.submitBid()}
                >
                  Skapa anbud
                </DefaultButton>
              </div>*/}
            </div>

          </div>

          <div style={{
            flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', //position: 'absolute', 
            borderColor: '#808080',
            borderTopWidth: 2,
            bottom: 0,
            backgroundColor: 'white',
            padding: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: -10,
            },
            shadowOpacity: 0.5,
            shadowRadius: 15,
          }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {this.state.controls.bid_type.value == 'fixed' ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 10, width: 60 }}>
                  <img src={require('../../../assets/icons/icon-lock.png')} style={{ height: 24, width: 24 }} />
                  <div style={{ fontSize: 9, textAlign: 'center' }}>Fast pris</div>
                </div>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {this.state.controls.bid_type.value == 'fixed' ? (
                  <div style={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40' }}>
                    {utility.niceNumberFormat(Math.round(this.state.bid_price_deducted * 1.25 + this.state.bid_commission))} <span style={{ fontSize: 16 }}>kr</span>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, width: 60 }}>
                        <img src={require('../../../assets/icons/icon-unlock.png')} style={{ height: 24, width: 24 }} />
                        <div style={{ fontSize: 9, textAlign: 'center' }}>Rörligt pris</div>
                      </div>
                      <div style={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40' }}>
                        {utility.niceNumberFormat(Math.round(this.state.bid_price_extra_hour_deducted * 1.25))} <span style={{ fontSize: 16 }}>kr / timme</span>
                      </div>
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: 'black' }}>
                      Uppskattad totalkostnad <span style={{ fontWeight: 'bold' }}>{utility.niceNumberFormat(Math.round(this.state.bid_price_deducted * 1.25 + this.state.bid_commission))}</span> kr
                    </div>
                  </div>
                )}
                <div style={{ fontSize: 11, textAlign: 'center', color: '#808080' }}>Inkl moms efter ROT-avdrag</div>
              </div>
              {this.state.controls.bid_type.value == 'fixed' ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 10, width: 60 }}>
                </div>
              ) : null}
            </div>

            <div style={{ padding: 0, margin: '20px 0', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              {/*<div style={{fontSize: 11, textAlign: 'center', color: '#808080'}}>Informationen ovan kommer att ingå i anbudet. Säkerställ att allt är korrekt innan du skapar anbudet.</div>*/}
              {!this.state.bid_is_being_created ? (
                <DefaultButton
                  style={{ width: '80%', backgroundColor: '#167f40' }}
                  onClick={() => this.submitBid()}
                >
                  Skapa offert
                </DefaultButton>
              ) : (
                <DefaultButton
                  style={{ width: '80%', backgroundColor: '#167f40' }}
                  disabled={true}
                >
                  Offerten skapas...
                </DefaultButton>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderByggbuddyContent() {

    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auctions: state.auctions.auctions,
  bids: state.auctions.bids,
  scope: state.auth.scope,
  user: state.auth.user,
  provider_account: state.auth.provider_account,
  properties: state.shop.properties,
  tasks: state.shop.tasks,
  services: state.shop.services,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    submitBid: (bid) => dispatch(submitBid(bid)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderMakeBidScreen)
