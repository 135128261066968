import React from 'react';
import { connect } from "react-redux";

import { updateCustomer } from '../../../store/actions/auth'
import { goToScreen } from '../../../store/actions/ui'

class CustomerResetPassword extends React.Component {

  state = {
    controls: {
      password: {
        value: '',
        valid: true,
        touched: false,
      },
      password_confirm: {
        value: '',
        valid: true,
        touched: false,
      },
    },
    password_change_successful: false,
  }

  setControlValue = (event) => {

    let value = event.target.value
    let name = event.target.name
    let type = event.target.type

    if (type == 'checkbox') {
      value = (event.target.checked ? 1 : 0)
    }

    this.setState(prev_state => {
      return {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [name]: {
            value: value,
            valid: true,
            touched: true
          },
        }
      }
    })
  }

  componentDidMount() {
  }

  resetPassword = () => {
    
    if (this.props.auth.user.customer_id) {
      let customer = {
        customer_id:    this.props.auth.user.customer_id, 
        password:       this.state.controls.password.value,
      }

      this.props.resetPassword(customer)
      .then(() => {
        //this.props.goToScreen('start')
        this.setState(prev_state => {
          return {
            ...prev_state,
            password_change_successful: true,
          }
        })
      })
      .catch(() => {
        alert('Misslyckades!')
      })
    }
    else {
      alert('Saknar customer_id')
    }
  }

  render() {
    return (
      <div className="CustomerResetPassword" style={{clear: 'both', textAlign: 'left', maxWidth: 400, padding: 50}}>

        {this.state.password_change_successful ? (
          <div>
            <div style={{marginBottom: 20}}>
              Det gick bra! Lösenordet har ändrats.
            </div>

            <button
              style={{
                backgroundColor: 'white',
                border: '1px solid #167f40',
                borderRadius: '0.7em',
                color: '#167f40',
                padding: '1em',
                fontSize: '1em',
                marginLeft: '1em',
              }}
              type="submit"
              onClick={(event) => {event.preventDefault(); this.props.goToScreen('fork')}} >
              Tillbaka
            </button>
          </div>

        ) : null}

        {this.props.auth.user && !this.state.password_change_successful ? (
          <div>
            <div style={{marginBottom: 20}}>
              Välj ett nytt lösenord för {this.props.auth.user.first_name} {this.props.auth.user.last_name}
            </div>
        
            <div style={{marginBottom: 20}}>
              <label style={{fontWeight: 'bold'}}>Nytt lösenord</label><br />
              <input
                style={{fontSize: 14, padding: 4, width: '100%'}}
                name="password"
                type="password"
                value={this.state.controls.password.value}
                onChange={this.setControlValue} />
            </div>

            <div style={{marginBottom: 20}}>
              <label style={{fontWeight: 'bold'}}>Bekräfta lösenord</label><br />
              <input
                style={{fontSize: 14, padding: 4, width: '100%'}}
                name="password_confirm"
                type="password"
                value={this.state.controls.password_confirm.value}
                onChange={this.setControlValue} />
            </div>

            <div style={{marginBottom: 20}}>
              <button
                style={{
                  backgroundColor: '#167f40',
                  border: 'none',
                  borderRadius: '0.7em',
                  color: 'white',
                  padding: '1em',
                  fontSize: '1em',
                }}
                disabled={this.state.controls.password.value.length < 6 || this.state.controls.password.value != this.state.controls.password_confirm.value}
                type="submit"
                onClick={(event) => {event.preventDefault(); this.resetPassword()}} >
                Spara lösenord
              </button>

              <button
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #167f40',
                  borderRadius: '0.7em',
                  color: '#167f40',
                  padding: '1em',
                  fontSize: '1em',
                  marginLeft: '1em',
                }}
                type="submit"
                onClick={(event) => {event.preventDefault(); this.props.goToScreen('fork')}} >
                Tillbaka
              </button>
            </div>
          </div>
        ) : null }

      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     auth: state.auth,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
     resetPassword: (customer) => dispatch(updateCustomer(customer)),
     goToScreen: (screen) => dispatch(goToScreen(screen)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerResetPassword);

//export default CustomerResetPassword;
