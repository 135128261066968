import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import uiReducer from './reducers/ui';
import authReducer from './reducers/auth';
import debugReducer from './reducers/debug';
import shopReducer from './reducers/shop';
import auctionsReducer from './reducers/auctions';
import jobsReducer from './reducers/jobs';

const rootReducer = combineReducers({
    ui: 		uiReducer,
    auth: 		authReducer,
    debug: 		debugReducer,
    shop: 		shopReducer,
    auctions: 	auctionsReducer,
    jobs: 		jobsReducer,
});

let store = null;

const configureStore = () => {
    store = createStore(rootReducer, compose(applyMiddleware(thunk)));
    return store;
};

export default configureStore;
export {store}