import React from 'react';
import { connect } from "react-redux";

import { tryAuth } from '../../../store/actions/auth'
import { goToScreen } from '../../../store/actions/ui'


class ProviderLogin extends React.Component {

  state = {
    controls: {
      username: {
        value: '',
        valid: true,
        touched: false,
      },
      password: {
        value: '',
        valid: true,
        touched: false,
      },
    }
  }

  setControlValue = (event) => {

    let value = event.target.value
    let name = event.target.name
    let type = event.target.type

    if (type == 'checkbox') {
      value = (event.target.checked ? 1 : 0)
    }

    this.setState(prev_state => {
      return {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [name]: {
            value: value,
            valid: true,
            touched: true
          },
        }
      }
    })
  }

  componentDidMount() {
  }

  login = () => {
    
    let auth_data = {
      scope:            'provider',
      email:            this.state.controls.username.value,
      password:         this.state.controls.password.value,
    }

    this.props.tryAuth(auth_data)
  }

  render() {
    return (
      <div className="ProviderLogin" style={{clear: 'both', textAlign: 'left', maxWidth: 400, padding: 50}}>
        
        <div style={{marginBottom: 20}}>
          <label style={{fontWeight: 'bold'}}>Användarnamn</label><br />
          <input
            style={{fontSize: 14, padding: 4, width: '100%'}}
            name="username"
            type="text"
            value={this.state.controls.username.value}
            onChange={this.setControlValue} />
        </div>

        <div style={{marginBottom: 20}}>
          <label style={{fontWeight: 'bold'}}>Lösenord</label><br />
          <input
            style={{fontSize: 14, padding: 4, width: '100%'}}
            name="password"
            type="password"
            value={this.state.controls.password.value}
            onChange={this.setControlValue} />
        </div>

        <div style={{marginBottom: 20}}>
          <button
            style={{
              backgroundColor: '#167f40',
              border: 'none',
              borderRadius: '0.7em',
              color: 'white',
              padding: '1em',
              fontSize: '1em',
            }}
            disabled={!this.state.controls.username.touched || !this.state.controls.password.touched}
            type="submit"
            onClick={(event) => {event.preventDefault(); this.login()}} >
            Logga in
          </button>

          <button
            style={{
              backgroundColor: 'white',
              border: '1px solid #167f40',
              borderRadius: '0.7em',
              color: '#167f40',
              padding: '1em',
              fontSize: '1em',
              marginLeft: '1em',
            }}
            type="submit"
            onClick={(event) => {event.preventDefault(); this.props.goToScreen('fork')}} >
            Tillbaka
          </button>
        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     auth: state.auth,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
     tryAuth: (auth_data) => dispatch(tryAuth(auth_data)),
     goToScreen: (screen) => dispatch(goToScreen(screen)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderLogin);

//export default ProviderLogin;
