import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import ProviderApp from './ProviderApp'

import { Socket, SocketService } from './services/socket.service'
import { Analytics, AnalyticsService } from './services/analytics.service'

import './App.css';

var history = null

class App extends React.Component {

  async componentDidMount() {

    // If app is starting
    if (Socket == null) {
      SocketService.createInstance()
      AnalyticsService.createInstance()
    }
    
    await Socket.startSocket()

    // Save reference to router history
    history = this.props.history;
    
  }

  render() {

    let provider = 'byggbuddy'

    if (this.props.custom_domain) {
      provider = this.props.custom_domain
    }
    else if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      provider = this.props.match.params.provider
    }

    return (
      <ProviderApp key={provider} {...this.props} />
    )
  }
}

function mapStateToProps(state) {
   return {
   }
}

function mapDispatchToProps(dispatch) {
  return {
   }
}

var AppRedux = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppRedux;
export {AppRedux as App, history}
