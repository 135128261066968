import moment from 'moment/min/moment-with-locales'

var utility = {

 Capitalize(str) {
  let temp = str.toLowerCase();
  //temp = temp.replace('May', 'Maj')
  temp = temp.replace('may', 'maj')
  //temp = temp.replace('Oct', 'Okt')
  temp = temp.replace('oct', 'okt')
  return temp
 },

 calculateTimeDiff(start, end) {
    if (start && end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0)
       hours = hours + 24;

    return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    } else {
      return '';
    }
  },

  niceDateFormat(date_string) {
    let date = moment(date_string);
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'day').startOf('day');
    let tomorrow = moment().add(1, 'day').startOf('day');
    let later = moment().add(2, 'day').startOf('day');
    let next_year = moment().add(1, 'year').startOf('day');
    let last_year = moment().subtract(1, 'year').startOf('day');

    if (date.diff(next_year) >= 0) {
      return utility.Capitalize(date.format('DD MMM, YYYY'));
    }
    if (date.diff(later) >= 0) {
      return utility.Capitalize(date.format('DD MMM'));
    }
    else if (date.diff(tomorrow) >= 0) {
      return 'Imorgon';
    }
    else if (date.diff(today) >= 0) {
      return 'Idag';
    }
    else if (date.diff(yesterday) >= 0) {
      return 'Igår';
    }
    else if (date.diff(last_year) >= 0) {
      return utility.Capitalize(date.format('DD MMM'));
    }
    else {
      return utility.Capitalize(date.format('DD MMM, YYYY'));
    }
  },

  niceLongDateFormat(date_string) {
    let date = moment(date_string);
    let temp = utility.Capitalize(date.format('DD MMM YYYY'));

    temp = temp.replace('jan', 'januari')
    temp = temp.replace('feb', 'februari')
    temp = temp.replace('mar', 'mars')
    temp = temp.replace('apr', 'april')
    temp = temp.replace('maj', 'maj')
    temp = temp.replace('jun', 'juni')
    temp = temp.replace('jul', 'juli')
    temp = temp.replace('aug', 'augusti')
    temp = temp.replace('sep', 'september')
    temp = temp.replace('okt', 'oktober')
    temp = temp.replace('nov', 'november')
    temp = temp.replace('dec', 'devember')
    return temp
  },

  niceDateTimeFormat(date_string, no_future = false) {
    let date = moment(date_string);
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'day').startOf('day');
    let tomorrow = moment().add(1, 'day').startOf('day');
    let later = moment().add(2, 'day').startOf('day');
    let next_year = moment().add(1, 'year').startOf('day');
    let last_year = moment().subtract(1, 'year').startOf('day');

    if (date.diff(next_year) >= 0) {
      return utility.Capitalize(date.format('DD MMM, YYYY'));
    }
    if (date.diff(later) >= 0) {
      return utility.Capitalize(date.format('DD MMM'));
    }
    else if (date.diff(tomorrow) >= 0) {
      return 'Imorgon ' + date.format('HH:mm');
    }
    else if (date.diff(today) >= 0) {
      let now = moment()
      let diff = date.diff(now)
      let duration = moment.duration(diff)

      if (date.diff(moment()) >= 0 && duration.hours() < 3) {
        if (no_future) {
          return 'Nu';
        }
        else {
          return 'Om ' + duration.hours() + ' tim ' + duration.minutes() + ' m';
        }
      }
      else {
        return 'Idag ' + date.format('HH:mm');
      }
    }
    else if (date.diff(yesterday) >= 0) {
      return 'Igår ' + date.format('HH:mm');
    }
    else if (date.diff(last_year) >= 0) {
      return utility.Capitalize(date.format('DD MMM'));
    }
    else {
      return utility.Capitalize(date.format('DD MMM, YYYY'));
    }
  },

  niceNumberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  },

  niceAddress(address) {

    if (address.substring(address.length - 8) == ', Sweden') {
      address = address.substring(0, address.length - 8);
    }
    if (address.substring(address.length - 9) == ', Sverige') {
      address = address.substring(0, address.length - 9);
    }
    return address
  },

  cityAddress(address) {

    let nice_address = utility.niceAddress(address)
    let chunks = nice_address.split(', ')

    return chunks[chunks.length-1]
  },

  anonymousAddress(address) {

    var hasNumber = /\d/

    let chunks = address.split(', ')
    let anonymous_chunks = []
    let found_first = false

    for (var j = 0; j < chunks.length; j++) {
      let chunk = chunks[j]

      let parts = chunk.split(' ')
      let anonymous_parts = []
      
      for (var i = 0; i < parts.length; i++) {
        let part = parts[i]
        //if (!found_first && !isNaN(part)) {
        //  found_first = true
        //}
        if (hasNumber.test(part)) {

        } 
        else {
          anonymous_parts.push(part)
        }
      }
      anonymous_chunks.push(anonymous_parts.join(' '))
    }

    address = anonymous_chunks.join(', ')

    if (address.substring(address.length - 8) == ', Sweden') {
      address = address.substring(0, address.length - 8);
    }
    if (address.substring(address.length - 9) == ', Sverige') {
      address = address.substring(0, address.length - 9);
    }

    return address
  },

  softRange(number) {

    let granularity = (number > 100 ? 50 : 10)
    let upper = Math.ceil(number * 1.2 / granularity) * granularity
    let lower = Math.floor(number * 0.8 / granularity) * granularity

    return utility.niceNumberFormat(lower) + '-' + utility.niceNumberFormat(upper)
  },

  truncateText(text, max_length) {
    if (text.length > max_length) {
      let truncated_text = text.substring(0, max_length)
      let i = truncated_text.lastIndexOf(' ')
      if (i == -1) {
        return truncated_text + '...'
      }
      else {
        return truncated_text.substring(0, i) + '...'
      }
    }
    else {
      return text
    }
  },

  truncateTextRows(text, max_rows, row_width) {

    let hard_rows = text.split("\n")
    let soft_rows = 0
    let cutoff_length = 0
    
    for (var i = 0; i < hard_rows.length; i++) {
      let rows_remaining = max_rows - soft_rows
      let estimated_rows = Math.ceil((hard_rows[i].length + 1) / row_width)
      
      if (estimated_rows <= rows_remaining) {
        soft_rows += estimated_rows
        cutoff_length += hard_rows[i].length
      }
      else {
        cutoff_length += rows_remaining * row_width
        break
      }
    }

    if (text.length > cutoff_length) {
      let truncated_text = text.substring(0, cutoff_length)
      let i = truncated_text.lastIndexOf(' ')
      if (i == -1) {
        return truncated_text + '...'
      }
      else {
        return truncated_text.substring(0, i) + '...'
      }
    }
    else {
      return text
    }
  },
}

export default utility