import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import _ from 'lodash'

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import utility from '../../../utility/utility';
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  selectSearchTask, 
  resetSearch,
} from '../../../store/actions/shop'


class HomeScreen extends ByggbuddyScreen {

  // Component settings
  show_cart = true
  show_back = false
  content_max_width = '100%'
  content_padding = '0'
  
  state = {
    provider_image_index: 0,
    provider_reference_image_indices: {},
  }

  prevProviderImage = () => {

    this.setState(prev_state => {

      let num_images = this.props.ui.brand_provider_account.provider_images.length
      let new_index = prev_state.provider_image_index - 1
      if (new_index < 0) {
        new_index = num_images - 1
      }

      return {
        ...prev_state,
        provider_image_index: new_index,
      }
    })
  }

  nextProviderImage = () => {

    this.setState(prev_state => {

      let num_images = this.props.ui.brand_provider_account.provider_images.length
      let new_index = prev_state.provider_image_index + 1
      if (new_index == num_images) {
        new_index = 0
      }

      return {
        ...prev_state,
        provider_image_index: new_index,
      }
    })
  }

  getReferenceImageIndex = (provider_reference_id) => {

    if (this.state.provider_reference_image_indices[provider_reference_id]) {
      return this.state.provider_reference_image_indices[provider_reference_id]
    }
    else {
      return 0
    }
  }

  prevReferenceImage = (provider_reference_id) => {

    this.setState(prev_state => {

      let num_images = 0

      for (var i = 0; i < this.props.ui.brand_provider_account.provider_references.length; i++) {
        let provider_reference = this.props.ui.brand_provider_account.provider_references[i]

        if (provider_reference.provider_reference_id == provider_reference_id) {
          num_images = provider_reference.provider_reference_images.length
        }
      }

      let new_index = this.getReferenceImageIndex(provider_reference_id) - 1
      if (new_index < 0) {
        new_index = num_images - 1
      }

      return {
        ...prev_state,
        provider_reference_image_indices: {
          ...prev_state.provider_reference_image_indices,
          [provider_reference_id]: new_index,
        },
      }
    })
  }

  nextReferenceImage = (provider_reference_id) => {

    this.setState(prev_state => {

      let num_images = 0

      for (var i = 0; i < this.props.ui.brand_provider_account.provider_references.length; i++) {
        let provider_reference = this.props.ui.brand_provider_account.provider_references[i]

        if (provider_reference.provider_reference_id == provider_reference_id) {
          num_images = provider_reference.provider_reference_images.length
        }
      }

      let new_index = this.getReferenceImageIndex(provider_reference_id) + 1
      if (new_index == num_images) {
        new_index = 0
      }

      return {
        ...prev_state,
        provider_reference_image_indices: {
          ...prev_state.provider_reference_image_indices,
          [provider_reference_id]: new_index,
        },
      }
    })
  }
  
  toShop = () => {
    this.props.history.push(this.getRoot() + '/shop/find_task/0')
  }

  beforeRender() {
  
    this.show_cart = (this.props.shop.cart.tasks.length > 0)
  }

  getFirstLevelTasks(parent_task_id) {

    let tasks = this.props.shop.tasks
    let first_level_tasks = []

    for (var i = 0; i < tasks.length; i++) {
      let task = tasks[i]

      if (task.parent_task_id == parent_task_id && task.task_status == 'active' && this.props.shop.always_in_cart_task_ids.indexOf(task.task_id) < 0) {
        first_level_tasks.push(task)
      }
    }

    if (first_level_tasks.length == 1) {
      // Only one task found on this level, use next level
      return this.getFirstLevelTasks(first_level_tasks[0].task_id)
    }
    else {
      return first_level_tasks
    }

  }

  renderBrandedContent(provider_account) {

    let first_level_tasks = this.getFirstLevelTasks(0)

    return (
      <>
        {provider_account.provider_images.length > 0 ? (
          <>
            <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden', width: '100%', position: 'relative'}}>
              <img src={provider_account.provider_images[this.state.provider_image_index].image_url} style={{height: 'calc(100vh - 85px)', width: '100%', objectFit: 'cover'}} />
              
              {provider_account.provider_images.length > 1 ? (
                <div onClick={() => this.prevProviderImage()} style={{cursor: 'pointer', /*backgroundColor: 'rgb(0,0,0,0.5)',*/ padding: '20px', position: 'absolute', zIndex: 9999, left: 0, top: 0, width: 'min(60px, 10%)', height: 'calc(100vh - 85px)', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{color: 'white', fontSize: '30px'}} className="material-icons">arrow_back_ios</span>
                </div>
              ) : null}
              {provider_account.provider_images.length > 1 ? (
                <div onClick={() => this.nextProviderImage()} style={{cursor: 'pointer', /*backgroundColor: 'rgb(0,0,0,0.5)',*/ padding: '20px', position: 'absolute', zIndex: 9999, right: 0, top: 0, width: 'min(60px, 10%)', height: 'calc(100vh - 85px)', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{color: 'white', fontSize: '30px'}} className="material-icons">arrow_forward_ios</span>
                </div>
              ) : null}

              <div className="byggbuddy-provider-plate">
                <div style={{margin: '20px auto', width: '100%', maxWidth: '600px', textAlign: 'center'}}>
                  <h1 style={{fontWeight: 'normal', color: '#fff', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>
                    Välkommen till {provider_account.provider_name}!
                  </h1>
                </div>

                <div style={{margin: '20px auto', width: '100%', maxWidth: '600px', }}>
                  {provider_account.provider_texts.ID_SHOP_INFO_PROVIDER ? (
                    <p style={{fontSize: '16px', fontWeight: 'normal', color: '#fff', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
                      {provider_account.provider_texts.ID_SHOP_INFO_PROVIDER}
                    </p>
                  ) : (
                    <div>
                      <p style={{fontSize: '16px', fontWeight: 'normal', color: '#fff', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
                        {provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 ? provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 :  provider_account.provider_description}
                      </p>
                    </div>
                  )} 

                </div>

                <div style={{margin: '20px auto', width: '100%', maxWidth: '400px', padding: '20px', boxSizing: 'border-box'}}>
                  <DefaultButton
                    style={{width: '100%', height: '50px', padding: '5px 10px', backgroundColor: (provider_account.provider_texts.ID_PROVIDER_THEME_COLOR_ONE ? provider_account.provider_texts.ID_PROVIDER_THEME_COLOR_ONE : '#167f40'), fontSize: '20px', textTransform: 'uppercase', boxSizing: 'border-box'}}
                    onClick={() => this.toShop()}
                  >
                    Priser och offert
                  </DefaultButton>
                </div>
              </div>

            </div>
          </>
        ) : null}
        
        <div style={{width: '100%', padding: '20px', boxSizing: 'border-box', backgroundColor: 'black', color: 'white'}}>
          <h3 style={{textAlign: 'center'}}>Det här erbjuder vi</h3>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
            {first_level_tasks.map(task => (
              <div key={task.task_id} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px', padding: '0 10px', marginBottom: '20px'}}>
                <img style={{height: '40px'}} src={task.image_url} />
                <div style={{fontSize: '16px', textAlign: 'center', marginTop: '2px'}}>{task.task_name}</div>
              </div>
            ))}
          </div>
        </div>
        

        <div style={{display: 'flex', flexDirection: 'row', width: '100%', maxWidth: '800px', margin: '20px auto 0', boxSizing: 'border-box', flexWrap: 'wrap'}}>
          
          <div style={{width: '300px', padding: '20px', boxSizing: 'border-box'}}>
            <h2>{provider_account.provider_name}</h2>
            <p style={{fontSize: '16px', fontWeight: 'normal', color: '#404040', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
              {provider_account.provider_locations.map(provider_location => utility.cityAddress(provider_location.geolocation_text)).join(', ')}<br />
              <br />
              {provider_account.org_phone}<br />
              {provider_account.org_email}<br />
              <br />
              {provider_account.org_name}<br />
              {provider_account.org_nr}<br />
              <br />
              {provider_account.org_address1 ? (
                <>
                  {provider_account.org_address1}<br />
                </>
              ) : null}
              {provider_account.org_address2 ? (
                <>
                  {provider_account.org_address2}<br />
                </>
              ) : null}
              {provider_account.org_address3 ? (
                <>
                  {provider_account.org_address3}<br />
                </>
              ) : null}
            </p>
          </div>

          <div style={{width: '500px', padding: '20px', boxSizing: 'border-box'}}>
            <h2>Om oss</h2>
            <p style={{fontSize: '16px', fontWeight: 'normal', color: '#404040', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
              {provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_LONG ? provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_LONG :  provider_account.provider_description}
            </p>
          </div>

          
        </div>

        {provider_account.provider_references.length > 0 ? (

          <div className="byggbuddy-provider-references">

            <div style={{backgroundColor: 'black', padding: '15px', width: '100%', marginTop: '40px', boxSizing: 'border-box'}}>
              <h2 style={{textAlign: 'center', color: 'white'}}>{provider_account.provider_texts.ID_PROVIDER_REFERENCES_HEADING ? provider_account.provider_texts.ID_PROVIDER_REFERENCES_HEADING :  'Möt några av våra nöjda kunder'}</h2>
            </div>

            {provider_account.provider_references.map((provider_reference, index) => (
              <div key={provider_reference.provider_reference_id} className="byggbuddy-provider-reference" style={{display: 'flex', flexDirection: 'column', /*marginTop: '40px'*/}}>
                <div className="byggbuddy-provider-reference-text-vertical">
                  <div style={{maxWidth: '600px', margin: '0 auto'}}> 
                    <h3>{provider_reference.reference_name}</h3>
                    <p>{provider_reference.reference_text}</p>
                  </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className="byggbuddy-provider-reference-images">
                    <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden', width: '100%', position: 'relative'}}>
                      <img src={provider_reference.provider_reference_images[this.getReferenceImageIndex(provider_reference.provider_reference_id)].image_url} style={{width: '100%', objectFit: 'cover', aspectRatio: '3/2', maxHeight: 'calc(100vh - 80px)'}} />
                      
                      {provider_reference.provider_reference_images.length > 1 ? (
                        <div onClick={() => this.prevReferenceImage(provider_reference.provider_reference_id)} style={{cursor: 'pointer', /*backgroundColor: 'rgb(0,0,0,0.5)',*/ padding: '20px', position: 'absolute', left: 0, bottom: '20vh', width: 'min(60px, 10%)', marginTop: '-20vh', height: '40vh', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                          <span style={{color: 'white', fontSize: '30px'}} className="material-icons">arrow_back_ios</span>
                        </div>
                      ) : null}
                      {provider_reference.provider_reference_images.length > 1 ? (
                        <div onClick={() => this.nextReferenceImage(provider_reference.provider_reference_id)} style={{cursor: 'pointer', /*backgroundColor: 'rgb(0,0,0,0.5)',*/ padding: '20px', position: 'absolute', right: 0, bottom: '20vh', width: 'min(60px, 10%)', marginTop: '-20vh', height: '40vh', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                          <span style={{color: 'white', fontSize: '30px'}} className="material-icons">arrow_forward_ios</span>
                        </div>
                      ) : null}

                    </div>
                    <div className="byggbuddy-provider-reference-text-horizontal">
                      <h3 style={{textAlign: 'center'}}>{provider_reference.reference_name}</h3>
                      <p style={{fontSize: '16px', fontWeight: 'normal', color: '#404040', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>{provider_reference.reference_text}</p>
                    </div>
                  </div>
                </div>
                
              </div>
            ))}
          </div>

        ) : null}

        <div style={{width: '100%', maxWidth: '400px', padding: '20px', marginTop: '20px', boxSizing: 'border-box'}}>
          <DefaultButton
            style={{width: '100%', height: '50px', padding: '5px 10px', backgroundColor: (provider_account.provider_texts.ID_PROVIDER_THEME_COLOR_ONE ? provider_account.provider_texts.ID_PROVIDER_THEME_COLOR_ONE : '#167f40'), fontSize: '20px', textTransform: 'uppercase', boxSizing: 'border-box'}}
            onClick={() => this.toShop()}
          >
            Beställ direkt
          </DefaultButton>
        </div>

        <div style={{width: '100%', boxSizing: 'border-box', backgroundColor: 'black', color: 'white', marginTop: '40px'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '240px', padding: '0 10px'}}>
              <div style={{fontSize: '14px', textAlign: 'center', padding: '20px'}}>
                <b>{provider_account.org_name}</b><br />
                {provider_account.org_nr}<br />
                {provider_account.org_address1 ? (
                  <>
                    {provider_account.org_address1}<br />
                  </>
                ) : null}
                {provider_account.org_address2 ? (
                  <>
                    {provider_account.org_address2}<br />
                  </>
                ) : null}
                {provider_account.org_address3 ? (
                  <>
                    {provider_account.org_address3}<br />
                  </>
                ) : null}
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '240px', padding: '0 10px'}}>
              <div style={{fontSize: '14px', textAlign: 'center', padding: '20px'}}>
                <b>Kontakta oss</b><br />
                {provider_account.org_phone}<br />
                {provider_account.org_email}
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '240px', padding: '0 10px'}}>
              <div style={{fontSize: '14px', textAlign: 'center', padding: '20px'}}>
                <i>Denna webbsida levereras av Byggbuddy. Beställ din webbsida eller webbshop för hantverkare på <a style={{color: 'white', textDecoration: 'none'}} href="https://www.byggbuddy.se">www.byggbuddy.se</a></i>
              </div>
            </div>
          </div>
        </div> 
        
 
        {/* Image preload */}
        <div className="byggbuddy-preload" style={{display: 'none'}}>
          {provider_account.provider_images.map(provider_image => (
            <img key={provider_image.provider_image_id} src={provider_image.image_url} />
          ))}
        </div>     
      </>

    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen)
