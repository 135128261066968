import moment from 'moment'
import { store } from '../store/configureStore'
import { pushDebugMessage } from '../store/actions/debug';
import { authGetToken } from '../store/actions/auth';
import { uiStartNetworkOffline, uiStopNetworkOffline } from '../store/actions/ui';

var Socket = null

class SocketService {

  static createInstance = () => {
    if (Socket == null) {
      Socket = new SocketService()
    }
    else {
      console.warn('Tried to instantiate Socket already running')
    }
  }
  
  static token = ''
  
  startSocket = async () => {

    /*store.dispatch(pushDebugMessage('Creating socket'))
    let socket = SocketIOClient.connect('http://dev1.bookzera.se', {
      query: {token: SocketService.token}
    })
    SocketService.socket = socket

    socket.on("connect_error", () => {
      //console.warn('connect_error')
      store.dispatch(pushDebugMessage('connect_error'))
      store.dispatch(uiStartNetworkOffline())
    })

    // Sync client and server clocks
    socket.on('clock_sync', (client_sendtime, server_sendtime) => {
      this.handleClockSync(client_sendtime, server_sendtime)
    })
    SocketService.clock_sync_trials = 0
    this.initClockSync()

    // General chat/debug messages
    socket.on('chat message', (msg) => {
      store.dispatch(pushDebugMessage(msg))
    })

    // On potential passenger waiting at stop
    socket.on('stop_update', (stop_statuses) => {
      //console.warn('stop_update')
      store.dispatch(updateStops(stop_statuses))
    })*/
  }

  setAccessToken = (token) => {

    /*if (SocketService.socket) {
      SocketService.socket.disconnect()
    }*/
    SocketService.token = token
    this.startSocket()
  }

  authorize = (auth_params) => {

    //console.warn(auth_params)

    return new Promise((resolve, reject) => {

      store.dispatch(pushDebugMessage('Socket authorize', auth_params))

      this.postWithoutToken('authorize', auth_params)
      .then(auth_results => {
        //console.warn(auth_results)

        if (!auth_results.access_token || auth_results.error) {
          reject(auth_results)
        }
        else {

          //SocketService.socket.disconnect();
          SocketService.token = auth_results.access_token
          //this.startSocket()

          // Save device token
          /*let device_token = store.getState().auth.device_token
          
          if (device_token != '') {
            Socket.setDeviceToken(device_token)
          }*/
          resolve(auth_results)
        }
      })
      .catch((error) => {
        console.warn(error)
        reject()
      })
    })
  }

  unauthorize = () => {

    console.warn('unauthorize')
    //SocketService.socket.disconnect();
    SocketService.token = ''
    this.startSocket()

  }

  getUser = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getUser',
          params: {
          },
          response: {
            user: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response.getUser.user)
      })
      .catch(error => {
        console.warn(error)
        reject()
      })
    })
  }

  approveUserTerms = (user_terms_approval) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createUserTermsApproval',
          params: {
            user_terms_approval,
          },
          response: {
            user_terms_approval: {}
          }
        },
        {
          function: 'getUser',
          params: {
          },
          response: {
            user: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  getProviderAccount = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getProviderAccount',
          params: {
          },
          response: {
            provider_account: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response.getProviderAccount.provider_account)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  createProviderUser = (provider_user) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'createProviderUser',
          params: {
            provider_user,
          },
          response: {
            provider_user: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.createProviderUser.error) {
          reject(response.createProviderUser.error)
        }
        else {
          resolve(response.createProviderUser.provider_user)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  updateProviderUser = (provider_user) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'updateProviderUser',
          params: {
            provider_user,
          },
          response: {
            provider_user: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.updateProviderUser.error) {
          reject(response.updateProviderUser.error)
        }
        else {
          resolve(response.updateProviderUser.provider_user)
        }
      })
      .catch(error => {
        console.warn(error)
        reject()
      })
    })
  }

  deleteProviderUser = (provider_user_id) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'deleteProviderUser',
          params: {
            provider_user_id,
          },
          response: {
            provider_user: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.deleteProviderUser.error) {
          reject(response.deleteProviderUser.error)
        }
        else {
          resolve(response)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  resetProviderUserPassword = (provider_user) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'resetProviderUserPassword',
          params: {
            provider_user,
          },
          response: {
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.resetProviderUserPassword.error) {
          reject(response.resetProviderUserPassword.error)
        }
        else {
          resolve(response)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  saveProviderCompetences = (provider_competences) => {
    return new Promise((resolve, reject) => {

      let actions = []
      let old_competences = store.getState().auth.provider_account.provider_competences
      
      for (var i = 0; i < old_competences.length; i++) {
        let old_competence = old_competences[i]
        
        let deleted = true
        let provider_competence = null
        for (var j = 0; j < provider_competences.length; j++) {
          let provider_competence = provider_competences[j]
          if (provider_competence.provider_competence_id === old_competence.provider_competence_id) {
            deleted = false
            /*actions.push(
              {
                function: 'updateProviderCompetence',
                params: {
                  provider_competence: provider_competence
                },
                response: {
                }
              }
            )*/
          }
        }
        if (deleted) {
          actions.push(
            {
              function: 'deleteProviderCompetence',
              params: {
                provider_competence_id: old_competence.provider_competence_id,
              },
              response: {
              }
            }
          )
        }
      }
      for (var k = 0; k < provider_competences.length; k++) {
        let provider_competence = provider_competences[k]
        if (provider_competence.provider_competence_id === 0) {
          actions.push(
            {
              function: 'createProviderCompetence',
              params: {
                provider_competence: provider_competence
              },
              response: {
              }
            }
          )
        }
      }
 
      actions.push(
        {
          function: 'getProviderAccount',
          params: {},
          response: {
            provider_account: {},
          }
        }
      )

      //console.warn(actions)

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  saveProviderLocations = (provider_locations) => {
    return new Promise((resolve, reject) => {

      let actions = []
      let old_locations = store.getState().auth.provider_account.provider_locations
      
      for (var i = 0; i < old_locations.length; i++) {
        let old_location = old_locations[i]
        
        let deleted = true
        let provider_location = null
        for (var j = 0; j < provider_locations.length; j++) {
          let provider_location = provider_locations[j]
          if (provider_location.provider_location_id === old_location.provider_location_id) {
            deleted = false
            // Do nothing
          }
        }
        if (deleted) {
          actions.push(
            {
              function: 'deleteProviderLocation',
              params: {
                provider_location_id: old_location.provider_location_id,
              },
              response: {
              }
            }
          )
        }
      }
      for (var k = 0; k < provider_locations.length; k++) {
        let provider_location = provider_locations[k]
        if (provider_location.provider_location_id === 0) {
          actions.push(
            {
              function: 'createProviderLocation',
              params: {
                provider_location: provider_location
              },
              response: {
              }
            }
          )
        }
      }
 
      actions.push(
        {
          function: 'getProviderAccount',
          params: {},
          response: {
            provider_account: {},
          }
        }
      )

      //console.warn(actions)

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  saveProviderImages = (provider_images) => {
    return new Promise((resolve, reject) => {

      /*let delete_actions = []
      let old_images = store.getState().auth.provider_account.provider_images
      
      for (var i = 0; i < old_images.length; i++) {
        let old_image = old_images[i]
        
        let deleted = true
        let provider_image = null
        for (var j = 0; j < provider_images.length; j++) {
          let provider_image = provider_images[j]
          if (provider_image.provider_image_id === old_image.provider_image_id) {
            deleted = false
            // Do nothing
          }
        }
        if (deleted) {
          delete_actions.push(
            {
              function: 'deleteProviderImage',
              params: {
                provider_image_id: old_image.provider_image_id,
              },
              response: {
              }
            }
          )
        }
      }

      let upload_promises = []

      for (var k = 0; k < provider_images.length; k++) {
        let provider_image = provider_images[k]

        if (provider_image.provider_image_id === 0) {
          
          let upload_promise = new Promise((res, rej) => {
            
            Promise.resolve()
            .then(() => {

              if (Platform.OS == 'ios') {

                const filePath = RNFS.DocumentDirectoryPath + '/upload_image' + Math.floor(Math.random() * 10000) + '.jpg'
                
                return RNFS.copyAssetsFileIOS(provider_image.image_url, filePath, 0, 0)
              }
              else {
                return Promise.resolve(provider_image.image_url)
              }
            })
            .then(image_filename => {
              
              return RNFS.readFile(image_filename, 'base64')
            })
            .then((image_data) => {

              let provider_image_with_data = {
                provider_account_id: provider_image.provider_account_id,
                image_data: image_data, //.substring(0,80000),
              }

              let upload_actions = [
                {
                  function: 'uploadProviderImage',
                  params: {
                    provider_image: provider_image_with_data
                  },
                  response: {
                  }
                }
              ]
              return this.post(upload_actions) 
            })
            .then(() => {
              res()
            })
            .catch(error => {
              console.warn(error)
              rej()
            })
          })
          upload_promises.push(upload_promise)
        }
      }
 
      let refresh_actions = [ 
        {
          function: 'getProviderAccount',
          params: {},
          response: {
            provider_account: {},
          }
        }
      ]

      //console.warn(actions)

      Promise.resolve()
      .then(() => {
        if (delete_actions.length > 0) {
          return this.post(delete_actions)
        }
        else {
          return Promise.resolve()
        }
      })
      .then(response => {
        return Promise.all(upload_promises)
      })
      .then(() => {
        return this.post(refresh_actions)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })*/
    })
  }

  saveUser = (user) => {
    return new Promise((resolve, reject) => {

      /*let data = {
        user: user,
      }

      this.post('save_user', data)
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        console.warn(error)
        reject()
      })*/
    })
  }

  signupCustomer = (customer) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'createCustomer',
          params: {
            customer,
          },
          response: {
            customer: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)
        resolve(response.createCustomer.customer)
      })
      .catch(error => {
        console.warn(error)
        reject()
      })
    })
  }

  updateCustomer = (customer) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'updateCustomer',
          params: {
            customer,
          },
          response: {
            customer: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.updateCustomer.error) {
          reject(response.updateCustomer.error)
        }
        else {
          resolve(response.updateCustomer.customer)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  resetCustomerPassword = (customer) => {
    return new Promise((resolve, reject) => {


      let actions = [
        {
          function: 'resetCustomerPassword',
          params: {
            customer,
          },
          response: {
          }
        },
      ]

      this.post(actions)
      .then(response => {
        console.warn(response)

        if (response.resetCustomerPassword.error) {
          reject(response.resetCustomerPassword.error)
        }
        else {
          resolve(response)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  setDeviceToken = (device_token) => {

    /*let now = moment().format("YYYY-MM-DD HH:mm:ss.SSS")

    let data = {
      device_type: device_token.os,
      device_token: device_token.token, 
      client_timestamp: now, 
    }

    this.post('set_device_token', data, true)*/
  }

  getGeolocation = (geolocation) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getGeolocation',
          params: {
            geolocation: geolocation
          },
          response: {
            geolocation: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response.getGeolocation.geolocation)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  submitTaskMissingText = (task_missing_text) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createTaskMissingText',
          params: {
            task_missing_text,
          },
          response: {
            task_missing_text: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        //console.warn(error)
        reject(error)
      })
    })
  }

  getJobs = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
        {
          function: 'getJobEventList',
          params: {
          },
          response: {
            job_events: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  createJob = (job) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createJob',
          params: {
            job,
          },
          response: {
            job: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response.createJob.job)
      })
      .catch(error => {
        //console.warn(error)
        reject(error)
      })
    })
  }

  completeJob = (job_id) => {
    return new Promise((resolve, reject) => {

      let job = {
        job_id,
        job_status: 'completed',
      }

      let actions = [
        {
          function: 'updateJob',
          params: {
            job,
          },
          response: {
            job: {},
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
        {
          function: 'getJobEventList',
          params: {
          },
          response: {
            job_events: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  uploadJobImage = (job_image) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'uploadJobImage',
          params: {
            job_image,
          },
          response: {
            job_image: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response.uploadJobImage.job_image)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  saveJobImages = (job_id, job_images) => {
    return new Promise((resolve, reject) => {

      /*let delete_actions = []
      let old_images = []

      let jobs = store.getState().jobs.jobs
      for (var i = 0; i < jobs.length; i++) {
        let job = jobs[i]
        if (job.job_id == job_id) {
          old_images = job.job_images
        }
      }
      
      for (var i = 0; i < old_images.length; i++) {
        let old_image = old_images[i]
        
        let deleted = true
        let job_image = null
        for (var j = 0; j < job_images.length; j++) {
          let job_image = job_images[j]
          if (job_image.job_image_id === old_image.job_image_id) {
            deleted = false
            // Do nothing
          }
        }
        if (deleted) {
          delete_actions.push(
            {
              function: 'deleteJobImage',
              params: {
                job_image_id: old_image.job_image_id,
              },
              response: {
              }
            }
          )
        }
      }

      let upload_promises = []

      for (var k = 0; k < job_images.length; k++) {
        let job_image = job_images[k]

        if (job_image.job_image_id === 0) {
          
          let upload_promise = new Promise((res, rej) => {
            
            Promise.resolve()
            .then(() => {

              if (Platform.OS == 'ios') {

                const filePath = RNFS.DocumentDirectoryPath + '/upload_image' + Math.floor(Math.random() * 10000) + '.jpg'
                
                return RNFS.copyAssetsFileIOS(job_image.image_url, filePath, 0, 0)
              }
              else {
                return Promise.resolve(job_image.image_url)
              }
            })
            .then(image_filename => {
              
              return RNFS.readFile(image_filename, 'base64')
            })
            .then((image_data) => {

              let job_image_with_data = {
                ...job_image,
                image_data: image_data, //.substring(0,80000),
              }

              let upload_actions = [
                {
                  function: 'uploadJobImage',
                  params: {
                    job_image: job_image_with_data
                  },
                  response: {
                  }
                }
              ]
              return this.post(upload_actions) 
            })
            .then(() => {
              res()
            })
            .catch(error => {
              console.warn(error)
              rej()
            })
          })
          upload_promises.push(upload_promise)
        }
      }*/
    })
  }

  updateJobTask = (job_task) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'updateJobTask',
          params: {
            job_task,
          },
          response: {
            job_task: {},
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
        {
          function: 'getJobEventList',
          params: {
          },
          response: {
            job_events: {}
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  createJobNote = (job_note) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createJobNote',
          params: {
            job_note,
          },
          response: {
            job_note: {}
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        //console.warn(error)
        reject(error)
      })
    })
  }

  createJobEvent = (job_event) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createJobEvent',
          params: {
            job_event,
          },
          response: {
            job_event: {}
          }
        },
        /*{
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },*/
        {
          function: 'getJobEventList',
          params: {
            job_id: job_event.job_id,
          },
          response: {
            job_events: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        //console.warn(error)
        reject(error)
      })
    })
  }

  markJobEventsRead = (job_event_ids) => {
    return new Promise((resolve, reject) => {

      let auth = store.getState().auth
      let actions = []

      for (var i = 0; i < job_event_ids.length; i++) {
        let job_event_id = job_event_ids[i]
      
        let job_event_read = {
          job_event_id,
          read_scope: auth.scope,
          read_user_id: (auth.scope == 'provider' ? auth.user.provider_user_id : (auth.scope == 'customer' ? auth.user.customer_id : 0))
        }

        let action = {
          function: 'createJobEventRead',
          params: {
            job_event_read,
          },
          response: {
            job_event_read: {}
          }
        }
        actions.push(action)
      }
      
      actions.push({
        function: 'getAuctionList',
        params: {
        },
        response: {
          auctions: {}
        }
      })

      actions.push({
        function: 'getJobList',
        params: {
        },
        response: {
          jobs: {}
        }
      })

      actions.push({
        function: 'getJobEventList',
        params: {
        },
        response: {
          job_events: {}
        }
      })
        

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  getAuctions = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  startAuction = (auction) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createAuction',
          params: {
            auction,
          },
          response: {
            auction: {},
          },
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
        {
          function: 'getJobEventList',
          params: {
          },
          response: {
            job_events: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  abortAuction = (auction_id) => {
    return new Promise((resolve, reject) => {

      let auction = {
        auction_id,
        auction_status: 'aborted'
      }

      let actions = [
        {
          function: 'updateAuction',
          params: {
            auction,
          },
          response: {
            auction: {}
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  declineAuction = (auction_id, provider_account_id) => {
    return new Promise((resolve, reject) => {

      let auction_provider = {
        auction_id,
        provider_account_id,
        provider_status: 'declined'
      }

      let actions = [
        {
          function: 'updateAuctionProvider',
          params: {
            auction_provider,
          },
          response: {
            auction_provider: {}
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  submitBid = (bid) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createBid',
          params: {
            bid,
          },
          response: {
            bid: {},
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  signBid = (bid_id) => {
    return new Promise((resolve, reject) => {

      let bid = {
        bid_id,
        bid_status: 'open',
      }

      let actions = [
        {
          function: 'updateBid',
          params: {
            bid,
          },
          response: {
            bid: {},
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  revokeBid = (bid_id) => {
    return new Promise((resolve, reject) => {

      let bid = {
        bid_id,
        bid_status: 'revoked',
      }

      let actions = [
        {
          function: 'updateBid',
          params: {
            bid,
          },
          response: {
            bid: {},
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  acceptBid = (bid_id) => {
    return new Promise((resolve, reject) => {

      let bid = {
        bid_id,
        bid_status: 'accepted',
      }

      let actions = [
        {
          function: 'updateBid',
          params: {
            bid,
          },
          response: {
            bid: {},
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  declineBid = (bid_id) => {
    return new Promise((resolve, reject) => {

      let bid = {
        bid_id,
        bid_status: 'declined',
      }

      let actions = [
        {
          function: 'updateBid',
          params: {
            bid,
          },
          response: {
            bid: {},
          }
        },
        {
          function: 'getAuctionList',
          params: {
          },
          response: {
            auctions: {}
          }
        },
        {
          function: 'getBidList',
          params: {
          },
          response: {
            bids: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  submitReview = (review) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'createReview',
          params: {
            review,
          },
          response: {
            review: {}
          }
        },
        {
          function: 'getJobList',
          params: {
          },
          response: {
            jobs: {}
          }
        }
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  getShopData = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getTaskList',
          params: {},
          response: {
            tasks: {},
          }
        },
        {
          function: 'getTaskItemList',
          params: {},
          response: {
            task_items: {},
          }
        },
        {
          function: 'getTaskItemImageList',
          params: {},
          response: {
            task_item_images: {},
          }
        },
        {
          function: 'getTaskItemQuestionList',
          params: {},
          response: {
            task_item_questions: {},
          }
        },
        {
          function: 'getTaskItemPrerequisiteList',
          params: {},
          response: {
            task_item_prerequisites: {},
          }
        },
        {
          function: 'getDriverList',
          params: {},
          response: {
            drivers: {},
          }
        },
        {
          function: 'getOptionList',
          params: {},
          response: {
            options: {},
          }
        },
        {
          function: 'getServiceList',
          params: {},
          response: {
            services: {},
          }
        },
        {
          function: 'getSettingList',
          params: {},
          response: {
            settings: {},
          }
        },
        {
          function: 'getCompetenceList',
          params: {},
          response: {
            competences: {},
          }
        },
        {
          function: 'getPropertyList',
          params: {},
          response: {
            properties: {},
          }
        },
        {
          function: 'getGeolocationList',
          params: {},
          response: {
            geolocations: {},
          }
        },
        {
          function: 'getProviderAccountList',
          params: {},
          response: {
            provider_accounts: {},
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  getGeolocations = (search_text = null, types = []) => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getGeolocationList',
          params: {
            search_text,
            types,
          },
          response: {
            geolocations: {}
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.warn(response)
        if (response.getGeolocationList.error) {
          console.warn(response.getGeolocationList.error)
          reject(response.getGeolocationList.error)
        }
        else {
          resolve(response.getGeolocationList.geolocations)
        }
      })
      .catch(error => {
        console.warn(error)
        reject(error)
      })
    })
  }

  /*getProviderData = () => {
    return new Promise((resolve, reject) => {

      let actions = [
        {
          function: 'getProviderAccountList',
          params: {},
          response: {
            provider_accounts: {},
          }
        },
      ]

      this.post(actions)
      .then(response => {
        //console.log(response)
        resolve(response)
      })
      .catch(error => {
        console.warn(error)
        reject()
      })
    })
  }*/

  post = (actions, cache = false) => {

    return new Promise((resolve, reject) => {

      //data.token = SocketService.token
      store.dispatch(authGetToken())
      .then(token => {
        let data = {
          token,
          actions,
        }

        //let url = 'http://adev.byggbuddy.se/api'
        let url = '/api'
        let body = JSON.stringify(data)
        let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authentication': 'Bearer ' + token,
        }

        fetch(url, {
          method: 'POST',
          headers,
          body,
        })
        .catch(() => {

          store.dispatch(uiStartNetworkOffline())

          //console.warn('POST ' + func + ' error')
          store.dispatch(pushDebugMessage('POST error'))
          reject()
          return
        })
        .then(res => {
          store.dispatch(uiStopNetworkOffline())
          if (res.ok) {
            return res.json()
          } else {
            throw new Error()
          }
        })
        .then(parsedRes => {
          resolve(parsedRes)
        })
        .catch((error) => {
          store.dispatch(pushDebugMessage('Incorrect json return 1'))
          console.warn('Incorrect json return 1', error)
        })
      })
      .catch(error => {
        console.warn('Error no token in socket', error)    
      })
    })
  }

  postWithoutToken = (path, data) => {

    return new Promise((resolve, reject) => {

      if (data == null || data == undefined) data = {}

      let body = JSON.stringify(data)
      //let url = 'http://adev.byggbuddy.se/' + path
      let url = '/' + path
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }

      fetch(url, {
        method: 'POST',
        headers,
        body,
      })
      .catch((error) => {
        console.warn(error)

        //store.dispatch(uiStartNetworkOffline())

        //console.warn('POST ' + path + ' error')
        //store.dispatch(pushDebugMessage('POST ' + path + ' error'))
        reject()
        return
      })
      .then(res => {
        //store.dispatch(uiStopNetworkOffline())
        if (res.ok) {
          return res.json()
        } else {
          //console.warn(res)
          throw new Error()
        }
      })
      .then(parsedRes => {
        resolve(parsedRes)
      })
      .catch(() => {
        //store.dispatch(pushDebugMessage('Incorrect json return 2 ' + path))
        console.warn('Incorrect json return 2 ' + path)
      })
    })
  }
}

export {Socket, SocketService}
